import { CSS } from "@dnd-kit/utilities";
import {
    MouseSensor as LibMouseSensor,
    KeyboardSensor,
    TouchSensor,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
    useSortable,
} from "@dnd-kit/sortable";
import { faL, faXmark } from "@fortawesome/free-solid-svg-icons";
import warning from "./warning.png";
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import PageTemplate from "../Templates/PageTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPenToSquare, faCaretLeft, faGear, } from "@fortawesome/free-solid-svg-icons";
import TablePreset from "../Table/TablePreset";
import ToastError from "../Toasts/ToastError";
import ToastSuccess from "../Toasts/ToastSuccess";
import ToastWarning from "../Toasts/ToastWarning";
import middleware from "../Api/Middleware";
import Cookies from "universal-cookie";
import { useParams, useNavigate } from "react-router";

function shouldHandleEvent(element) {
    let cur = element;

    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false;
        }
        cur = cur.parentElement;
    }
    return true;
}

class MouseSensor extends LibMouseSensor {
    static activators = [
        {
            eventName: "onMouseDown",
            handler: ({ nativeEvent: event }) => {
                return shouldHandleEvent(event.target);
            },
        },
    ];
}
function Grid({ children, columns }) {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
                gridGap: 10,
                padding: 10,
            }}
        >
            {children}
        </div>
    );
}

// This is used to render the photo's. For the actual styling and format of the image draggable you need to look at the [Photo] function
const SortablePhoto = (props) => {
    const sortable = useSortable({ id: props.position });
    const { attributes, listeners, setNodeRef, transform, transition } = sortable;
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <Photo
            style={style}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            {...props}
        />
    );
};

// This is used to render the image draggable. This contains the styling and the html in order to render the photo.
const Photo = forwardRef(
    (
        {
            error,
            required,
            index,
            variationindex,
            file,
            url,
            name,
            handleVariationChange,
            handleDelete,
            useColors,
            variationOptions,
            ...props
        },
        ref
    ) => {
        //Return the image div
        return (
            <div ref={ref} {...props} className="col-12 objectWithinMU">
                <div className={error ? "errorImageBoxMU imageBoxMU" : "imageBoxMU"}>
                    {/* If the file exists and it's under 10mb it will render it normally */}
                    {file && file.size < 10 * 1024 * 1024 ? (
                        <div>
                            <img
                                className="col-12 imageInObjectMU"
                                src={url}
                                draggable="false"
                            />
                            <div>
                                <div className="imageInformationContainerMU">
                                    {/* Image size */}
                                    <b className="imageNameInObjectMU" id="imageSize">
                                        {file.size.toString().length >= 6 ? (
                                            <span>
                                                {(file.size / 1024 / 1000).toFixed(1) + " MB"}{" "}
                                            </span>
                                        ) : (
                                            <span>{(file.size / 1024).toFixed(1) + " kB"} </span>
                                        )}
                                    </b>

                                    {/* File name */}
                                    <div className="imageNameInObjectMU" id="imageName">
                                        <span>{name}</span>
                                    </div>

                                    {/* Delete button */}
                                    <div
                                        data-no-dnd="true"
                                        className="imageNameInObjectMU"
                                        id="deleteButton"
                                    >
                                        <span className="d-flex justify-content-center">
                                            <button
                                                type="submit"
                                                className="deleteImageButtonMU"
                                                onClick={() => handleDelete(index)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    className="deleteIconMU"
                                                />{" "}
                                                Verwijder
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // This renders the error image and styling
                        <div>
                            <img
                                className="col-12 imageInObjectMU"
                                src={warning}
                                draggable="false"
                            />
                            <div>
                                <div className="imageInformationContainerMU">
                                    {/* File name */}
                                    <div className="imageNameInObjectMU" id="imageName">
                                        <span>{name}</span>
                                    </div>

                                    {/* Delete button */}
                                    <div
                                        data-no-dnd="true"
                                        className="imageNameInObjectMU"
                                        id="deleteButton"
                                    >
                                        <span className="d-flex justify-content-center">
                                            <button
                                                type="submit"
                                                className="deleteImageButtonMU"
                                                onClick={() => handleDelete(index)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    className="deleteIconMU"
                                                />{" "}
                                                Verwijder
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* The select object for the color options */}
                {(useColors ?? false) &&
                    <div data-no-dnd="true">
                        {/* If there is nothing wrong with the image it will render out all the color options given in [colorOptions] */}
                        {!error ? (
                            <select
                                className=" mb-3 py-1  col-12 colorSelectForObjectMU"
                                defaultValue={props.variationIndex}
                                id={props.variationIndex === undefined && required ? "required" : ""}
                                onChange={(event) => handleVariationChange(index, event.target.value)}
                            >
                                <option hidden defaultValue>
                                    Selecteer een variatie
                                </option>
                                {variationOptions.map((variation, index) => (
                                    <option key={index} value={index}>
                                        {(variation.color_name ? variation.color_name : '') + (variation.size_name && variation.color_name ? ' - ' : '') + (variation.size_name ? variation.size_name : '')}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            // If there is an error it will display the error given to the object in the function [handleFileUpload]
                            <div
                                className=" mb-3 py-1  col-12 colorSelectForObjectMU"
                                id="error"
                            >
                                {error}
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
);



const blobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result.replace('data:', '')
        .replace(/^.+,/, ''));
    reader.onerror = error => reject(error);
});

const MediaUploaderComponent = forwardRef(({ useColors, availableVariations, edit }, ref) => {
    useEffect(() => {
        if (edit) {
            if(availableVariations.length) {
            getImages();
            }
        }
    }, [availableVariations])
    useImperativeHandle(ref, () => {
        return {
            async saveImages(productId) {
                try {

                    let currentProductId = productId ?? product_id;
                    const currentMonth = (new Date().getMonth() + 1)
                        .toString()
                        .padStart(2, "0");
                    const currentYear = new Date().getFullYear();

                    const folderPath = `warehouse/uploads/${currentYear}/${currentMonth}/${currentProductId}`;
                    const url = `${imageAPIUrl}/api/image`;

                    //Check if there are any images that are faulty (like too big or unsupported file type)
                    let faultyImages = false;
                    for (let index = 0; index < images.length; index++) {
                        const CurrentImage = images[index];
                        if (CurrentImage.error) {
                            faultyImages = true;
                        }
                    }
                    let everythingFilledIn = true;
                    if (useColors) {
                        //Check if every file has a color assigned to it
                        for (let index = 0; index < images.length; index++) {
                            const CurrentImage = images[index];
                            if (CurrentImage.colorId === undefined) {
                                everythingFilledIn = false;
                            }
                        }
                    }
                    // If there are no images uploaded yet it will tell the user to add images and stop the code from running any further
                    // if (images.length <= 0) {
                    //     ToastError("Voeg eerst foto's toe voordat je opslaat");
                    //     return false;
                    // }
                    //If there are any images that are faulty, a pop-up will appear and will stop the code from running any further
                    else if (faultyImages) {
                        ToastError("Verwijder de foto's die ongeldig zijn voordat je opslaat.");
                        return false;
                    }
                    //If there are any images that have no color assigned to them, a pop-up will appear and will stop the code from running any further
                    else if (useColors && !everythingFilledIn) {
                        setRequiredCheck(true);
                        ToastError("Selecteer voor elke foto een kleur.");
                        return false;
                    } else {
                        //This is so if the upload went succesful and you add a new image, it won't have a red ring around it.
                        setRequiredCheck(false);
                    }

                    //UploadCount is for feedback so the user knows they are being uploaded.
                    setUploadCount(0);

                    // Makes the upload screen appear for the upload media box
                    setUploading(true);
                    setUploadImgStatus("Oude bestanden aan het wissen");
                    //The string with all the data that will be send to the API so it saves the image path, order and color
                    let bulkDataString = "";

                    // Delete the images in the folder so it won't contain images from the previous save
                    // await fetch(`${url}?folderPath=${encodeURIComponent(folderPath)}`, {
                    //     method: "DELETE",
                    // })
                    //     .then((response) => response.json())
                    //     .then((data) => { })
                    //     .catch((error) => {
                    //         console.error(error);
                    //     });
                    let imagestrings = [];
                    console.log(images);
                    
                    //Loop through the images and preparing them to upload
                    for (let index = 0; index < images.length; index++) {
                        const CurrentImage = images[index];
                        // This displays the image that is currently being uploaded
                        setUploadImgStatus(
                            `${CurrentImage.name} (${CurrentImage.file.size.toString().length >= 6
                                ? (CurrentImage.file.size / 1024 / 1000).toFixed(1) + " MB"
                                : (CurrentImage.file.size / 1024).toFixed(1) + " kB"
                            })`
                        );

                        //Data that get's send to the api to save the images on react (server.js)
                        const formData = new FormData();
                        formData.append(`name`, CurrentImage.name);
                        formData.append(`index`, index);
                        formData.append(`color`, CurrentImage.color);
                        formData.append(`id`, CurrentImage.id);
                        formData.append(`productId`, product_id);
                        formData.append(`folderPath`, folderPath);
                        formData.append(`image`, CurrentImage.file); //This should always be last, anything appended after this will be ignored (no idea why but I've had this before).


                        const base64String = await blobToBase64(CurrentImage.file);
                        imagestrings.push(base64String);
                        // Posts the data to the server.js
                        // await fetch(url, {
                        //     method: "POST",
                        //     body: formData,
                        // })
                        //     .then((response) => response.json())
                        //     .then((data) => { })
                        //     .catch((error) => {
                        //         console.error(error);
                        //     });

                        // Increase the upload count to notify the user the image has been uploaded
                        setUploadCount((prev) => prev + 1);
                        console.log(bulkDataString);
                        console.log(imagestrings);
                        
                        
                        //Add the data to the bulkdata that will eventually be sent to the middleware API
                        bulkDataString += `${CurrentImage.name}|${useColors ? CurrentImage.colorId : null
                            }|${folderPath}/${CurrentImage.name.replace(/\s/g, "_")}|${index + 1}|${useColors ? CurrentImage.sizeId : null}`;
                        if (index < images.length - 1) {
                            bulkDataString += ",";
                        }
                    }
                    console.log(bulkDataString);
                    setUploadImgStatus("Foto's versturen naar database");
                    //Post request to the middleware api to save the image data to the database and delete the older information
                    const response = await middleware.post(
                        `products/media/upload?product_id=${currentProductId}&bulk_data=${encodeURIComponent(
                            bulkDataString
                        )}`, { imagestrings: imagestrings }
                    );

                    // Toast to notify the user of the succesful image upload
                    ToastSuccess("Foto's zijn succesvol opgeslagen!");

                    setUploading(false);
                    return true;
                } catch (error) {
                    // When something goes wrong the user gets logged out and send to the login page
                    console.warn(error);
                    cookies.remove("token");
                    navigate("/");
                    return false;
                }
            }
        }
    });
    const imageAPIUrl = "http://api-middleware.dev.techdogcloud.com"; //This is a temporary url, you can start this by typing [node server.js] in the terminal.
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor)
    );
    const { product_id } = useParams();
    const cookies = new Cookies();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [mouseDownX, setMouseDownX] = useState(null);
    const [mouseDownY, setMouseDownY] = useState(null);
    const [requiredCheck, setRequiredCheck] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [downloadCount, setDownloadCount] = useState(0);
    const [images, setImages] = useState([]);
    const [variations, setVariations] = useState(availableVariations);
    const [uploadCount, setUploadCount] = useState(0);
    const [uploadImgStatus, setUploadImgStatus] = useState("");
    const allowedImageFormats = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/jpeg",
        "image/svg",
    ];
    window.isDeleteOrDragEndTriggered = true;
    window.mouseMoved = true;
    window.clickedOnTheColor = true;
    return (
        <div className='whiteBox p-4'>
            <div className="fileUploader fileUploaderWidthHeight">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <div
                        className="mediaUploader"
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        onClick={handleClick}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                    >
                        {uploading || downloading ? (
                            <div className="uploadScreenMU">
                                <div className="uploadScreenContainerMU">
                                    <div
                                        className="spinner-border me-2"
                                        role="status"
                                    >
                                        <span className="visually-hidden">
                                            Loading...
                                        </span>
                                    </div>
                                    <span className="uploadScreenTextMU">
                                        {uploadImgStatus}
                                    </span>
                                    <span className="uploadScreenTextMU uploadScreenSubTextMU">
                                        (
                                        {uploading
                                            ? `${uploadCount} / ${images.length}`
                                            : downloading
                                                ? `${uploadCount} / ${downloadCount}`
                                                : ""}
                                        )
                                    </span>
                                </div>
                            </div>
                        ) : null}
                        <input
                            id="fileInput"
                            ref={fileInputRef}
                            hidden
                            type="file"
                            onChange={handleFileUpload}
                            multiple
                        />
                        <div className="textForTheMU">
                            Sleep of selecteer uw media-bestanden om te
                            uploaden
                        </div>
                        <SortableContext
                            items={images}
                            strategy={rectSortingStrategy}
                        >
                            <Grid columns={4}>
                                {images.map(
                                    ({id, file, name, error, variationIndex },index) => (
                                        <SortablePhoto
                                            key={id}
                                            error={error}
                                            required={requiredCheck}
                                            index={index}
                                            file={file}
                                            url={URL.createObjectURL(file)}
                                            useColors={useColors}
                                            name={name}
                                            variationIndex={variationIndex}
                                            handleDelete={handleDelete}
                                            variationOptions={availableVariations}
                                            handleVariationChange={handleVariationChange}
                                            images={images}
                                            position={id}
                                            id={id}
                                        />
                                    )
                                )}
                            </Grid>
                        </SortableContext>
                    </div>
                </DndContext>
            </div>
        </div>

    )
    // Triggers everytime the user is dragging an image inside the drag and drop box (only when moving)
    function handleDragEnter(e) {
        e.preventDefault(); // Prevents the user from openening the image on a new page when dragging the image on the image upload field
        e.stopPropagation(); // Ensures that the event is not passed along to any higher-level elements in the DOM tree.
    }

    // Triggers everytime the user is holding an image inside the drag and drop box (even when holding still)
    function handleDragOver(e) {
        e.preventDefault(); // Prevents the user from openening the image on a new page when dragging the image on the image upload field
        e.stopPropagation(); // Ensures that the event is not passed along to any higher-level elements in the DOM tree.
    }

    function handleVariationChange(index, id) {
        const newImages = [...images];
        console.log(availableVariations[id].color_id);
        
        if (availableVariations[id].color_id) {
            newImages[index].colorId = availableVariations[id].color_id;
        } else {
            newImages[index].colorId = 'null';
        }
        if (availableVariations[id].size_id) {
            newImages[index].sizeId = availableVariations[id].size_id;
        } else {
            newImages[index].sizeId = null;
        }
        setImages(newImages);

    }

    // Sets the new color for the image
    function handleColorChange(index, newColor) {
        const newImages = [...images];
        newImages[index].colorId = newColor;
        setImages(newImages);
        window.isDeleteOrDragEndTriggered = true;
    }

    // Removes image from the images variable
    function handleDelete(index) {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
        window.isDeleteOrDragEndTriggered = true;
    }

    // Triggers when the user holds down the left or right mouse button
    function handleMouseDown(event) {
        if (event.target.matches("select, option, button, span, svg, path")) {
            return;
        } else {
            setMouseDownX(event.clientX);
            setMouseDownY(event.clientY);
        }
    }

    // Triggers when the users lets go of their left or right mouse button
    function handleMouseUp(event) {
        if (event.clientX === mouseDownX && event.clientY === mouseDownY) {
            window.isDeleteOrDragEndTriggered = false;
            window.mouseMoved = false;
        } else {
            window.mouseMoved = true;
        }
        setMouseDownX(null);
        setMouseDownY(null);

        handleClick();
    }

    // Triggers when the user let's go of the mouse button their holding
    function handleClick() {
        if (!window.isDeleteOrDragEndTriggered && !window.mouseMoved) {
            fileInputRef.current.click();
        }
    }

    // Triggers when the user stops dragging an image
    function handleDragEnd(event) {
        const { active, over } = event;
        if (!over) {
            return;
        }
        if (active.id !== over.id) {
            setImages((images) => {
                // fixed issue with help of Tijn :D
                const oldIndex = images.indexOf(
                    images.find((obj) => obj["id"] === active.id)
                );
                const newIndex = images.indexOf(
                    images.find((obj) => obj["id"] === over.id)
                );
                return arrayMove(images, oldIndex, newIndex);
            });
        }
    }
    function handleFileUpload(e, isDropEvent) {
        e.preventDefault(); // Prevents the user from openening the image on a new page when dragging the image on the image upload field
        e.stopPropagation(); // Ensures that the event is not passed along to any higher-level elements in the DOM tree.

        const droppedFiles = isDropEvent ? e.dataTransfer.files : e.target.files;
        const newImages = [...images];
        
        let counter = 0;
        // Loop through the dropped files
        for (const uploadedFile of droppedFiles) {
            //If the image exists, is smaller than 10 MB and is an allowed file type (check [allowedImageFormats] for the full list of allowed file types)
            if (
                uploadedFile &&
                uploadedFile.size <= 10 * 1024 * 1024 &&
                allowedImageFormats.includes(uploadedFile.type)
            ) {
                const newImage = {
                    file: uploadedFile, //The file containing the size and the image
                    name: createUniqueName(uploadedFile.name.replace(/\s/g, "_"), images), // This function makes sure the name doesn't exist yet
                    variation: "default", // Default shows 'selecteer een kleur'
                    id: `${Date.now()}${+counter}`, // A unique id for the image
                };
                //Push the new image object into the newImages list
                newImages.push(newImage);
                counter++;
            } else if (uploadedFile.size >= 10 * 1024 * 1024) {
                // If the image is bigger than 10 MB it will give the user a popup
                //Toast with the warning
                ToastWarning(
                    `${uploadedFile.name} (${Math.round((uploadedFile.size / 1024 / 1024) * 100) / 100
                    } MB) is te groot! De maximale bestandsgrootte is 10 MB per afbeelding.`
                );

                //New image object
                const newImage = {
                    file: { name: uploadedFile.name, size: uploadedFile.size }, // Makes sure it can still display the image name and the image size even when the file can't be loaded correctly
                    name: createUniqueName(uploadedFile.name, images), // This function makes sure the name doesn't exist yet
                    color: "default", // Default shows 'selecteer een kleur'
                    id: `${Date.now()}${+counter}`, // A unique id for the image
                    error: "Afbeelding is te groot", // This error will be shown in the 'select' menu under the image
                };

                //Push the error image object into the newImages list
                newImages.push(newImage);
                counter++;
            } else if (!allowedImageFormats.includes(uploadedFile.type)) {
                // If the image isn't allowed notify the user using a toast
                //Toast telling the user which file isn't allowed and which types are.
                ToastWarning(
                    `${uploadedFile.name} is niet toegestaan. Bestanden met het type .png, .jpg, .jpeg, .svg en .gif zijn wel toegestaan. `
                );

                // New image object
                const newImage = {
                    file: undefined, // Makes sure the [Photo] function doesn't try to display the faulty image
                    name: createUniqueName(uploadedFile.name.replace(/\s/g, "_"), images), // This function makes sure the name doesn't exist yet
                    color: "default", // Default shows 'selecteer een kleur'
                    id: `${Date.now()}${+counter}`, // A unique id for the image
                    error: "Ongeldig bestandtype", // This error will be shown in the 'select' menu under the image
                };

                //Push the error image object into the newImages list
                newImages.push(newImage);
                counter++;
            } else {
                //A fallback for when something goes wrong which can't be explained.
                ToastError(
                    `Er ging iets fout tijdens het uploaden van ${uploadedFile.name}. Probeer het later opnieuw`
                );
            }
        }
        // Sets the new list of images to the variable
        setImages(newImages);
        
        // When they selected images by clicking on the file uploader
        if (!isDropEvent) {
            clearFileInput(); // Clears the file input so the user can upload the same image again if they want to
        }
    }
    function clearFileInput() {
        var fileInput = document.getElementById("fileInput");
        fileInput.value = "";
    }
    function handleDrop(e) {
        handleFileUpload(e, true);
    }


    async function urlToBlob(url) {
        try {
            // Use fetch API to get a response from the specified URL
            const response = await fetch(url);

            if (!response.ok) {
                // If the response was not ok (status in the range 200-299),
                // throw an error with the status text.
                throw new Error(
                    `HTTP error! status: ${response.status} ${response.statusText}`
                );
            }
            // Wait for the response to resolve into an ArrayBuffer.
            // An ArrayBuffer is a low-level data structure for representing a generic, fixed-length raw binary data buffer.
            const arrayBuffer = await response.arrayBuffer();

            // Create a new Blob object from the ArrayBuffer.
            // A Blob represents immutable raw data that can be read as text or binary data, or used as a source for an img, etc.
            // The Blob constructor parameters are an array of ArrayBuffer, ArrayBufferView, Blob, DOMString,
            // which will be put inside the Blob, and an optional BlobPropertyBag.
            // BlobPropertyBag is an object that sets the MIME type, here we are getting it from the response headers.
            const blob = new Blob([arrayBuffer], {
                type: response.headers.get("content-type"),
            });

            // Return the Blob object.
            return blob;
        } catch (error) {
            // Log any errors that occur during the process to the console.
            console.error("Error converting URL to Blob:", error);

            // Re-throw the error to be handled by the calling function.
            throw error;
        }
    }
    function fileExists(imageName, imagesArray) {
        return imagesArray.some((image) => image.name === imageName);
    }

    function createUniqueName(imageName, imagesArray) {
        const originalName = imageName; // The name of the image
        const lastDotIndex = originalName.lastIndexOf("."); // Index of the last .
        const filenameWithoutExt = originalName.substring(0, lastDotIndex); // Only the file name without the executor
        const fileExtension = originalName.substring(lastDotIndex + 1); // Only the executor so for example: png or jpeg
        let fileName = `${filenameWithoutExt}.${fileExtension}`;
        let fileCount = 0;

        // Loop through possible names until it doesn't exist in the current image array
        while (fileExists(fileName, imagesArray)) {
            fileCount++;
            fileName = `${filenameWithoutExt}(${fileCount}).${fileExtension}`;
        }

        return fileName;
    }
    async function uploadFile(file) {
        let formData = new FormData();
        formData.append("file", file);

        const response = await fetch("/upload", {
            // This should be the URL to your server-side upload script
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Upload failed");
        }

        const data = await response.json();
        return data.url; // This should be the URL to the uploaded file
    }
    async function getImages() {
        try {
            setDownloading(true);
            setUploadImgStatus("Foto's aan het ophalen");
            setUploadCount(0);
            setDownloadCount("?");

            const mediaRepsonse = await middleware.get(
                `products/media/upload?product_id=${product_id}`
            ); //Get the media from the database
            const mediaData = mediaRepsonse.data.images;
            const variationData = mediaRepsonse.data;
            const newImages = [];

            // Loop through the given images
            setDownloadCount(mediaData.length);
            for (let i = 0; i < mediaData.length; i++) {
                let imgData = mediaData[i];
                // The path url to the current file
                const filePathURL = imageAPIUrl + "/public/storage/" + imgData.path;
                const lastSeparatorIndex = filePathURL.lastIndexOf("/");

                // Extract the file name using substring()
                const fileName = filePathURL.substring(lastSeparatorIndex + 1);
                // Turns the url into a blob so it can be processed by the image handler
                const image = await middleware.get(`products/media/productmedia?folderPath=${imgData.path}`, { responseType: 'blob' });
                const blob = new Blob([image.data]);
                console.log(availableVariations.findIndex((e) => e.color_id == imgData.color_id && e.size_id == imgData.size_id));
                
                const newImage = {
                    file: blob,
                    name: createUniqueName(fileName, newImages),
                    variationIndex: availableVariations.findIndex((e) => e.color_id == imgData.color_id && e.size_id == imgData.size_id),
                    id: `${Date.now()}${i + 1}`,
                };
                newImages[i] = newImage;
                setImages(newImages);
                console.log(images);
                
                setUploadCount((prev) => prev + 1);
            }

            setDownloading(false);
            setImages(newImages);
        } catch (error) {
            console.warn(error);
            // cookies.remove("token");
            // navigate("/");
        }
    }

});

export default MediaUploaderComponent;