import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useTable } from "react-table";
import middleware from "../../../Api/Middleware";
import TablePreset from "../../../Table/TablePreset";
import "../../../Table/Table.css";
import "../../../Orders/Orders.css";
import "../../../Main/Main.css";
import { useNavigate, useParams } from "react-router";
import CheckboxHeader from "../../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../../Table/TableComponents/SortableHeader";
import InputComponent from "../../AddCustomers/InputComponent";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";

const AddressData = () => {
  const navigate = useNavigate();
const [loading, setLoading] = useState(true);
  const [addAdress, setAddAdress] = useState(false);
  const [formData, setFormData] = useState({});
  const [editMode, setEditMode] = useState(false); // New state to track if in edit mode
  const [addressToEdit, setAddressToEdit] = useState(null); // Address data to edit
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({ orderBy: '', direction: '' });
  const [searchBar, setSearchBar] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // New state for filtered data
  const [countries, setCountries] = useState([]);
  const [countryIdInfo, setCountryIdInfo] = useState([]);
  const { internal_id } = useParams();

  const fetchAll = async () => {
    setLoading(true);
    try {
      const [adressesResponse, countrycodesResponses] = await Promise.all([
        middleware.get(`addresses?internal_id=${internal_id}&page=${pagination.page}&page_size=${pagination.page_size}&${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&direction=${order.direction}` : ''}`),
        middleware.get(`countryCodes`),
      ]);
      setData(adressesResponse.data.data);
      setPagination({...pagination, "max_items": adressesResponse.data.total, "begin_of_page": adressesResponse.data.from, "end_of_page": adressesResponse.data.to})
      setFilteredData(adressesResponse.data.data); // Set initial filtered data
      setCountries(countrycodesResponses.data);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };


  useEffect(() => {
    try {
      fetchAll();
    } catch (error) {
      console.warn(error);
    }
  }, [order, order.direction]);

  useEffect(() => {
    // Filter the data whenever the search term changes
    if (searchBar) {
      const lowerCaseSearch = searchBar.toLowerCase();
      setFilteredData(
        data.filter(item =>
          item.streetname.toLowerCase().includes(lowerCaseSearch) ||
          item.city.toLowerCase().includes(lowerCaseSearch) ||
          item.postalcode.toLowerCase().includes(lowerCaseSearch) ||
          item.housenumber.toString().toLowerCase().includes(lowerCaseSearch) || // Convert to string before using toLowerCase()
          (item.addition && item.addition.toLowerCase().includes(lowerCaseSearch)) ||
          getCountryName(item.country_code_id).toLowerCase().includes(lowerCaseSearch)
        )
      );
    } else {
      setFilteredData(data); // Reset to original data if search term is empty
    }
  }, [searchBar, data]);
  

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const getAddressType = (addressKind) => {
    switch (addressKind) {
      case 0:
        return "Persoonlijk afleveradres";
      case 1:
        return "Persoonlijk factuuradres";
      case 2:
        return "Bedrijf afleveradres";
      case 3:
        return "Bedrijf factuuradres";
      default:
        return "Ongeldige waarde voor addressType";
    }
  };

  const getCountryName = (country_code_id) => {
    const country = countries.find(country => country.country_code_id === country_code_id);
    return country ? country.country : "Onbekend land";
  };

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader currentItem={filteredData} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'address_id'}>
          </CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'address_id'} />
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="streetname">
            Adres
          </SortableHeader>
        ),
        accessor: 'streetname',
        Cell: ({ row }) => {
          const ad = row.original;
          return (
            <div className="d-flex flex-column cursorPointer" style={{ color: "#009fe3" }} onClick={() => handleNewAdres(ad)}>
              <p className="mb-0">{`${ad.streetname || ""} ${ad.housenumber || ""}${ad.addition || ""}, ${ad.postalcode || ""} ${ad.city || ""} ${getCountryName(ad.country_code_id)}`}</p>
            </div>
          );
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} classNameHeader={"TypeAdressInfoHeader"} field="address_kind">
            Soort
          </SortableHeader>
        ),
        accessor: 'address_kind',
        Cell: ({ row }) => {
          return (
            <div className="text-center">{getAddressType(row.original.address_kind)}</div>
          );
        }
      },
    ], [order.orderBy, order.direction, selectedIds, setSelectedIds, filteredData, countries]
  );

  // Table instance
  const tableInstance = useTable({ columns, data: filteredData });

  const normalizeLabel = (label) => {
    // Verwijder spaties aan het begin en einde, verwijder alle tussenliggende spaties en streepjes
    return label.trim().replace(/[\s\-]+/g, "");
  };

  const getValueInput = useCallback((value, label) => {
    // Normaliseer het label om consistentie te waarborgen
    const normalizedLabel = normalizeLabel(label);

    setFormData((prev) => ({ ...prev, [normalizedLabel]: value }));
  }, []);

  const validateAddressForm = () => {
    // Controleer of de verplichte velden zijn ingevuld
    const { Postalcode, HouseNumber, StreetName, City } = formData;

    if (!Postalcode || !HouseNumber || !StreetName || !City || !countryIdInfo.country_code_id) {
      ToastError("Land, Postcode, Huisnummer, Straat, en Stad velden zijn verplicht");
      return false;
    }

    // Als alles is ingevuld, retourneer true
    return true;
  };

  const handleNewAdres = async (address = null) => {
    // If an address is passed in, set the form data to that address (edit mode)
    if (address) {
      setFormData({
        Postalcode: address.postalcode,
        HouseNumber: address.housenumber,
        Addition: address.addition,
        StreetName: address.streetname,
        City: address.city,
        primaryAddress: address.address_kind === 1 || address.address_kind === 3 ? "invoice" : "delivery",
      });
      setCountryIdInfo(countries.find(country => country.country_code_id === address.country_code_id));
      setEditMode(true);
      setAddressToEdit(address);
      setAddAdress(true);
    } else {
      // If no address is passed in, reset the form and set to add mode
      setFormData({});
      setCountryIdInfo([]);
      setEditMode(false);
      setAddressToEdit(null);
      setAddAdress(true);
    }
  };

  const handleSubmitAddress = async (e) => {
    if (e) {
      e.preventDefault(); // Prevent the default form submission behavior
    }
    if (validateAddressForm()) {
      let adress_kind = 0;

      if (internal_id.startsWith('B')) {
        switch (formData.primaryAddress) {
          case "invoice":
            adress_kind = 3;
            break;
          case "delivery":
            adress_kind = 2;
            break;
        }
      } else if (internal_id.startsWith('C')) {
        switch (formData.primaryAddress) {
          case "invoice":
            adress_kind = 1;
            break;
          case "delivery":
            adress_kind = 0;
            break;
        }
      }

      try {
        if (editMode && addressToEdit) {
          // If in edit mode, update the existing address
          await middleware.put(`/addresses?address_id=${addressToEdit.address_id}`, {
            country_code_id: countryIdInfo.country_code_id,
            postalcode: formData.Postalcode,
            housenumber: formData.HouseNumber,
            addition: formData.Addition || null,
            streetname: formData.StreetName,
            city: formData.City,
            address_kind: adress_kind,
          });
          ToastSuccess("Adres is bijgewerkt");
        } else {
          // If not in edit mode, create a new address
          await middleware.post("/addresses", {
            internal_id: internal_id,
            country_code_id: countryIdInfo.country_code_id,
            postalcode: formData.Postalcode,
            housenumber: formData.HouseNumber,
            addition: formData.Addition || null,
            streetname: formData.StreetName,
            city: formData.City,
            address_kind: adress_kind,
          });
          ToastSuccess("Adres is aangemaakt");
        }
        setAddAdress(false);
        fetchAll();
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className="adressOverviewTab h-100 mt-4 px-3">
      {addAdress ?
        <form onSubmit={handleSubmitAddress}>
          <div className="mt-5">
            <div>
              <div className=" d-flex flex-row mb-4">
                <h5 className="me-5">{editMode ? "Adres bewerken" : "Adres toevoegen"}</h5>
                <select
                  className="form-select inputFieldAddCustomer ms-5 dropdownsNewCustomers" style={{ width: "250px" }}
                  value={JSON.stringify(countryIdInfo)}
                  onChange={(e) => setCountryIdInfo(JSON.parse(e.target.value))}
                >
                  <option hidden>Kies een land</option>
                  {countries?.length > 0 && countries.map(country => (
                    <option key={country.country_code_id} value={JSON.stringify(country)}>
                      {country.country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <label className="checkbox-containerAdressAdd me-2">
                  <input
                    type="radio"
                    name="primaryAddress"
                    value="invoice"
                    checked={formData.primaryAddress === "invoice"}
                    onChange={(e) => setFormData(prev => ({ ...prev, primaryAddress: e.target.value }))}
                  />
                  <span className="checkmarkAdressAdd"></span>
                </label>
                <p className="text-black fitContent mb-0 fw-medium">Factuuradres</p>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <label className="checkbox-containerAdressAdd me-2">
                  <input
                    type="radio"
                    name="primaryAddress"
                    value="delivery"
                    checked={formData.primaryAddress === "delivery"}
                    onChange={(e) => setFormData(prev => ({ ...prev, primaryAddress: e.target.value }))}
                  />
                  <span className="checkmarkAdressAdd"></span>
                </label>
                <p className="text-black fitContent mb-0 fw-medium">Verzendadres</p>
              </div>
              <div className="mb-4">
                <div className="col-12 d-flex flex-row align-content-end my-3">
                  <InputComponent
                    type={"text"}
                    onValueChange={getValueInput}
                    parentCol={6}
                    labelName={"Postcode"}
                    labelValue={"Postalcode"}
                    classnamesInput={"col-12 widthInputComponents px-3"}
                    defaultValue={formData.Postalcode}
                  />
                  <div className="col-6 d-flex justify-content-end">
                    <div className="col-12 d-flex widthInputComponents flex-row justify-content-end">
                      <InputComponent
                        alignmentInputs={"justify-content-start"}
                        onValueChange={getValueInput}
                        type={"number"}
                        parentCol={6}
                        labelName={"Huisnummer"}
                        labelValue={"HouseNumber"}
                        classnamesInput={"col-12 widthInputComponents px-3"}
                        defaultValue={formData.HouseNumber}
                      />
                      <InputComponent
                        alignmentInputs={"justify-content-end"}
                        onValueChange={getValueInput}
                        type={"text"}
                        parentCol={6}
                        labelName={"Toevoeging"}
                        labelValue={"Addition"}
                        classnamesInput={"col-12 widthInputComponents px-3"}
                        defaultValue={formData.Addition}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 d-flex flex-row align-content-end my-3 ">
                  <InputComponent
                    type={"text"}
                    onValueChange={getValueInput}
                    alignmentInputs={"justify-content-start"}
                    parentCol={6}
                    labelName={"Straat"}
                    labelValue={"StreetName"}
                    classnamesInput={"col-12 widthInputComponents px-3"}
                    defaultValue={formData.StreetName}
                  />
                  <InputComponent
                    type={"text"}
                    onValueChange={getValueInput}
                    alignmentInputs={"justify-content-end"}
                    parentCol={6}
                    labelName={"Stad"}
                    labelValue={"City"}
                    classnamesInput={"col-12 widthInputComponents px-3"}
                    defaultValue={formData.City}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <button className="btn DarkStandardButton" type="button" onClick={() => setAddAdress(false)}>Annuleren</button>
              <button className="btn lightBlueStandardButton" type="submit">{editMode ? "Bijwerken" : "Toevoegen"}</button>
            </div>
          </div>
        </form>
        :
        <div className="mb-3 mt-5 tablesCustomerEdit h-100">
          {loading ? 
          <div className="loader text-center"></div>
          :
            <TablePreset
              data={[{ 'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id' }]}
              setPagination={setPagination}
              handleSelection={handleSelection}
              searchBar={[{ shown: true, value: searchBar, function: setSearchBar }]}
              leftButtons={[{ title: "Filteren", function: () => console.log('a') }]}
              rightButtons={[{ title: "Nieuw adres", function: () => handleNewAdres() }]}
            />
          }
        </div>
      }
    </div>
  );
}

export default AddressData;
