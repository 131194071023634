// edit
import React, { useEffect, useState, useMemo } from "react";
import middleware from "../../../../Api/Middleware";
import TablePreset from "../../../../Table/TablePreset";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRotate } from "@fortawesome/free-solid-svg-icons";
import { useTable } from "react-table";
import { useNavigate, useParams } from "react-router-dom";
import ToastError from "../../../../Toasts/ToastError";

const BoxVariation = (props) => {
  const navigate = useNavigate();
  // usestates
  const [addVariation, setAddVariation] = useState({
    size: "",
    color: "",
  });
  const [sizeVariations, setSizeVariations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [variations, setVariations] = useState([]);
  const [colorVariations, setColorVariations] = useState([]);
  const [selectedVariationIds, setSelectedVariationIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });

  // useeffectes
  useEffect(() => {
    const colorsCache = localStorage.getItem("colorsAddVariationCache");
    const sizesCache = localStorage.getItem("sizesAddVariationCache");

    if (!colorsCache || !sizesCache) {
      navigate("/producten");
      return;
    }

    const colors = JSON.parse(colorsCache);
    const sizes = JSON.parse(sizesCache);

    const variations = [];

    // if (colors.length === 0 && sizes.length > 0) {
    //   sizes.forEach(size => {
    //     variations.push({
    //       id: props.variations.length + 1,
    //       size: size.id,
    //       barcode: null,
    //       stock: null
    //     });
    //   });
    // } else if (sizes.length === 0 && colors.length > 0) {
    //   colors.forEach(color => {
    //     variations.push({
    //       id: props.variations.length + 1,
    //       color: color.color_id,
    //       hex: color.hex,
    //       barcode: null,
    //       stock: null
    //     });
    //   });
    // } else {
    //   colors.forEach(color => {
    //     sizes.forEach(size => {
    //       variations.push({
    //         id: props.variations.length + 1,
    //         color: color.color_id,
    //         hex: color.hex,
    //         size: size.id,
    //         barcode: null,
    //         stock: null
    //       });
    //     });
    //   });
    // }

    setColorVariations(colors);
    setSizeVariations(sizes);
    props.setAllVariations(variations);
    generateVariations(variations);
  }, [navigate]);

  useEffect(() => {
    if (props.allVariations?.length) {
      updatePagination();
    }
  }, [props.allVariations?.length, pagination.size, pagination.page])

  const updatePagination = () => {
    const totalVariations = props.allVariations.length;
      const { page_size, page } = pagination;
      const maxPage = Math.ceil(totalVariations / page_size);
      const newPage = Math.max(1, page > maxPage ? maxPage : page);
      const begin_of_page = (page - 1) * page_size;
      const end_of_page = Math.min(begin_of_page + page_size, totalVariations);
      const paginatedVariations = props.allVariations.slice(begin_of_page, end_of_page);
      setPagination({
        page_size: page_size,
        max_items: totalVariations,
        page: newPage,
        begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
        end_of_page: end_of_page
      });
      setVariations(paginatedVariations);
  }

  const generateVariations = (variations = null) => {
    variations = variations ? variations : props.allVariations;
    const totalVariations = variations.length;
    const { page_size, page } = pagination;
    const maxPage = Math.ceil(totalVariations / page_size);
    const newPage = Math.max(1, page > maxPage ? maxPage : page);

    const begin_of_page = (page - 1) * page_size;
    const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

    const paginatedVariations = variations.slice(begin_of_page, end_of_page);

    setVariations(paginatedVariations);
  };
  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedVariationIds([...selectedVariationIds, productId]);
    } else {
      setSelectedVariationIds(
        selectedVariationIds.filter((id) => id !== productId)
      );
    }
  };
  const handleSelection = (id, isSelected) => {
    if (isSelected) {
      setSelectedIds((previous) => [...previous, id]);
    } else {
      setSelectedIds((previous) => previous.filter((id) => id !== id));
    }
  };
  // a function that selects all the selects in the table
  const select_all = () => {
    setSelectedVariationIds(variations.map((variation) => variation.id));
  };

  // a function that unselects all the selects in the table
  const unselect_all = () => {
    setSelectedVariationIds([]);
  };

  const delete_variations = () => {
    props.setAllVariations((previous) =>
      previous.filter(
        (variation) => !selectedVariationIds.includes(variation.id)
      )
    );
    setSelectedVariationIds([]);
  };
  const handleAction = async (event) => {
    if (event === "select-all") {
      select_all();
    } else if (event === "unselect-all") {
      unselect_all();
    } else if (event === "delete") {
      props.setUpdatedVariations(true);
      delete_variations();
    }
  };
  const generateBarcode = async (event, id) => {
    event.preventDefault();

    const response = await middleware.get("products/generateBarcode");
    const newBarcode = response.data.generatedBarcode;

    handleBarcodeChange(newBarcode, id);
    // Set the pagination so the generated barcode is visible
    updatePagination();
  };

  const handleBarcodeChange = (newBarcode, id) => {
    // set the updated stock to true if the field changed
    if (newBarcode != props.allVariations.find((variation) => variation.id === id)?.barcode ?? null) {
      props.setUpdatedVariations(true);
    }
    props.setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === id ? { ...variation, barcode: newBarcode } : variation
      )
    );
  };

  const handleStockChange = (newStock, id) => {
    // set the updated stock to true if the field changeds
    if (newStock != props.allVariations.find((variation) => variation.id === id)?.stock ?? null) {
      props.setUpdatedVariations(true);
    }
    props.setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === id ? { ...variation, stock: newStock } : variation
      )
    );
  };
  // table

  const columns = useMemo(() => {
    const cols = [
      {
        Header: (
          <>
            <label className="checkbox-container">
              <input
                type="checkbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setSelectedVariationIds(
                    checked ? variations.map((variation) => variation.id) : []
                  );
                }}
                checked={selectedVariationIds.length > 0}
              />
              <span className="checkmark"></span>
            </label>
          </>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          const isSelected = selectedVariationIds.includes(row.original.id);
          return (
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={(e) =>
                  handleProductSelection(row.original.id, e.target.checked)
                }
              />
              <span className="checkmark"></span>
            </label>
          );
        },
      },
      {
        Header: "Voorraad",
        accessor: "stock",
        Cell: ({ row }) => {
          const stock =
            props.allVariations.find(
              (variation) => variation.id === row.original.id
            )?.stock || "";
          return (
            <div className="d-flex justify-content-center">
              <input
                onBlur={(event) =>
                  handleStockChange(event.target.value, row.original.id)
                }
                defaultValue={stock}
                type="number"
                className="stockBox w-50 webkitRemove"
                placeholder="0"
              />
              {/* <input type="number" className='stockBox w-25 webkitRemove' placeholder='0'/> */}
            </div>
          );
        },
      },
      {
        Header: <div>Barcode</div>,
        accessor: "barcode",
        Cell: ({ row }) => {
          const barcode =
            props.allVariations.find(
              (variation) => variation.id === row.original.id
            )?.barcode || "";
          return (
            <div className="d-flex flex-column">
              <div>
                <input
                  onBlur={(event) =>
                    handleBarcodeChange(event.target.value, row.original.id)
                  }
                  defaultValue={barcode}
                  type="number"
                  className={"stockBox w-75 webkitRemove " + (props.errors.find((e) => e == row.original.id) != null ? "border-danger" : "")}
                />

                <button
                  onClick={(event) => generateBarcode(event, row.original.id)}
                  className="btn border-0 w-25"
                >
                  <FontAwesomeIcon icon={faRotate} />
                </button>
              </div>
            </div>
          );
        },
      },
      // Dit is een extra optie die later toegevoegd moet worden.
      // {
      //   Header: "Prijs incl. BTW",
      //   accessor: "incl_tax",
      //   Cell: ({row}) => {
      //     return (
      //       <div className='d-flex justify-content-center'>
      //         <span className='stockBox'>€ 34,99</span>
      //       </div>
      //     )
      //   }
      // },
    ];

    // Conditionally add the color_name column if colors are selected
    if (colorVariations.length > 0) {
      cols.splice(1, 0, {
        Header: (
          <select className="tableDropdown fw-semibold standardDropdownArrow dropdownArrowSmall p-1">
            <option hidden>Filter kleur</option>
          </select>
        ),
        accessor: "color_name",
        Cell: ({ row }) => {
          return row.original.color_name ? (
            <span className="d-flex flex-row">
              <div
                className=" rounded-circle me-2 mt-1 colorCircle"
                style={{
                  backgroundColor: row.original.hex,
                  width: "15px",
                  height: "15px",
                }}
              ></div>
              <div className="text-nowrap colorNameVariationTable">
                {row.original.color_name}
              </div>
            </span>
          ) : (
            ""
          );
        },
      });
    }

    // Conditionally add the size_name column if sizes are selected
    cols.splice(colorVariations.length > 0 ? 2 : 1, 0, {
      Header: (
        <select className="tableDropdown fw-semibold standardDropdownArrow dropdownArrowSmall p-1">
          <option hidden>Filter maten</option>
        </select>
      ),
      accessor: "size_name",
      Cell: ({ row }) => {
        return (
          <span className="d-flex justify-content-center">
            {row.original.size_name}
          </span>
        );
      },
    });

    return cols;
  }, [selectedVariationIds, pagination, props.errors]);

  //   Table instances
  const tableInstance = useTable({ columns, data: variations });

  const handleNewVariation = () => {
    const size = props.allSizes.filter(
      (size) => size.primary_key === Number(addVariation.size)
    )[0];
    const color = props.allColors.filter(
      (color) => color.primary_key === Number(addVariation.color)
    )[0];

    if (!size && !color) {
      ToastError("Je moet een maat of kleur selecteren");
      return;
    } else {
      props.setUpdatedVariations(true);
    }
    const newVariation = {
      id: props.allVariations.length + 1,
      size: size?.size_id ?? null,
      size_name: size?.name ?? null,
      color: color?.color_id ?? null,
      color_name: color?.name ?? null,
      hex: color?.hex ?? null,
    };
    props.setAllVariations([newVariation, ...props.allVariations]);
  };
  return (
    <>
      <div className="whiteBox p-4 formVariationProduct mb-4">
        <h5 className="fw-semibold">Huidige variatie</h5>
        <div className="widthColorTable">
          {isLoading ? (
            <TablePreset
              data={[
                {
                  selectedIds: selectedIds,
                  tableInstance: tableInstance,
                  pagination: pagination,
                },
              ]}
              tableLoading={false} // Isloading
              setPagination={(e) => setPagination(e)}
              handleSelection={handleSelection}
              actionOptions={[
                {
                  title: "Selecteer alles",
                  function: () => handleAction("select-all"),
                },
                {
                  title: "De-selecteer alles",
                  function: () => handleAction("unselect-all"),
                },
                { title: "Verwijder", function: () => handleAction("delete") },
              ]}
              rightDropdowns={[
                {
                  title: "Selecteer een maat",
                  primary_key: "primary_key",
                  options: props.allSizes,
                  function: (event) =>
                    setAddVariation({
                      ...addVariation,
                      size: event.target.value,
                    }),
                  value: addVariation.size,
                },
                {
                  title: "Selecteer een kleur",
                  primary_key: "primary_key",
                  options: props.allColors,
                  function: (event) =>
                    setAddVariation({
                      ...addVariation,
                      color: event.target.value,
                    }),
                  value: addVariation.color,
                },
              ]}
              rightButtons={[
                { title: "Handmatig toevoegen", function: handleNewVariation },
              ]}
            />
          ) : (
            <div className="table-container table-responsive">
              <table>
                <thead>
                  <tr>
                    <td>
                      <div>Loading</div>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BoxVariation;
