import React, { useState, useEffect } from "react";
import middleware from "../Api/Middleware";
import "./Orders.css";
import "../Table/Table.css";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck, faMoneyBill1, faUser } from "@fortawesome/free-regular-svg-icons";
import { faTruck, faChevronUp, faChevronDown, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import NavbarIcon from "../Navbars/Sidebar/Icons/envelope.png"
import productPic from "../WarehouseSettings/Icons/size.png"
import PageTemplate from "../Templates/PageTemplate";
import ToastSuccess from "../Toasts/ToastSuccess";
import ToastError from "../Toasts/ToastError";
import { Toast } from "react-toastify"
import TDSTextNavigation from "../Templates/TDSTextNavigation";
import countries from 'i18n-iso-countries';
import SendEmailModal from '../Modals/SendEmailModal';
import ReceiveEmailModal from "../Modals/ReceiveEmailModal";
countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));


const OrdersEdit = () => {
  const { order_number } = useParams();

  const navigate = useNavigate();

  const [pageIsLoading, setPageIsLoading] = useState(true);

  const [order, setOrder] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [emails, setEmails] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [oldOrderNote, setOldOrderNote] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState(null);
  const [shipments, setShipments] = useState([]);
  const [shownShipments, setShownShipments] = useState([]);
  const [productsToProcess, setProductsToProcess] = useState([]);
  const [productsToProcessFullyShown, setProductsToProcessFullyShown] = useState([]);
  const [totalAmountOfProducts, setTotalAmountOfProducts] = useState(0);
  const [taxTypes, setTaxTypes] = useState([]);

  const fetchAll = async () => {
    try {
      setPageIsLoading(true);
  
      // Fetch all data concurrently
      const [orderResponse, countryCodesResponse] = await Promise.all([
        middleware.get(`orders?order_number=${order_number}`),
        middleware.get(`countryCodes`),
      ]);

      const order = orderResponse.data.order_data[0];
      setOrder(order);
      setOldOrderNote(order.note);
  
      // Fetch customer, address, sold products, emails, and phone numbers concurrently
      const customerType = order.internal_id.charAt(0) === "C" ? 'consumers' : 'businesses';
      const [customerResponse, addressResponse, soldProductsResponse, emailResponse, phoneNumberResponse] = await Promise.all([
        middleware.get(`${customerType}?internal_id=${order.internal_id}`),
        middleware.get(`addresses?internal_id=${order.internal_id}`),
        middleware.get(`orders/soldproducts?order_id=${order.order_id}`),
        middleware.get(`emails?internal_id=${order.internal_id}`),
        middleware.get(`phonenumbers?internal_id=${order.internal_id}`),
      ]);
  
      // Set customer data
      setCustomer(...customerResponse.data);
  
      // Handle address and country codes
      if (addressResponse.data.length > 0) {
        addressResponse.data.forEach((address) => {
          const country = countryCodesResponse.data.find(code => code.country_code_id === address.country_code_id).country_code_2;
          address.country = country;
          (address.address_kind === 0 || address.address_kind === 2) ? setDeliveryAddress(address) : setInvoiceAddress(address);
        });
      }
  
      // Set emails and phone numbers
      setEmails(emailResponse.data);
      setPhoneNumbers(phoneNumberResponse.data);
  
      // Handle sold products
      const soldProductsData = soldProductsResponse.data;
      let totalProducts = 0;
      const productsToProcess = soldProductsData.reduce((acc, product) => {
        totalProducts += product.amount;
        if (product.amount > product.items_processed) {
          acc.push({ ...product, amount: product.amount - product.items_processed });
        }
        return acc;
      }, []);
  
      setProductsToProcess(productsToProcess);
      setTotalAmountOfProducts(totalProducts);
  
      // Calculate tax types
      const taxTypes = soldProductsData.reduce((acc, product) => {
        const totalPriceExclTax = Number(product.price_excl_tax) * Number(product.amount);
        const totalTax = (Number(product.price_incl_tax) - Number(product.price_excl_tax)) * Number(product.amount);
        const index = acc.findIndex(tax => tax.taxRate === product.btw_rate);
        
        if (index !== -1) {
          acc[index].totalPriceExclTax += totalPriceExclTax;
          acc[index].totalTax += totalTax;
        } else {
          acc.push({ taxRate: product.btw_rate, totalPriceExclTax, totalTax });
        }
        return acc;
      }, []);
  
      setTaxTypes(taxTypes);
  
      // Fetch and handle shipments
      const shipmentsResponse = await middleware.get(`shipments/order?order_id=${order.order_id}`);
      const shipments = shipmentsResponse.data;
      console.log(shipments);
      setShipments(shipments);
  
      const shownShipments = shipments.reduce((acc, _, index) => ({ ...acc, [index]: true }), {});
      setShownShipments(shownShipments);
  
      setPageIsLoading(false);
    } catch (error) {
      console.error(error);
      // removeLoginCookies(navigate);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const getCustomerLink = _ => {
    return customer.consumer_id ? (
      `/klanten/bewerken/${customer.consumer_id}`
    ) : (
      `/klanten/bewerken/${customer.company_id}`
    );
  }

  const handleOrderOnChange = event => {
    setOrder({...order, [event.target.name]: event.target.value});
  }

  const handleSaveNote = async event => {
    event.preventDefault();

    if (order.note !== oldOrderNote) {
      try {
        const res = await middleware.put(`orders`, {
          order_id: order.order_id,
          note: order.note,
        });
        
        setOrder(res.data);
        setOldOrderNote(res.data.note);
        ToastSuccess(`Notitie is successvol opgeslagen`);
      } catch (error) {
        ToastError(`Er ging iets fout tijdens de notitie opslaan`);
      }
    }
  }

  const calculateSalePrice = soldProduct => {
    switch (soldProduct.sale_unit) {
      case 0: // This means that the sale was in percentage
        return Number(soldProduct.price_incl_tax) * Number(soldProduct.amount) / 100 * Number(soldProduct.sale_amount);
      case 1: // This means that the sale was in euro's
        return Number(soldProduct.sale_amount) * Number(soldProduct.amount);
    }
  }

  const showPrice = price => {
    return `€ ${Number(price).toFixed(2).replace('.', ',').replace(',00', ',-')}`;
  }

  const showSalePrice = soldProduct => {
    const salePrice = calculateSalePrice(soldProduct);

    return showPrice(Number(soldProduct.price_incl_tax) - salePrice / soldProduct.amount);
  }

  const handleShipmentDropdown = async (event, shipmentId) => {
    try {
      switch (event.target.value) {
        case '1':
          await middleware.delete(`shipments/${shipmentId}`);
          fetchAll();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <PageTemplate navbarTitle={`Bestelling bewerken`} pageIsLoading={pageIsLoading} iconNavbar={NavbarIcon}>
      <div className="OrderEdit mx-3 w-100">
        <div>
          <div className="d-flex flex-row align-items-center mb-3">
            <button
              className="buttonStyle me-3"
              onClick={(e) => navigate("/bestellingen")}
            >
              <FontAwesomeIcon icon={faCaretLeft} /> Alle Bestellingen
            </button>
            <h1 className="mb-0">{`#${order.order_number}`}</h1>
          </div>
          <div className="d-flex justify-content-between gap-5 parentBoxes flex-column w-100">
            <div className="ordersEditContainer">
              {/* Left side container box */}
              <div className="ordersEditLeftSide d-flex flex-column">
                <div className="bg-white rounded-4 p-4 mb-3 overflow-x-scroll h-50">
                  <div className="orderEditScroll">
                    <div className="mb-3">
                      <h3>
                        <FontAwesomeIcon icon={productsToProcess.length > 0 ? faCircle : faCircleCheck} className={productsToProcess.length > 0 ? 'text-warning' : 'text-success'} />{' '}
                        {productsToProcess.length > 0 ? 'Nog te verwerken' : 'Alles is verwerkt'}
                      </h3>
                    </div>
                    
                    {productsToProcess.legnth > 0 ? '' : 'Dit betekent dat deze bestelling al is voorbereid voor verzending, of al onderweg is naar de klant'}
                    
                    {productsToProcess.length !== 0 ? (
                      <div>
                        <table className="w-100 border-0">
                          <thead>
                            <tr>
                              <th>Aantal</th>
                              <th className="text-center">Foto</th>
                              <th className="w-75">Productnaam</th>
                              <th>Prijs p.s.</th>
                              <th>Totaal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productsToProcess.map((productToProcces, index) => (
                              <tr key={index}>
                                <td className="text-center">{productToProcces.amount}</td>
                                <td className="text-center"><img src={productPic} className="p-2" height={69} width={69} alt="placeholder..."></img></td>
                                <td className="w-75">
                                  {productToProcces.product_name.length > 100 ? (
                                    productsToProcessFullyShown[index] === undefined ? (
                                      <>
                                        <span>
                                          {productToProcces.product_name.slice(0, 90)}
                                        </span>
                                        <span
                                          className="text-info hover text-nowrap ms-2"
                                          onClick={() => setProductsToProcessFullyShown([...productsToProcessFullyShown, index])}>
                                          (...)
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span>
                                          {productToProcces.product_name}
                                        </span>
                                        <span
                                          className="text-info hover text-nowrap ms-2"
                                          onClick={() => setProductsToProcessFullyShown(productsToProcessFullyShown.filter(i => i !== index))}>
                                          Toon minder
                                        </span>
                                      </>
                                    )
                                  ) : (
                                    productToProcces.product_name
                                  )}
                                </td>
                                <td className="text-nowrap">
                                  {productToProcces.sale_amount > 0 ? (
                                    <div>
                                      <span className="text-decoration-line-through text-secondary">{showPrice(productToProcces.price_incl_tax)}</span> <br/>
                                      <span>{showSalePrice(productToProcces)}</span>
                                    </div>
                                  ) : (
                                    <span>{showPrice(productToProcces.price_incl_tax)}</span>
                                  )}
                                </td>
                                <td className="text-nowrap">{showPrice(productToProcces.price_incl_tax * productToProcces.amount - calculateSalePrice(productToProcces))}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="d-flex justify-content-end">
                          <button className="buttonStyle bg-light" onClick={() => navigate(`/bestellingen/verwerken/${order.order_number}`)}>
                            <FontAwesomeIcon icon={faTruck} />{' '}
                            Verwerken
                          </button>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <div className="bg-white rounded-4 p-4 mb-3 h-50">
                  <div className="mb-3">
                    <h3>
                      <FontAwesomeIcon icon={faMoneyBill1} className="text-success" />{' '}
                      Betaling
                    </h3>
                  </div>

                  <div>
                    <table className="ordersEditShowPriceTable">
                      <thead>
                        <tr>
                          <th className="pe-2">Omschrijving</th>
                          <th className="pe-2">Incl. BTW</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{totalAmountOfProducts} {totalAmountOfProducts > 1 ? 'Producten' : 'Product'}</td>
                          <td>{showPrice(order.total_price_incl_tax)}</td>
                        </tr>
                        <tr>
                          <td>Subtotaal</td>
                          <td>{showPrice(order.total_price_incl_tax)}</td>
                        </tr>
                        <tr>
                          <td className="pe-2">Korting</td>
                          <td>{showPrice(order.sale_amount)}</td>
                        </tr>
                        <tr>
                          <td className="pe-2">Loyaliteitspunten</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>Totaal</td>
                          <td>{showPrice(order.total_price_incl_tax - order.sale_amount)}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="ordersEditShowTaxTable">
                      <thead>
                        <tr>
                          <th className="pe-2">Omschrijving</th>
                          <th className="pe-2">Excl. BTW</th>
                          <th className="pe-2">BTW</th>
                        </tr>
                      </thead>
                      
                      <tbody>
                        {taxTypes.map((taxType, index) => (
                          <tr key={index}>
                            <th>BTW {taxType.taxRate}%</th>
                            <td className="text-nowrap">{showPrice(taxType.totalPriceExclTax)}</td>
                            <td className="text-nowrap">{showPrice(taxType.totalTax)}</td>
                          </tr>
                        ))}
                        <tr>
                          <th>Korting</th>
                          <td>{showPrice(order.sale_amount)}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Totaal</th>
                          <td>{showPrice(order.total_price_excl_tax - order.sale_amount)}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                
                {shipments.map((shipment, index) => (
                  <div key={index} className="bg-white rounded-4 p-4 mb-3 shadow-sm">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                      <h3>
                        <FontAwesomeIcon icon={faTruck} className="text-info" />{' '}
                        Verzending #{shipment.shipment_number}
                      </h3>
                      <h3>
                        <FontAwesomeIcon
                          icon={shownShipments[index] ? faChevronUp : faChevronDown}
                          className="hover"
                          onClick={() => setShownShipments({...shownShipments, [index]: !shownShipments[index]})}
                        />
                      </h3>
                    </div>
                    {shownShipments[index] &&
                      Object.keys(shipment.packages).map((packagekey, index) => (
                        <div key={index} className="mb-4">
                          <h4 className="text-primary">{packagekey.replace('package_', 'Pakket ')}</h4>
                          <table className="table table-hover align-middle">
                            <thead>
                              <tr>
                                <th className="text-center">Aantal</th>
                                <th className="text-center">Product</th>
                                <th>Naam</th>
                                <th className="text-center">Prijs per stuk</th>
                                <th className="text-center">Totaal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.values(shipment.packages[packagekey]).map((pack, index) =>
                                pack.map((soldProduct, index) => (
                                  <tr key={index}>
                                    <td className="text-center fw-bold">{soldProduct.amount}x</td>
                                    <td className="text-center">
                                      <img src={productPic} className="p-2 rounded" height={69} width={69} alt="Product" />
                                    </td>
                                      <td>
                                        {soldProduct && soldProduct.product_name ? (
                                          soldProduct.product_name.length > 100 ? (
                                            productsToProcessFullyShown[index] ? (
                                              <>
                                                <span>{soldProduct.product_name}</span>
                                                <span
                                                  className="text-info hover ms-2"
                                                  onClick={() => setProductsToProcessFullyShown(prev => prev.filter(i => i !== index))}>
                                                  (minder)
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span>
                                                  {soldProduct.product_name.slice(0, 90)}...
                                                </span>
                                                <span
                                                  className="text-info hover ms-2"
                                                  onClick={() => setProductsToProcessFullyShown(prev => [...prev, index])}>
                                                  (meer)
                                                </span>
                                              </>
                                            )
                                          ) : (
                                            soldProduct.product_name
                                          )
                                        ) : (
                                          <span className="text-muted">Productnaam niet beschikbaar</span>
                                        )}
                                      </td>
                                    <td className="text-nowrap text-center">
                                      {soldProduct.sale_amount > 0 ? (
                                        <>
                                          <span className="text-decoration-line-through text-secondary">{showPrice(soldProduct.price_incl_tax)}</span><br />
                                          <span className="text-danger">{showSalePrice(soldProduct)}</span>
                                        </>
                                      ) : (
                                        <span>{showPrice(soldProduct.price_incl_tax)}</span>
                                      )}
                                    </td>
                                    <td className="text-nowrap text-center fw-bold">
                                      {showPrice(soldProduct.price_incl_tax * soldProduct.amount - calculateSalePrice(soldProduct))}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      ))}
                  </div>
                ))}

                
              </div>

              {/* Right side container box */}
              <div className="ordersEditRightSide">
                <div className="bg-white rounded-4 p-4 mb-3">
                  <div className="mb-3">
                    <h3>
                      <FontAwesomeIcon icon={faUser} className="text-info" />{' '}
                      Klant
                    </h3>
                  </div>

                  <div className="mb-3">
                    <TDSTextNavigation
                      text={customer.company_name ?? `${customer.first_name ?? ""} ${customer.last_name ?? ""}`}
                      link={getCustomerLink()}
                    /> <br />
                    <div className="d-flex justify-content-between mb-3">
                      <span className="me-2">E-mails:</span>
                      <div className="d-flex flex-column">
                        {emails.map((email, index) => (
                          <div key={index}>
                            <span className=" text-end">{email.email}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="me-2">Telefoonnummers:</span>
                      <div>
                        {phoneNumbers.map((phonenumber, index) => (
                          <div key={index}>
                            <span>{phonenumber.phone_number}</span><br />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <span className="fw-bold">Verzendadres</span> <br />
                    {deliveryAddress ? (
                      <>
                        <span>{deliveryAddress.streetname} {deliveryAddress.housenumber}{deliveryAddress.addition}</span> <br/>
                        <span>{deliveryAddress.living_area} {deliveryAddress.postalcode} {countries.getName(deliveryAddress.country, "nl")}</span>
                      </>
                    ) : (
                      <span>Deze klant heeft nog geen verzendadres</span>
                    )}
                  </div>

                  <div>
                    <span className="fw-bold">Factuuradres</span> <br />
                    {invoiceAddress ? (
                      <>
                        <span>{invoiceAddress.streetname} {invoiceAddress.housenumber}{invoiceAddress.addition}</span> <br/>
                        <span>{invoiceAddress.living_area} {invoiceAddress.postalcode} {countries.getName(invoiceAddress.country, "nl")}</span>
                      </>
                    ) : (
                      <span>Deze klant heeft nog geen Factuuradres</span>
                    )}
                  </div>
                </div>

                <div className="bg-white rounded-4 p-4 mb-3">
                  <div className="mb-3">
                    <h3>Referentie</h3>
                  </div>
                </div>

                {/* <div className="bg-white rounded-4 p-4 mb-3">
                  <div className="mb-3">
                    <h3>E-mails</h3>
                    <button onClick={NewEmail} >Nieuw email</button>
                    <input id="emailSearch" value={} type="textarea"></input> 
                  </div>
                </div> */}

                <div className="d-flex flex-column bg-white rounded-4 p-4 mb-3">
                  {productsToProcess.length > 0 ? (                  
                    <div className="d-flex flex-row">
                      <div className="col-5">
                        <h3>Email sturen</h3>
                      </div>
                    <div className="col-3"></div>
                      <SendEmailModal order={order} emails={emails} />
                  </div>) : <h3>Order is al verwerkt, u kunt geen nieuwe mail sturen.</h3>
                  }
                  
                  <div>
                    {/* Email binnen krijgen modal hier enzo*/}
                    {/* <ReceiveEmailModal/> */}
                  </div>

                </div>

                <div className="bg-white rounded-4 p-4 mb-3">
                  <div className="mb-3">
                    <h3>Notitie</h3>
                  </div>

                  <div>
                    <textarea
                      className="form-control"
                      value={order.note ?? ''}
                      name="note"
                      rows={5}
                      onChange={handleOrderOnChange}
                      onBlur={handleSaveNote}
                      placeholder="Maak hier uw notities"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default OrdersEdit;