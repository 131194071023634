import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import middleware from "../../../Api/Middleware";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import arrowCircleRight from "../Icons/Arrow-in-circle-right.svg";
import '../../../Main/Main.css'
import "../EditCustomers.css"

const Overzicht = ({ NavigateTab, typeCustomer }) => {
  const { internal_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [consumerData, setConsumerData] = useState({});
  const [addressesData, setAddressesData] = useState([]);

  const fetchAll = async () => {
    setLoading(true);

    try {
      const [consumerRes, addressesRes] = await Promise.all([
        middleware.get(`sp/customerHubOverview?specificSearchColumn=internal_id&searchTerm=${internal_id}`),
        middleware.get(`sp/consumeraddresses?specificSearchColumn=internal_id&searchTerm=${internal_id}`)
      ]);

      setConsumerData(consumerRes.data[0]);
      setAddressesData(addressesRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const splitString = (str) => {
    return str ? str.split(',').map(item => item.trim()) : [];
  };

  if (loading) {
    return <h2 className="m-4 loader"></h2>
  }

  return (
    <div className='d-flex flex-row col-12 justify-content-between HeightOverviewInfo'>
      <div className='widthOverviewSmallboxes'>
        <div className='whiteBox mb-4 generalInfoBox'>
          <h5 className='fw-bold mb-3'>Algemeen</h5>
          <div>
            <label className='labelGenerelInfoitems fw-medium'>Klantnummer</label>
            <div className='fw-medium mb-3'>{consumerData.internal_id}</div>
            <label className='labelGenerelInfoitems fw-medium'>Relatiesoort</label>
            <div className='fw-medium mb-3'>{consumerData.relation}</div>
            {consumerData.company_name &&
            <>
              <label className='labelGenerelInfoitems fw-medium'>Bedrijfsnaam</label>
              <div className='fw-medium mb-3'>{consumerData.company_name}</div>
            </>
            }
            {consumerData.first_name && consumerData.last_name &&
            <>
              <label className='labelGenerelInfoitems fw-medium'>Voornaam</label>
              <div className='fw-medium mb-3'>{consumerData.first_name}</div>
              <label className='labelGenerelInfoitems fw-medium'>Achternaam</label>
              <div className='fw-medium mb-3'>{consumerData.last_name}</div>
            </>
            }
            <label className='labelGenerelInfoitems fw-medium'>E-mailadres</label>
            {splitString(consumerData.emails).map((email, index) => (
              <div className='fw-medium mb-2' key={index}>{email}</div>
            ))}
            <label className='labelGenerelInfoitems fw-medium'>Telefoonnummer</label>
            {splitString(consumerData.phone_numbers).map((phone, index) => (
              <div className='fw-medium mb-2' key={index}>{phone}</div>
            ))}
            <label className='labelGenerelInfoitems fw-medium'>Geboortedatum</label>
            <div className='fw-medium'>{consumerData.birthday} ({consumerData.age})</div>
          </div>
        </div>
        <div className='whiteBox px-4 py-3 adressInfoBox'>
          <h5 className='fw-bold mb-3'>Adresgegevens</h5>
          {addressesData && addressesData?.map((address, index) => <div key={index}>
            <label className='noteTitle fw-medium text-nowrap'>{address.address_type}</label>
            <div>
              <p className='mb-0 fw-medium'>{address.street}</p>
              <p className='mb-0 fw-medium'>{address.location}</p>
              <p className='mb-0 fw-medium'>{address.country}</p>
            </div>
          </div>)}
        </div>
      </div>
      <div className='widthOverviewLargeboxes'>
        <div className='whiteBox heightLargeboxesOverview'>
          <h5 className='fw-bold mb-3'>Bestellingen</h5>
          <div className='d-flex flex-row justify-content-start'>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview fw-bold'>{consumerData.open_orders}</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab4")}>
                <p className='mb-0 me-4 darkBlueLinkText fontSize15' >Open bestellingen</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight} alt="arrow"/>
              </div>
            </div>

            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview fw-bold'>€ {consumerData.unpaid_order_amount}</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab4")}>
                <p className='mb-0 me-4 darkBlueLinkText fontSize15'>Openstaand bedrag</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight} alt="arrow"/>
              </div>
            </div>

            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview fw-bold'>{consumerData.completed_orders}</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab4")}>
                <p className='mb-0 me-4 darkBlueLinkText fontSize15'>Afgeronde bestellingen</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight} alt="arrow"/>
              </div>
            </div>
          </div>
        </div>
        <div className='whiteBox heightLargeboxesOverview'>
          <h5 className='fw-bold mb-3'>Offertes</h5>
          <div className='d-flex flex-row justify-content-start'>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview fw-bold'>{consumerData.open_offers}</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab5")}>
                <p className='mb-0 me-4 darkBlueLinkText fontSize15'>Openstaande offertes</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight} alt="arrow"/>
              </div>
            </div>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview fw-bold'>{consumerData.signed_offers}</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab5")}>
                <p className='mb-0 me-4 darkBlueLinkText fontSize15'>Getekende offertes</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight} alt="arrow"/>
              </div>
            </div>
          </div>
        </div>
        <div className='whiteBox heightLargeboxesOverview'>
          <h5 className='fw-bold mb-3'>Facturen</h5>
          <div className='d-flex flex-row justify-content-start'>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview fw-bold'>{consumerData.open_invoices}</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab6")}>
                <p className='mb-0 me-4 darkBlueLinkText fontSize15'>Open facturen</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight} alt="arrow"/>
              </div>
            </div>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview fw-bold'>€ {consumerData.unpaid_invoice_amount}</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab6")}>
                <p className='mb-0 me-4 darkBlueLinkText fontSize15'>Openstaand bedrag</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight} alt="arrow"/>
              </div>
            </div>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview fw-bold'>€ {consumerData.ready_to_invoice_amount}</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab6")}>
                <p className='mb-0 me-4 darkBlueLinkText fw-medium fontSize15'>Klaar om te facturen</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight} alt="arrow"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overzicht;
