import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../Navbars/Sidebar/Logo Unified SMB - Wit.svg';
import middleware from '../Api/Middleware';
import "./Login.css"

export const removeLoginCookies = ( navigate = null ) => {
  const cookies = new Cookies();
  cookies.remove('token', { path: '/' });
  
  if (navigate) {
    navigate(`/login`);
  }
};

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const cookies = new Cookies();
  const navigate = useNavigate();

  const expirationDate = new Date();
  expirationDate.setHours(23, 59, 59, 0); // Set the time to 23:59:59.000

  useEffect(() => {
    (async () => {
      const key = cookies.get('token');
      if (key) {
        try {
          await middleware.post(`auth`, {
            ApiKey: key,
          });
          navigate('/');
        } catch (error) {
          if (error.response.status !== 401) {
            console.warn(error.response.data);
          }
          removeLoginCookies();
        }
      }
    })()
  }, []);
  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await middleware.post(`auth`, {
        email: email,
        password: password,
      });
      cookies.set('token', response.data.api_token, { path: '/', expires: expirationDate });
      navigate('/');
    } catch (error) {
      setError(error.response.data.error);
    }
  }
  
  return (
    <div className='backgroundGradient'>
      <div className='d-flex justify-content-center h-100'>
        <form onSubmit={handleLogin} className=''>
          <div className=''>
            <img src={logo} className="logoLogin" alt="techdoglogo"/>
          </div>
          <div className="mb-4 input-group-sm">
            <input type="email" name='email' required onChange={e => setEmail(e.target.value)} className="form-control inputsUserCredentials" autoComplete='email' placeholder="Email"/>
          </div>
          <div className="mb-4 input-group-sm">
            <input type="password" name='password' required onChange={e => setPassword(e.target.value)} className="form-control inputsUserCredentials" autoComplete='current-password' placeholder="Wachtwoord"/>
          </div>
          <div className='mb-3 input-group-sm'>
            <button type="submit" className="btn w-100 rounded-pill loginButton">Inloggen</button>
          </div>
          <div className='w-100 d-flex justify-content-center'>
            <div className='forgotPassword' onClick={e => navigate('/wachtwoord-vergeten')}>Wachtwoord vergeten</div>
          </div>
        <span className='text-danger fw-bolder fs-5 text-wrap text-center'>{error}</span>
        </form>
      </div>
    </div>
  );
}

export default Login;