import React, { useMemo, useState, useEffect } from "react";
import "./Table.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faChevronRight, faChevronLeft, faMagnifyingGlass, faXmark, faChevronDown, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'

function TablePreset({data = [], tableLoading, actionOptions = [], leftButtons, rightButtons, rightDropdowns, setPagination, handleSelection, searchBar}) {
    
    // Use optional chaining to safely access nested properties and provide fallback values to avoid errors.
    const tableInstance = data[0]?.tableInstance ?? {};
    const selectedIds = data[0]?.selectedIds ?? [];
    const pagination = data[0]?.pagination ?? {};
    const { getTableProps = () => {}, getTableBodyProps = () => {}, headerGroups = [], rows = [], prepareRow = () => {} } = tableInstance;
    const options = actionOptions;
    const [showActionDropdown, setShowActionDropdown] = useState(false);
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
    const usingPagination = setPagination ? true : false;
    const usingData = data?.length > 0 ? true : false;

    return (
        <>
        <div className="d-flex justify-content-between">
            <div className="d-flex flex-row gap-3">
                {actionOptions.length >= 1 && options &&
                <div className="selectContainerPresetStyle">
                    <>
                        <div className={"selectPresetStyle " + (showActionDropdown ? "open" : "") + " fitContent justify-content-between fw-medium"} value={"placeholder"} onClick={e => setShowActionDropdown(!showActionDropdown)}>
                            Actie <FontAwesomeIcon icon={faChevronDown} className="ms-3" style={{fontSize: "12px"}}/>
                        </div>
                        <div className={"optionsContainerStyle " + (showActionDropdown ? "open" : "closed")}>
                            {options.map((button, index) =>
                                <div className="optionStyle Nunito fw-semibold" key={index} value={index} onClick={e => button.function() + setShowActionDropdown(!showActionDropdown)}>
                                    {button.title}
                                </div>
                            )}
                        </div>
                    </>
                </div>
                }
                {leftButtons && leftButtons == undefined || leftButtons?.length < 1 ? null : (
                    leftButtons?.map((map, index) => (
                        <div
                            key={index}
                            className="buttonPresetStyle extraButtonsLeft Nunito fw-semibold"
                            onClick={() => map.function()}
                        >
                        {map.title}
                        </div>
                    ))
                )}
                {searchBar && searchBar[0].shown ? (
                    <input 
                    className="tableSearchBar rounded-pill px-3" 
                    type="text"
                    placeholder="Zoeken..."
                    value={searchBar[0].value}
                    onChange={e => searchBar[0].function(e.target.value)}
                    />
                ) : null}
            </div>
            <div className="d-flex flex-rom gap-3">
                {rightDropdowns === undefined || rightDropdowns?.length < 1 ? null : (
                    rightDropdowns.map((rightDropdown, index) => (
                        <div key={index}>
                            <select value={rightDropdown.value} onChange={rightDropdown.function}>
                                <option value=''>{rightDropdown.title}</option>
                                {rightDropdown.options.map((option, optionIndex) => (
                                    <option key={optionIndex} value={option[rightDropdown.primary_key]}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                    ))
                )}

                {rightButtons && rightButtons == undefined || rightButtons?.length < 1 ? null : (
                    rightButtons?.map((map, index) => (
                        <div
                            key={index}
                            className="buttonPresetStyle newButtons Nunito"
                            onClick={() => map.function()}
                        >
                        {map.title}
                        </div>
                    ))
                )}

                {/* {rightDropdowns === undefined || rightDropdowns?.length < 1 ? null : (
                    rightDropdowns.map((rightDropdown, index) => (
                        <div key={index} className="selectContainerPresetStyle">
                            <div className={"selectPresetStyle Nunito fw-semibold" + (activeDropdownIndex === index ? " open" : "") + " justify-content-between fw-medium"} value={"placeholder"} onClick={e => setActiveDropdownIndex(activeDropdownIndex === index ? null : index)}>
                                {rightDropdown.title} <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                            <div className={"optionsContainerStyle " + (activeDropdownIndex === index ? " open" : "closed")}>
                                {rightDropdown.options?.map((button, idx) =>
                                    <div className="optionStyle Nunito fw-semibold" key={idx} value={idx} onClick={e => button.function()}>
                                        {button.title}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                )} */}
            </div>
        </div>
        {usingData ?
            (tableLoading ? 
                <div className="tableLoadingScreen flex-column">
                    <div className="spinner-border text-white mb-3" role="status" style={{ height: '100px', width: '100px'}}/>
                    <h3 style={{maxWidth: 'fit-content'}} className=" text-white mb-5">Laden...</h3>
                </div>
            :
                <div className="tablePresetBackground">
                    <table className="tablePreset" {...getTableProps()}>
                        <thead className="customTheadPreset">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th className="px-4 py-3 header-filter tablePresetHeaderRow" {...column.getHeaderProps()}>{column.render("Header")}</th>
                                ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="customTbodyPreset" {...getTableBodyProps()}>
                        {rows.map(row => {
                        const firstKey = Object.keys(row.original)[0];
                        prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} 
                                    className="tablePresetDataRow"
                                    >
                                    {row.cells.map((cell, index) => (
                                        <td
                                        onClick={index === 0 ? (e => 
                                        {const isSelected = selectedIds.includes(row.original[firstKey])
                                            if (isSelected) {
                                            handleSelection(row.original[firstKey], false)
                                            } else {
                                            handleSelection(row.original[firstKey], true)
                                            }
                                        }) : null}
                                        className="px-4 py-2" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            )
            :
            (
                <div className="tableLoadingScreen flex-column">
                    {/* <div className="spinner-border text-white mb-3" role="status" style={{ height: '100px', width: '100px'}}/> */}
                    <h3 style={{maxWidth: 'fit-content'}} className=" text-white mb-5">Deze tabel heeft geen data</h3>
                </div>
            )
        }
        <div className="d-flex justify-content-between py-1 w-100">
            <div className="d-flex gap-0 ">
                <select value={pagination.page_size} onChange={e => setPagination({...pagination, 'page_size': Number(e.target.value), 'page': 1})} className="page-sizer Nunito">
                    {/* <option value={1}>1</option> */}
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </select>
                <div className="page-display text-nowrap me-4 Nunito">
                    {pagination.begin_of_page}-{pagination.end_of_page} van {pagination.max_items}
                </div>
            </div>
            <div className="d-flex gap-1">
                <button 
                    onClick={e => setPagination( {...pagination, "page":  1 })} 
                    disabled={pagination.page == 1 ? true : false} 
                    type="button" 
                    className="btn rounded-2 border-0 px-1 text-nowrap toFirstLastPage"
                    >
                    <FontAwesomeIcon className="text-white" icon={faAnglesLeft}/>
                </button>
                <button 
                    onClick={e => setPagination( {...pagination, "page":  pagination.page - 1}) } 
                    disabled={pagination.page <= 1 ? true : false} 
                    type="button" 
                    className="btn rounded-2 border-0 pageBackNext"
                    >
                    <FontAwesomeIcon className="text-white" icon={faChevronLeft}/>
                </button>
                <button
                onClick={e => setPagination( {...pagination, "page":  pagination.page - 1}) } 
                hidden={pagination.page <= 1 ? true : false} 
                type="button" 
                className="btn rounded-2 border-0 bg-white text-center pageNumBlocks Nunito"
                style={{fontWeight: "600", color: "#009fe3", width: "30px", height: "30px"}}
                >
                    {pagination.page - 1}
                </button>
                <button
                    
                type="button" 
                className="btn rounded-2 border-0 bg-white text-center pageNumBlocks Nunito"
                disabled
                style={{fontWeight: "600", color: "#009fe3", cursor: "default", width: "30px", height: "30px"}}
                >
                    {pagination.page}
                </button>
                <button
                onClick={e => setPagination( {...pagination, "page":  pagination.page + 1}) } 
                hidden={(Math.ceil(pagination.max_items / pagination.page_size)) <= pagination.page ? true : false} 
                type="button" 
                className="btn rounded-2 border-0 bg-white text-center pageNumBlocks Nunito"
                style={{fontWeight: "600", color: "#009fe3", width: "30px", height: "30px"}}
                >
                    {pagination.page + 1}
                </button>
                <button
                onClick={e => setPagination( {...pagination, "page":  pagination.page + 1}) } 
                disabled={((Math.ceil(pagination.max_items / pagination.page_size)) == pagination.page || (pagination.max_items <= 0 ) ? true : false)} 
                type="button" 
                className="btn rounded-2 border-0 pageBackNext"
                >
                    <FontAwesomeIcon className="text-white" icon={faChevronRight}/>
                </button>
                <button
                onClick={e => (Math.ceil(pagination.max_items / pagination.page_size)) == 0 ? setPagination({...pagination, "page": 1}) : setPagination({...pagination, "page":  Math.ceil(pagination.max_items / pagination.page_size)})} 
                disabled={((Math.ceil(pagination.max_items / pagination.page_size)) == pagination.page || (pagination.max_items <= 0 ) ? true : false)}
                type="button" 
                className="btn rounded-2 border-0 text-nowrap px-1 toFirstLastPage"
                >
                    <FontAwesomeIcon className="text-white" icon={faAnglesRight}/>
                </button>
            </div>
        </div>
    </>
    );
}

export default TablePreset;