import React, { useState, useEffect } from 'react';
import { Modal, Toast } from 'react-bootstrap';
import warning from "../Icons/Warning-sign.svg";
import ToastWarning from '../../../Toasts/ToastWarning';
import ToastError from '../../../Toasts/ToastError';

const ConvertToBusiness = ({ openConvertToBusiness, closeConvertToBusiness, onConvertConfirmed }) => {
  const [showConvertToBusiness, setShowConvertToBusiness] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const [formData, setFormData] = useState({
    companyName: '',
    companyNumber: '',
    vatNumber: ''
  });

  useEffect(() => {
    if (openConvertToBusiness) {
      setShowConvertToBusiness(true);
      setConfirmationStep(false);
    }
  }, [openConvertToBusiness]);

  const closeConvertToBusinessModal = () => {
    setShowConvertToBusiness(false);
    closeConvertToBusiness();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const isValidForm = () => {
    if (formData.companyNumber.length > 12) {
      ToastError("KVK nummer mag niet langer zijn dan 12 nummers")
    } else if (formData.companyName && formData.companyNumber && formData.vatNumber) {
      return true
    } else {
      ToastWarning("Vul alle velden in om de conversie te voltooien.");
    }

  };
  

  const handleConfirm = async () => {
    if (!confirmationStep) {
      setConfirmationStep(true);
    } else {
      // Controleer of alle velden zijn ingevuld voordat je doorgaat
      if (isValidForm()) {
        await onConvertConfirmed(formData);
        closeConvertToBusinessModal();
      } 
    }
  };

  return (
    <Modal
        id="modalBlueprint"
        show={showConvertToBusiness}
        onHide={closeConvertToBusinessModal}
        className="colormodals"
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton className="modalTop justify-content-end" style={{height: "60px"}}>
        </Modal.Header>
        <Modal.Body className="modalCenter d-flex justify-content-center">
          {confirmationStep ? (
            <div className='d-flex flex-column justify-content-center w-100'>
              <h3>Vul ontbrekende info in om te converteren naar bedrijf</h3>
              <div className='d-flex flex-column'>
                <label className='fw-semibold mb-2' >Bedrijfs naam</label>
                <input
                  type="text"
                  name="companyName"
                  placeholder="Bedrijfsnaam"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  className="mb-3 inputFieldAddCustomer px-3"
                />
              </div>
              <div className='d-flex flex-column'>
                <label className='fw-semibold mb-2' >Kvk nummer</label>
                <input
                  type="number"
                  name="companyNumber"
                  placeholder="KvK-nummer"
                  max={12}
                  min={0}
                  value={formData.companyNumber}
                  onChange={handleInputChange}
                  className="mb-3 inputFieldAddCustomer px-3"
                />
              </div>
              <div className='d-flex flex-column'>
                <label className='fw-semibold mb-2' >BTW nummer</label>
                <input
                  type="text"
                  name="vatNumber"
                  placeholder="BTW-nummer"
                  value={formData.vatNumber}
                  onChange={handleInputChange}
                  className="mb-3 inputFieldAddCustomer px-3"
                />
              </div>
              <button className='btn lightBlueStandardButton' onClick={handleConfirm}>Bevestigen</button>
            </div>
          ) : (
            <div className='d-flex flex-column justify-content-center'>
              <img style={{width: "75px" }} className=' d-flex align-self-center mb-4' src={warning} alt="Warning" />
              <div className='w-100 d-flex justify-content-center mb-3'>
                <h3 style={{width: "60%"}} className='text-center'>Weet je zeker dat je deze consumer wilt veranderen naar een bedrijf?</h3>
              </div>
              <div className='d-flex justify-content-between align-self-center' style={{width: "60%"}}>
                <button className='btn DarkStandardButton' onClick={closeConvertToBusinessModal}>Annuleren</button>
                <button style={{padding: "5px 15px"}} className='btn lightBlueStandardButton' onClick={handleConfirm}>Ja, ik weet het zeker</button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="modalBottom">
        </Modal.Footer>
      </Modal>
  );
};

export default ConvertToBusiness;
