import React, { useState, useEffect } from "react";
import CategoryList from "../sub_components/CategoryList";

const Categories = ({
  initialCategories,
  setCheckedCategories,
  checkedCategories,
  setUpdatedCategories,
}) => {
  // A helper function that recursively organizes the products based on their headId.
  const organizeCategory = (categories, parentId = null) => {
    // Ensure the input is an array
    return categories
      .filter((category) => category.headId === parentId)
      .map((category) => {
        return {
          ...category,
          subcategories: organizeCategory(categories, category.id),
        };
      });
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [categoryTree, setCategoryTree] = useState(
    organizeCategory(initialCategories)
  );
  const [filteredCategory, setFilteredCategory] = useState(categoryTree);
  const [initalCategoryTree, setInitalCategoryTree] = useState(
    organizeCategory(initialCategories)
  );
  useEffect(() => {
    setFilteredCategory(categoryTree);
    // setFilteredCategoryNew(categoryTreeNew);
  }, [categoryTree /*, categoryTreeNew*/]);

  useEffect(() => {
    filterCategory(searchQuery);
  }, [searchQuery]);

  function filterCategoriesByName(categories, query) {
    // Function to recursively search and filter categories
    function search(category) {
      // Check if the current category or its subcategories match the query
      let hasMatch = category.name.toLowerCase().includes(query.toLowerCase());
      let newSubcategories = [];

      // Recursively search through subcategories
      for (let sub of category.subcategories) {
        let result = search(sub);
        if (result) {
          newSubcategories.push(result);
          hasMatch = true; // Parent needs to be included if any child matches
        }
      }

      // Return the category if there's a match or one of its subcategories matched
      if (hasMatch) {
        return { ...category, subcategories: newSubcategories };
      }
      return null;
    }

    // Filter through the main categories
    return categories.map((category) => search(category)).filter(Boolean);
  }

  const Category = ({ category }) => {
    if (
      Object.keys(checkedCategories).includes(
        `1-${category.primary_key}-${category.id}-${category.headId ?? 0}`
      )
    ) {
      return (
        <div style={{ marginLeft: category.headId ? "20px" : "0px" }}>
          <div className="d-flex flex-row align-items-center ms-2">
            {category.headId != undefined && (
              <div className="lineCategories me-2"></div>
            )}
            <p className="mb-0 fw-medium"> {category.name}</p>
          </div>
          {category.subcategories && category.subcategories.length > 0 && (
            <div>
              {category.subcategories.map((sub) => (
                <Category key={sub.id} category={sub} />
              ))}
            </div>
          )}
        </div>
      );
    }
  };

  const CategoryHierarchy = ({ data }) => {
    return data.map((e) => {
      return (
        <div key={e.id}>
          <Category category={e} />
        </div>
      );
    });
  };

  // Find a category inside a nested tree
  function findCategoryById(categories, targetId) {
    for (const category of categories) {
      if (category.id === targetId) {
        return category; // If the ID matches, return the category
      }

      if (category.subcategories && category.subcategories.length > 0) {
        const result = findCategoryById(category.subcategories, targetId); // Recursively search in subcategories
        if (result) {
          return result; // If the category is found in subcategories, return it
        }
      }
    }

    return null; // Return null if no matching category is found
  }

  function checkCategoryChildren(object, checkedC) {
    if (object.subcategories) {
      object.subcategories.forEach((e) => {
        delete checkedC[`1-${e.primary_key}-${e.id}-${e.headId ?? 0}`];
        checkedC = checkCategoryChildren(e, checkedC);
      });
    }
    return checkedC;
  }

  // Enables a items parents and disables a parents children recursively
  function recurisveToggle(object, checkedC, checked) {
    if (object.headId) {
      let element = initialCategories.find((e) => e.id == object.headId);
      if (checked) {
        checkedC[
          `1-${element.primary_key}-${element.id}-${element.headId ?? 0}`
        ] = true;
      } else {
        delete checkedC[
          `1-${element.primary_key}-${element.id}-${element.headId ?? 0}`
        ];
      }
      if (element.headId) {
        checkedC = recurisveToggle(element, checkedC, checked);
      }
    }
    return checkedC;
  }
  const toggleSimple = (id) => {
    setCheckedCategories((prev) => {
      if (setUpdatedCategories != null) {
        setUpdatedCategories(true);
      }
      let parts = id.split("-");
      let v = initialCategories.find((e) => e.id == parts[2]);
      let newCheckedCategory = { ...prev };
      if (!newCheckedCategory[id] == false) {
        newCheckedCategory = checkCategoryChildren(
          findCategoryById(initalCategoryTree, v.id),
          newCheckedCategory
        );
        delete newCheckedCategory[id];
        return newCheckedCategory;
      }
      newCheckedCategory = recurisveToggle(v, newCheckedCategory, !prev[id]);
      if (newCheckedCategory[id] == true) {
        delete newCheckedCategory[id];
      } else {
        newCheckedCategory[id] = true;
      }
      return newCheckedCategory;
    });
  };
  // A function to filter products.
  const filterCategory = (query) => {
    if (!query) {
      setCategoryTree(organizeCategory(initialCategories));
      return;
    }

    setCategoryTree(filterCategoriesByName(initalCategoryTree, query));
  };

  return (
    <div className="whiteBox boxCategories mb-4">
      <div className="d-flex flex-column">
        <div className=" widthBoxes w-100 mb-2 p-3 categories">
          <div className="d-flex  justify-content-between ">
            <h5 className="inputTitle mb-2">Categorieën</h5>
          </div>

          <div className="    sellChannelBoxContainer mb-3">
            <div className="categorieënBox">
              <div className="px-3 d-flex position-sticky py-2 inputFieldCategory">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Zoek categorie"
                  className="w-100 searchInput SearchIcon"
                />
              </div>
              <div className="categoryList">
                <CategoryList
                  categories={filteredCategory}
                  checkedCategories={checkedCategories}
                  toggle={toggleSimple}
                />
              </div>
            </div>
          </div>
          <div>
            <p className="fw-medium text-black">Geselecteerde categoriën</p>
            <div>
              <CategoryHierarchy data={initalCategoryTree} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
