import React from "react";
import TextEditor from "../../../../Main/TextEditor";

const FirstBox = (props) => {
  return (
    <>
      {/* First Box */}
      <div className="whiteBox p-5 mt-0 align-center">
        <div className="d-flex flex-column">
          <label className="inputTitle me-2">Productnaam</label>
          <input
            className="inputField col-12 standardInput"
            value={props?.product?.product_name ?? ""}
            onChange={(e) =>
              props.setProduct({
                ...props.product,
                product_name: e.target.value,
              }) + props.setUpdatedProduct(true)
            }
          />
        </div>
        <div className="d-flex flex-row productNumSelectLists col-12 justify-content-between">
          <div className="d-flex flex-column" style={props.product.type != 2 ? ({ width: "32%"}) : ( {width: "45%"}) }>
            <div className="d-flex flex-nowrap w-100">
              <label className="inputTitle me-2">Productnummer</label>
            </div>
            <input
              type="number"
              className="inputField px-2 standardInput webkitRemoves"
              name="product_number"
              defaultValue={props?.product?.product_number ?? ""}
              onChange={(e) =>
                props.setProduct({
                  ...props.product,
                  product_number: e.target.value,
                }) + props.setUpdatedProduct(true)
              }
              // disabled={!edit}
              required
            />
          </div>
          {props.product.type != 2 ? (
          <div className="d-flex flex-column" style={{ width: "32%" }}>
  <div className="d-flex flex-row justify-content-between">
    <label className="inputTitle me-2">Barcode</label>
    <select
      className="optionsList productOptions me-4"
      id="sel1"
      name="sellist1"
      value={props?.selectedOption ?? ""}
      onChange={(e) => {
        props.setSelectedOption(e.target.value);
        props.setUpdatedBarcode(true);
      }}
    >
      <option value="3" hidden>-- Selecteer een optie --</option>
      <option value="0">Aangepaste barcode</option>
      <option value="1">Gelijk aan productnummer</option>
      <option value="2">Automatisch gegenereerd</option>
    </select>
  </div>
  <div className="d-flex flex-column me-4 mt-0 marginRight0">
    {props.selectedOption === "0" && (
      <input
        type="number"
        className="mb-4 standardInput px-2 webkitRemove"
        placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
        value={props?.adjustedBarcode ?? ""} //null
        onChange={(e) => {
          props.setBarcode(e.target.value);
          props.setAdjustedBarcode(e.target.value);
          props.setUpdatedBarcode(true);
        }}
      />
    )}
    {props.selectedOption === "1" && (
      <input
        type="number"
        className="mb-4 standardInput px-2 webkitRemove"
        placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
        value={props?.product?.product_number ?? ""}
        disabled
      />
    )}
    {props.selectedOption === "2" && (
      <input
        type="number"
        className="mb-4 standardInput px-2 webkitRemove"
        placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
        value={props?.generatedBarcode ?? ""}
        disabled
      />
    )}
    {props.selectedOption === "3" && (
      <input
        type="number"
        className="mb-4 standardInput px-2 webkitRemove"
        placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
        value={props?.barcode ?? ""}
        disabled
      />
    )}
  </div>
</div>
) : (
  <></>
)}
          <div className="d-flex flex-column" style={props.product.type != 2 ? ({ width: "32%"}) : ( {width: "50%"}) }>
            <div className="d-flex flex-column me-4 marginRight0">
              <label className="inputTitle">
                Merk{" "}
                <props.FontAwesomeIcon
                  icon={props.faPlus}
                  color="#6a6a6a"
                  className="hover"
                  onClick={() => props.setShowBrandModal(true)}
                  data-bs-toggle="exampleModal"
                  data-bs-target="#exampleModal"
                />
              </label>
              <select
                className="standardInput mb-2 px-2 standardDropdownArrow"
                id="sel1"
                name="sellist1"
                value={props.brand?.brand_id ?? ""}
                onChange={(e) =>
                  props.setProductBrand({
                    ...props.brand,
                    brand_id: e.target.value,
                  }) + props.setUpdatedProduct(true)
                }
              >
                <option hidden></option>
                {props.brands.map((brands) => {
                  return (
                    <option key={brands.brand_id} value={brands.brand_id}>
                      {brands.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <label className="inputTitle">Product beschrijving</label>
            <TextEditor
              onChange={(e) =>
                props.setProduct({ ...props.product, product_description: e }) +
                props.setUpdatedProduct(true)
              }
              value={props.product.product_description}
            />
          </div>
        </div>
      </div>
      
      {/* First Box end */}
    </>
  );
};

export default FirstBox;
