import React, { useMemo, useState, useRef, forwardRef, useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../Navbars/Sidebar/Sidebar";
import Navbar from "../../../Navbars/Navbar/Navbar";
import middleware from "../../../Api/Middleware";
import saleschannels from "../../../Api/SalesChannels";
import "../css/AddProductPages.css";
import "../../../Main/Main.css";
import "../../product.css";
import PageTemplate from "../../../Templates/PageTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPenToSquare,
  faCaretLeft,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../LoadingScreen/LoadingScreen";
import TablePreset from "../../../Table/TablePreset";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import ToastWarning from "../../../Toasts/ToastWarning";
import useUpdateEffect from "./useUpdateEffect";
import Backbutton from "../images/BackIcon.svg";
// Media
import "../css/UploadArea.css";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
// boxes
import FirstBox from "./edit_components/boxfirst";
import SecondBox from "./edit_components/boxsecond";
import ThirdBox from "./edit_components/boxthird";
import FourthBox from "./edit_components/boxfourth";
import BoxVariation from "./edit_components/BoxVariation";
import MediaUploaderComponent from "../../../MediaUpload/MediaUploaderComponent";
import Categories from "../sub_components/Categories";
import { color } from "@uiw/react-color";

const EditPage = () => {
  // variables

  //variation
  const [allVariations, setAllVariations] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [allColors, setAllColors] = useState([]);

  const { product_id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [showBrandModal, setShowBrandModal] = useState(true);
  const [selectedOption, setSelectedOption] = useState("3");
  const [tax, setTax] = useState("0");
  const [taxClass, setTaxClass] = useState("zero");
  const [selectedTax, setSelectedTax] = useState("");
  const [product, setProduct] = useState([{}]);
  const [oldProduct, setOldProduct] = useState([{}]);
  const [barcode, setBarcode] = useState([{}]);
  const [adjustedBarcode, setAdjustedBarcode] = useState([{}]);
  const [oldBarcode, setOldBarcode] = useState([{}]);
  const [barcodeId, setBarcodeId] = useState([{}]);
  const [brands, setBrands] = useState([{}]);
  const [prices, setPrices] = useState({
    price: { incl_tax: "", excl_tax: "" },
    sale_price: { incl_tax: "", excl_tax: "" },
    buy_in_price: { incl_tax: "", excl_tax: "" },
  });
  const [stock, setStock] = useState([{
    "minimal": "",
    "maximal": "",
    "amount": "",
    "stock_behavior": 0,
    "deliverability": 0
}]);
  const [suppliers, setSuppliers] = useState([{}]);
  const [businesses, setBusinesses] = useState([{}]);
  const [attributeConnections, setAttributeConnections] = useState([{}]);
  const [updatedProduct, setUpdatedProduct] = useState(false);
  const [updatedBarcode, setUpdatedBarcode] = useState(false);
  const [updatedBrands, setUpdatedBrands] = useState(false);
  const [updatedPrices, setUpdatedPrices] = useState(false);
  const [updatedMedia, setUpdatedMedia] = useState(false);
  const [updatedGender, setUpdatedGender] = useState(false);
  const [updatedCategories, setUpdatedCategories] = useState(false);
  const [updatedStock, setUpdatedStock] = useState(false);
  const [updatedVariations, setUpdatedVariations] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [productsHasBrands, setProductsHasBrands] = useState([{}]);
  const [productBrand, setProductBrand] = useState({});
  const [generatedBarcode, setGeneratedBarcode] = useState({});
  const [categories, setCategories] = useState([]);
  const [genders, setGenders] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [errors, setErrors] = useState([]);
  // set to track errors to prevent duplicate errors
  const processedErrors = new Set();

  // State for holding categories.
  const [initialCategories, setInitialCategories] = useState([
    { id: 1, headId: null, name: "Categories" },
  ]);

  const [initialCategoriesNew, setInitialCategoriesNew] = useState([
    { id: 1, headId: null, name: "Categories" },
  ]);

  // A helper function that recursively organizes the products based on their headId.
  const organizeCategory = (categories, parentId = null) => {
    // Ensure the input is an array
    return categories
      .filter((category) => category.headId === parentId)
      .map((category) => {
        return {
          ...category,
          subcategories: organizeCategory(categories, category.id),
        };
      });
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedCategories, setCheckedCategories] = useState({});
  const [checkedCategory, setCheckedCategory] = useState({});
  const [categoryTree, setCategoryTree] = useState(
    organizeCategory(initialCategories)
  );
  const [categoryTreeNew, setCategoryTreeNew] = useState(
    organizeCategory(initialCategoriesNew)
  );
  const [filteredCategory, setFilteredCategory] = useState(categoryTree);
  const [showCategory, setShowCategory] = useState(false);

  // Variables end

  // Functions

  const fetchAll = async () => {
    const [
      fetchBrands,
      fetchProduct,
      fetchStock,
      fetchPrices,
      fetchBarcode,
      fetchAttributeConns,
      fetchSuppliers,
      fetchBusinesses,
      fetchProductsHasBrands,
      fetchGeneratedBarcode,
      fetchCategories,
      fetchGenders,
      fetchSelectedCategories,
      fetchSelectedGenders,
      fetchAllSizes,
      fetchAllColors,
    ] = await Promise.all([
      middleware.get(`products/brands`),
      middleware.get(`products?product_id=${product_id}`),
      middleware.get(`products/stock?product_id=${product_id}`),
      middleware.get(`products/prices?product_id=${product_id}`),
      middleware.get(`products/barcodes?product_id=${product_id}`),
      middleware.get(`products/attributes/connection?product_id=${product_id}`),
      middleware.get(`suppliers`),
      middleware.get(`businesses`),
      middleware.get(`products/hasbrands`),
      middleware.get("products/generateBarcode"),
      middleware.get(`products/category`),
      middleware.get(`products/genders`),
      middleware.get(`products/hascategories?product_id=${product_id}`),
      middleware.get(`products/hasgenders?product_id=${product_id}`),
      middleware.get(`products/sizes`),
      middleware.get(`products/colors`),
    ]);
    setProduct(fetchProduct.data);
    setOldProduct(fetchProduct.data);
    setBarcode(fetchBarcode.data[0]?.barcode);
    setOldBarcode(fetchBarcode.data[0]?.barcode);
    setBarcodeId(fetchBarcode.data[0]?.barcode_id);
    setBrands(fetchBrands.data);
    setPrices(fetchPrices.data);
    setStock(...fetchStock.data);
    setSuppliers(fetchSuppliers.data);
    setBusinesses(fetchBusinesses.data);
    setAttributeConnections(...fetchAttributeConns.data);
    setFirstLoad(true);
    setLoading(false);
    setProductsHasBrands(fetchProductsHasBrands.data);
    setGeneratedBarcode(fetchGeneratedBarcode.data.generatedBarcode);
    setGenders(fetchGenders.data);
    setSelectedCategories(fetchSelectedCategories.data);
    // Select the genders that were selected before
    const oldGenders = fetchSelectedGenders.data.map((e) => e.gender_id);
    setSelectedGenders([...selectedGenders, ...oldGenders]);
    if (fetchProduct.data.tax_class == 'reduced') {
      setTax("9");
      setSelectedTax("9");
    } else if (fetchProduct.data.tax_class == 'high') {
      setTax("21");
      setSelectedTax("21");
    }
    else {
      setTax("0");
      setSelectedTax("0");
    }

    // Category
    const cat = fetchCategories.data.map((map) => {
      return {
        primary_key: map.primary_key,
        id: map.category_id,
        headId: map.head_category_id,
        name: map.title,
      };
    });
    setInitialCategories(cat);
    setInitialCategoriesNew(cat);
    setCategories(fetchCategories.data);
    setAllSizes(fetchAllSizes.data);
    setAllColors(fetchAllColors.data);
    if (fetchProduct.data.type == 2) {
      // fetch selected variations when the product type is 1 and reverse the array so the most recent variations get showed first
      const fetchHasVariations = await middleware.get(
        `products/hasvariations?product_id=${product_id}`
      );
      setSelectedVariations(fetchHasVariations.data.reverse());
    }
  };
  useEffect(() => {
    let variations = [];
    let count = 0;
    if (
      allColors != null &&
      selectedVariations != null &&
      allColors.length != 0 &&
      allSizes != null &&
      allSizes.length != 0 &&
      selectedVariations.length != 0
    ) {
      // loop trough selectedVariations and replace the color and the size object to get the correct data
      selectedVariations.forEach((variation) => {
        // set colors
        if (variation.color != null) {
          const color = allColors.find(
            (color) => color.color_id === variation.color.color_id
          );
          variation.colors = color;
        }
        // set sizes
        if (variation.size != null) {
          const size = allSizes.find(
            (size) => size.size_id === variation.size.size_id
          );
          variation.sizes = size;
        }
        const customVariation = {
          ...variation, // spread the original properties
          id: count, // add or update the id field
          color_name: variation.colors?.name ?? null, // assign the color name from colors object
          size_name: variation.sizes?.name ?? null,
          hex: variation.colors?.hex ?? null, // assign the size name from sizes object
        };
        customVariation.color = customVariation.colors?.color_id ?? null;
        customVariation.size = customVariation.sizes?.size_id ?? null;
        variations.push(customVariation);
        count++;
      });
      setAllVariations(variations);
    }
  }, [allColors, selectedVariations]);

  useEffect(() => {
    if (product.product_id != null) {
      setProductBrand(
        productsHasBrands.filter(
          (productHasBrand) => productHasBrand.product_id == product.product_id
        )[0]
      );
      setSelectedOption("3");
    }
  }, [product]);

  // Set the fetched selected categories as selected
  useEffect(() => {
    if (selectedCategories.length) {
      const prev = { ...checkedCategories };
      initialCategories.forEach((initialCategory) => {
        if (
          selectedCategories.find((e) => e.category_id == initialCategory.id)
        ) {
          prev[
            `1-${initialCategory.primary_key}-${initialCategory.id}-${
              initialCategory.headId ?? 0
            }`
          ] = true;
        }
      });
      setCheckedCategories(prev);
    }
  }, [selectedCategories]);

  // Effect to recalculate prices when tax rate changes
  useEffect(() => {
    // Function defined inside useEffect to avoid dependencies on external functions
    const recalculatePrices = () => {
      setPrices((currentPrices) => {
        const updatedPrices = [];
        for (const [key, value] of Object.entries(currentPrices)) {
          const inclTax = value.incl_tax;
          const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : "";

          updatedPrices[key] = { ...value, excl_tax: exclTax };
        }

        return updatedPrices;
      });
    };

    recalculatePrices();
  }, [tax]);

  const MediaUpload = useRef();

  const proccessCategorieIds = (obj) => {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
    const categoryIds = [];
    const HeadIds = [];

    Object.entries(obj)
      .filter(([key, value]) => value === true && regex.test(key))
      .forEach(([key]) => {
        const match = key.match(regex);
        if (match) {
          categoryIds.push(match[3]); // Voeg het derde deel toe aan match3
          HeadIds.push(match[4]); // Voeg het vierde deel toe aan match4
        }
      });

    return {
      categoryIds,
      HeadIds,
    };
  };

  const transformData = (data) => {
    let headCategoryMap = {};

    data.HeadIds.forEach((headId) => {
      const headCategory = categories.find(
        (cat) => cat.category_id === parseInt(headId)
      );
      if (headCategory) {
        headCategoryMap[headCategory.title] = data.categoryIds
          .map((catId) => {
            const category = categories.find(
              (cat) => cat.category_id === parseInt(catId)
            );
            return category &&
              category.head_category_id === headCategory.category_id
              ? category.title
              : null;
          })
          .filter((title) => title !== null);
      }
    });

    return headCategoryMap;
  };

  const transformedData = transformData(
    proccessCategorieIds(checkedCategories)
  );

  // End category code
  useEffect(() => {
    if (selectedOption == 1) {
      setBarcode(product.product_number);
    } else if (selectedOption == 2) {
      setBarcode(generatedBarcode);
    } else if (selectedOption == 0) {
      setBarcode(adjustedBarcode);
    }
  }, [selectedOption]);

  const categoryIds = proccessCategorieIds(checkedCategories).categoryIds;

  // updatebutton function
  const UpdateAll = async (e) => {
    e.preventDefault();
    // check if a field changed
    if (
      updatedBarcode == false &&
      updatedProduct == false &&
      updatedBrands == false &&
      updatedPrices == false &&
      updatedGender == false &&
      updatedMedia == false &&
      updatedCategories == false &&
      updatedStock == false &&
      updatedVariations == false
    ) {
      // If nothing changed sent error to user
      ToastError("Niets om op te slaan");
    } else {
      if (product.type != 2 && updatedBarcode == false) {
        ToastError("Niets om op te slaan2");
      }
      else {
      // Set prices
      const newprices = prices.reduce((acc, curr) => {
        acc[curr.type] = {
          price_id: curr.price_id,
          incl_tax: curr.incl_tax,
          excl_tax: curr.excl_tax,
        };
        return acc;
      }, {});
      // Validate input fields
      let errorMessage = [];
      if (product.product_name == "" || product.product_name == null)
        errorMessage.push("Het productnaam veld is verplicht!");
      if (product.product_number == "" || product.product_number == null)
        errorMessage.push("Het productnummer veld is verplicht!");
      if (barcode == "" || barcode == null)
        if (product.type != 2) {
        errorMessage.push("Het barcode veld is verplicht!");
        }
      if (prices.length == 0 || prices == null)
        errorMessage.push("Prijs is verplicht!");
      if (errorMessage == "" || errorMessage == null) {
        // Put request to the API
        try {
        await middleware.put(`/products/edit`, {
          product: {
            product_id: product.product_id,
            name: product.product_name,
            number: product.product_number,
            description: product.product_description,
            tax_class: taxClass,
            barcode_id: barcodeId,
            barcode: barcode,
          },
          prices: newprices,
          category_ids: categoryIds,
          brand_id: Number(productBrand?.brand_id) ?? null,
          gender_ids: selectedGenders,
          stock: stock,
          variations: allVariations,
        });
        MediaUpload.current.saveImages(product.product_id);
        ToastSuccess("product is bijgewerkt");
        navigate("/producten");
      }
      catch (error) {
        if (error.response.data.error) {
          // show all barcode duplicate errors
        console.log(error.response.data.error);
        if (error.response.data.error != null) {
          // loop trough all errors
          error.response.data.error.forEach(barcodeError => {
            if (!processedErrors.has(barcodeError.message)) {
              ToastError(barcodeError.message);
              // Prevent to show the same error multiple times
              processedErrors.add(barcodeError.message);
            }
          });
        }
        else {
          ToastError("Er is een fout met het verturen van de data. Controleer de ingevulde gegevens en probeer opnieuw.");
        }
        setErrors(error.response.data.error.map(er => er.index));
      }
      }
      } else {
        if (errorMessage.length > 1) {
          ToastError("Er zijn meerdere velden niet ingevuld!");
        } else {
          ToastError(errorMessage[0]);
        }
      }
      }
    }
  };
  // Gender change
  const handleGendersChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer
    setUpdatedGender(true);
    if (event.target.checked) {
      setSelectedGenders([...selectedGenders, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedGenders(selectedGenders.filter((e) => e !== valueAsInt)); // Verwijder de integer waarde
    }
  };

  // updatebutton function end
  const ShowPrice = (type, turned, fixed) => {
    var res = [];
    if (turned == undefined || turned == false) {
      res = prices
        .filter((filter) => filter.type == type)
        .map((map) => map[tax == 0 ? "incl_tax" : "excl_tax"]);
    } else {
      res = prices
        .filter((filter) => filter.type == type)
        .map((map) => map[tax == 0 ? "excl_tax" : "incl_tax"]);
    }
    return fixed !== undefined || fixed == false
      ? Number(res).toFixed(2)
      : String(Number(res));
  };

  const UpdatePrice = (e, type, turned) => {
    const index = prices.findIndex((price) => price.type == type) + 1;
    if (index) {
      const updated = [...prices];
      updated[index - 1][tax == 0 ? "incl_tax" : "excl_tax"] = Number(
        e.target.value.replace(",", ".")
      );
      setPrices(updated);
    }
  };

  const handlePriceChange = (event) => {
    const priceType = event.target.name;
    const inclTax = event.target.value;
    const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : "";
    setPrices((prevPrices) => {
      // if price was not ever set then make a new object
      if (prevPrices == null || prevPrices.length == 0) {
        prevPrices = [
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "price",
          },
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "sale_price",
          },
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "buy_in_price",
          },
        ];
      }
      setUpdatedPrices(true);
      return prevPrices.map((price) =>
        price.type === priceType
          ? { ...price, incl_tax: Number(inclTax), excl_tax: Number(exclTax) }
          : price
      );
    });
  };

  const handleTaxChange = (event) => {
    setUpdatedPrices(true);
    setTax(event.target.value);
    setSelectedTax(event.target.value);

    if (event.target.value == 9) {
      setTaxClass("reduced");
    } else if (event.target.value == 21) {
      setTaxClass("high");
    } else {
      setTaxClass("zero");
    }
  };

  const ChangeStatus = async (newStatus) => {
    const change = await middleware.put("/products", {
      product_id: product_id,
      status: newStatus,
    });
    setProduct(change.data);
  };

  //functions end
  //useEffects

  useEffect(() => {
    fetchAll();
  }, []);
  //useEffects end

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <PageTemplate navbarTitle={"Warehouse HUB"}>
      {/* back to products btn */}
      <div className="d-flex flex-column gap-5 w-100">
        <div
          className="d-flex flex-row align-items-center hover"
          style={{ width: "10%" }} // Make it 10% so that only the button and the text are clickable and not the whole page
          onClick={() => navigate("/producten")}
        >
          <img style={{ width: "25px" }} className="me-3" src={Backbutton} />
          <p className="mb-0 fw-semibold">Alle producten</p>
        </div>

        <form onSubmit={UpdateAll}>
          <div className="d-flex justify-content-between me-5">
            <div className="d-flex gap-2">
              <h1 className="fw-bolder">{oldProduct.product_name} bewerken</h1>
              {product.status == 0 ? (
                <div
                  className="btn btn-secondary m-2 fw-bolder"
                  onClick={(e) => ChangeStatus(1)}
                >
                  Deactiveer
                </div>
              ) : (
                <div
                  className="btn btn-success m-2 fw-bolder"
                  onClick={(e) => ChangeStatus(0)}
                >
                  Activeer
                </div>
              )}
            </div>
            <div className=" saveButtonsBox fitContent">
              <button
                type="submit"
                id="2"
                className="btn lightBlueStandardButton Nunito m-2"
              >
                Opslaan
              </button>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="gap-5" style={{ width: "75%" }}>
              <FirstBox
                product={product}
                setProduct={setProduct}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                FontAwesomeIcon={FontAwesomeIcon}
                faPlus={faPlus}
                setShowBrandModal={setShowBrandModal}
                brands={brands}
                barcode={barcode}
                setBarcode={setBarcode}
                setUpdatedProduct={setUpdatedProduct}
                setUpdatedBarcode={setUpdatedBarcode}
                setUpdatedBrands={setUpdatedBrands}
                brand={productBrand}
                setProductBrand={setProductBrand}
                generatedBarcode={generatedBarcode}
                adjustedBarcode={adjustedBarcode}
                setAdjustedBarcode={setAdjustedBarcode}
                oldBarcode={oldBarcode}
              />
              {/* only products with type 2 can edit/add variations */}
              {product.type == 2 ? (
                <div className="mt-5">
                <BoxVariation
                  allVariations={allVariations}
                  setAllVariations={setAllVariations}
                  allSizes={allSizes}
                  allColors={allColors}
                  setUpdatedVariations={setUpdatedVariations}
                  errors={errors}
                />
                </div>
              ) : (
                <></>
              )}
              <div className="whiteBox p-4 my-5">
                <MediaUploaderComponent edit={true} ref={MediaUpload} availableVariations={allVariations.map((e) => {
                return {
                  color_id: e.color,
                  color_name: e.color_name,
                  size_id: e.size,
                  size_name: e.size_name
                }
              })} useColors={true}>

              </MediaUploaderComponent>
              </div> 
            </div>
            <div>
              <div className="whiteBox boxCategories mb-4 mx-5">
                <div className="d-flex flex-column">
                  <div className=" widthBoxes w-100 mb-2 p-3 categories">
                    {/* categories component */}
                    <Categories
                      initialCategories={initialCategories}
                      checkedCategories={checkedCategories}
                      setCheckedCategories={setCheckedCategories}
                      setUpdatedCategories={setUpdatedCategories}
                    />
                  </div>
                </div>
              </div>
              <div className="whiteBox mb-4 p-5 pb-3 pt-4 mx-5">
                <h5 className="inputTitle mb-4">Geslacht</h5>
                <div>
                  {genders.map((gender, index) => (
                    <div key={index} className="d-flex flex-row mb-3">
                      <label className="checkbox-containerSimple">
                        <input
                          type="checkbox"
                          value={gender.gender_id}
                          checked={selectedGenders.includes(gender.gender_id)}
                          onChange={handleGendersChange}
                        />
                        <span className="checkmarkSimple"></span>
                      </label>
                      <h5 className="inputTitle">{gender.name}</h5>
                    </div>
                  ))}
                </div>
              </div>
              {/* prices component */}
              <SecondBox
                Tax={tax}
                TaxClass={taxClass}
                ShowPrice={ShowPrice}
                UpdatePrice={UpdatePrice}
                HandlePriceChange={handlePriceChange}
                HandleTaxChange={handleTaxChange}
                prices={prices}
                selectedTax={selectedTax}
              />
              <ThirdBox
                stock={stock}
                setStock={setStock}
                setUpdatedStock={setUpdatedStock}
              />
              <FourthBox
                suppliers={suppliers}
                setSuppliers={setSuppliers}
                businesses={businesses}
                setBusinesses={setBusinesses}
                attributeConnections={attributeConnections}
                setAttributeConnections={setAttributeConnections}
              />
            </div>
          </div>
        </form>
      </div>
    </PageTemplate>
  );
};

export default EditPage;
