import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

const SortableHeader = ({ currentItem, setItem, field, children, pos }) => {
    const position = pos ?? 'center';
    const generateOrderDirection = () => {
      if (currentItem.direction === 'asc') {
        return currentItem.orderBy === field ? 'desc' : 'asc';
      }
      if (currentItem.direction === 'desc') {
        return currentItem.orderBy === field ? '' : 'asc';
      }
      return 'asc';
    }
  
    const renderSortIcon = () => {
      if (currentItem.orderBy !== field) return <FontAwesomeIcon className="text-black ms-2 sortingIcon" icon={faSort} />;
      return currentItem.direction === 'asc' ? <FontAwesomeIcon className="text-black ms-2 sortingIcon" icon={faSortUp} /> : currentItem.direction === 'desc' ? <FontAwesomeIcon className="text-black ms-2 sortingIcon" icon={faSortDown} /> : <FontAwesomeIcon className="text-black ms-2 sortingIcon" icon={faSort} />;
    }
  
    return (
      <div 
        onClick={() => setItem({ ...currentItem, orderBy: field, direction: generateOrderDirection() })}
        className={"header-filter tablePresetHeader text-" + position}
      >
        {children} {renderSortIcon()}
      </div>
    );
  };

  export default SortableHeader;