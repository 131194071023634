import React from "react";
import "./components/Main/Darkmode.css"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./components/MainPage/MainPage";
import AddSimple from "./components/Products/subpages/add/AddSimple";
import AddVariation from "./components/Products/subpages/add/AddVariation";
import EditPage from "./components/Products/subpages/edit/EditPage";
import OldEditPage from "./components/Products/subpages/edit/OldEditPage";
import Orders from "./components/Orders/Orders";
import OrdersEdit from "./components/Orders/OrdersEdit";
import OrdersNew from "./components/Orders/OrdersNew";
import Login from "./components/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import ActiveProducts from "./components/Products/Products";
import WarehouseSettings from "./components/WarehouseSettings/WarehouseSettings";
import ColorSettings from "./components/ColorSettings/ColorSettings";
import Blueprints from "./components/Blueprints/Blueprints";
import EditBlueprints from "./components/Blueprints/EditBlueprints/EditBlueprints";
import Brands from "./components/Brands/Brands";
import BrandsEdit from "./components/Brands/BrandsEdit/BrandsEdit";
import Category from "./components/Categories/Category";
import CategoryNew from "./components/CategoryNew/Category";
import GeneralSettings from "./components/GeneralSettings/GeneralSettings";
import Users from "./components/GeneralSettings/Users/Users";
import EditUsers from "./components/GeneralSettings/Users/EditUsers/EditUsers";
import { ToastContainer } from 'react-toastify';
import CategoryEdit from "./components/Categories/CategoryEdit/CategoryEdit";
import AddUsers from "./components/GeneralSettings/Users/AddUsers/AddUsers";
import ProccessOrders from "./components/Orders/ProccessOrders";
import Customer from "./components/Customer/Customer";
import EditCustomers from "./components/Customer/EditCustomers/EditCustomers";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import PaymentProviders from "./components/Payments/PaymentProviders";
import Provider from "./components/Payments/Provider";
import ProviderDetails from "./components/Payments/ProviderDetails";
import ProviderOnboarding from "./components/Payments/ProviderOnboarding";
import RouteChangeListener from "./components/RouteChangeListener/RouteChangeListener";
import ForgotPassword from "./components/Login/pages/ForgotPassword";
import ResetPassword from "./components/Login/pages/ResetPassword";
import CatEdit from "./components/CategoryNew/CategoryEditen/CatEdit";
import CatAdd from "./components/CategoryNew/CategoryAdd/CatAdd";
import Labels from "./components/Labels/Labels";

function App() {
  return (
    <BrowserRouter>
    <ToastContainer/>
    <RouteChangeListener />
    <UnauthorizedEventListener />
      <Routes>
        <Route path="/" element={<MainPage />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/wachtwoord-vergeten" element={<ForgotPassword />}></Route>
        <Route path="/wachtwoord-resetten" element={<ResetPassword />}></Route>
        <Route path="/*" element={<NotFound />}></Route>

        <Route path="/klanten" element={<Customer />}></Route>
        <Route path="/klanten/bewerken/:internal_id" element={<EditCustomers />}></Route>


        <Route path="/producten" element={<ActiveProducts />}></Route>
        <Route path="/producten/alles" element={<ActiveProducts />}></Route>
        <Route path="/producten/nieuw" element={<AddSimple />}></Route>
        <Route path="/producten/nieuw/variatie" element={<AddVariation />}></Route>
        <Route path="/producten/bewerken/:product_id" element={<EditPage />}></Route>
        <Route path="/producten/bewerken" element={<OldEditPage />}></Route>

        <Route path="/algemene/instellingen" element={<GeneralSettings />}></Route>
        <Route path="/algemene/instellingen/gebruikers" element={<Users />}></Route>
        <Route path="/algemene/instellingen/gebruikers/aanmaken" element={<AddUsers />}></Route>
        <Route path="/algemene/instellingen/gebruikers/bewerken/:user_id" element={<EditUsers />}></Route>

        <Route path="/warehouse/instellingen" element={<WarehouseSettings />}></Route>
        <Route path="/warehouse/instellingen/kleuren" element={<ColorSettings />}></Route>
        <Route path="/warehouse/instellingen/blauwdrukken" element={<Blueprints />}></Route>
        <Route path="/warehouse/instellingen/blauwdrukken/bewerken" element={<EditBlueprints />}></Route>
        <Route path="/warehouse/instellingen/merken" element={<Brands />}></Route>
        <Route path="/warehouse/instellingen/merken/bewerken/:brand_id" element={<BrandsEdit />}></Route>
        <Route path="/warehouse/instellingen/categoriën" element={<Category />}></Route>
        <Route path="/warehouse/instellingen/categoriën/bewerken/:primary_key" element={<CategoryEdit />}></Route>
        <Route path="/warehouse/instellingen/category_nieuw" element={<CategoryNew />}></Route>
        <Route path="/warehouse/instellingen/category_nieuw/category_Bewerken" element={<CatEdit />}></Route>
        <Route path="/warehouse/instellingen/category_nieuw/category_Toevoegen" element={<CatAdd />}></Route>
        <Route path="/warehouse/instellingen/labels" element={<Labels />}></Route>

        <Route path="/bestellingen" element={<Orders />}></Route>
        <Route path="/bestellingen/nieuw" element={<OrdersNew />}></Route>
        <Route path="/bestellingen/bewerken/:order_number" element={<OrdersEdit />}></Route>
        <Route path="/bestellingen/verwerken/:order_number" element={<ProccessOrders />}></Route>

        <Route path="/betalingproviders" element={<PaymentProviders />}></Route>
        <Route path="/betalingproviders/details" element={<ProviderDetails />}></Route>
        <Route path="/betalingproviders/onboarding" element={<ProviderOnboarding />}></Route>
        <Route path="/provider" element={<Provider />}></Route>
        
      </Routes>
    </BrowserRouter>
  );
}

const UnauthorizedEventListener = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    const handleUnauthorized = () => {
      cookies.remove('token', { path: '/' });
      navigate('/login');
    };

    window.addEventListener('unauthorized-event', handleUnauthorized);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('unauthorized-event', handleUnauthorized);
    };
  }, [navigate]);

  return null; // This component does not render anything
};

export default App;