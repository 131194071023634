import React, { useEffect, useState } from 'react';
import Sidebar from '../Navbars/Sidebar/Sidebar';
import Navbar from '../Navbars/Navbar/Navbar';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import './Template.css';

const PageTemplate = ({ navbarTitle, pageIsLoading, children, iconNavbar }) => {
  const [sidebarIsLoading, setSidebarIsLoading] = useState(true);
  const [navbarIsLoading, setNavbarIsLoading] = useState(true);
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [sidebarSmall, setSidebarSmall] = useState(false);
  const [darkmode, setDarkmode] = useState(false);

  useEffect(() => {

    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sidebarIsLoadingChange = event => {
    console.log(event);
    
    setSidebarIsLoading(event);
  }

  const navbarIsLoadingChange = event => {
    setNavbarIsLoading(event);
  }

  return (
    <div className={`d-flex flex-nowrap ${darkmode ? "darkmode" : ""}`}>
      {pageIsLoading || sidebarIsLoading || navbarIsLoading ? (
        <LoadingScreen/>
      ) : ('')}
      <Sidebar shortcuts={[]} callbackFuncIsLoading={sidebarIsLoadingChange} collapsed={(e) => setSidebarSmall(e)}  darkmodeSysteem={(e) => setDarkmode(e)}/>
      <div className="d-flex flex-column ">
        <Navbar title={navbarTitle} callbackFuncIsLoading={navbarIsLoadingChange} icon={iconNavbar} sidebarsmall={sidebarSmall} />
        <div className={` d-flex justify-content-center ${sidebarSmall == true ? "mainBoxSmallSidebar mainBox" : "mainBox"} ${windowSize[0] < 1458 ? "mainBoxSmallSidebar" : ""}`}>
          <div className="mb-5 position-absolute pb-4 widthContentMainbox">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageTemplate;