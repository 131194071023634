import React, { useMemo, useState } from 'react';
import TablePreset from '../Table/TablePreset';
import { useNavigate } from 'react-router';
import { useTable } from 'react-table';
import Pen from "../CategoryNew/ComponentsCategory/IconsAccordion/Pen.svg";
import Xmark from "../CategoryNew/ComponentsCategory/IconsAccordion/xmark.svg";
import PageTemplate from '../Templates/PageTemplate';
import NewLabelModal from './Modals/NewLabelModal';
import EditLabelModal from './Modals/EditLabelModal';

const Labels = () => {
  const navigate = useNavigate();
const [loading, setLoading] = useState(false); // useState for page loading
  const [showNewLabel, setShowNewLabel] = useState(false);
  const [showEditLabel, setShowEditLabel] = useState(false);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  }); // the useState for the pagination for the table
  const [order, setOrder] = useState({orderBy: '', direction: ''}); // useState for ordering the columns in the table
  const [selectedIds, setSelectedIds] = useState([]);
  const [fullyShownProducts, setFullyShownProducts] = useState([]); // usestate fpr the productname that u can fully show and shortend it

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const testData = [
    {labelName: "Uitgelicht"},
    {labelName: "Sale"},
    {labelName: "Nieuw"},
  ]

  const handleEditClick = (e) => {
    e.stopPropagation(); // Stop event propagation
    setShowEditLabel(true);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Stop event propagation
  };

  const columns = useMemo(
    () => {
      let columnsArray = [
        {
          Header: () => (
            <div>
              <h2 className='fw-semibold mb-0'>Labels</h2>
            </div>
          ),
          accessor: 'labelName',
          Cell: ({ row }) => {
              return (
                <div><h5 className='fw-semibold py-2 mb-0'>{row.original.labelName}</h5></div>
              );
          },
        },

        {
          Header: () => (
            <div className='d-flex justify-content-end'>
              <button className='lightBlueStandardButton px-5 py-2' onClick={() => setShowNewLabel(true)}>Nieuw toevoegen</button>
            </div>
            ),
          accessor: "id",
          Cell: ({ row }) => {
            return (
              <div className='d-flex flex-row justify-content-end'>
                <button className='lightBlueStandardButton buttonsAccordion me-2 d-flex align-self-center fitContent' onClick={handleEditClick}>
                  <img src={Pen} style={{ width: "15px" }} alt="Edit" />
                </button>
                <button className='darkBlueStandardButton buttonsAccordion d-flex align-self-center fitContent' onClick={handleDeleteClick}>
                  <img src={Xmark} style={{ width: "15px", height: "15px" }} alt="Delete" />
                </button>
              </div>
            );
          }
        },
      ];
        return columnsArray; 
    },
    [order.orderBy, order.direction, selectedIds, setSelectedIds,]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: testData || [] });
  return (
    <PageTemplate navbarTitle={"Warehouse HUB"}>
      <NewLabelModal openNewLabel={showNewLabel} closeNewLabel={() => setShowNewLabel(false)}/>
      <EditLabelModal openEditLabel={showEditLabel} closeEditLabel={() => setShowEditLabel(false)}/>
      <div className='w-100 labelTable'>
          <TablePreset
            data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination}]}
            tableLoading={loading}
            setPagination={(e) => setPagination(e)} 
            handleSelection={handleSelection} 
            primary_key={'label_id'}
          />
      </div>

    </PageTemplate>
  );
}

export default Labels;
