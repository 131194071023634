import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import middleware from '../../../Api/Middleware';
import salesChannelsApi from '../../../Api/SalesChannels';
import { Modal } from 'react-bootstrap';
import VariationModal from '../sub_components/VariationModals'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from '@fortawesome/free-solid-svg-icons'
import '../css/AddProductPages.css'
import '../../../Main/Main.css'
import "../../product.css"
import ToastError from '../../../Toasts/ToastError';
import ToastSuccess from '../../../Toasts/ToastSuccess';
import NavbarIcon from "../../../Navbars/Sidebar/Icons/globe.png"
import Backbutton from "../images/BackIcon.svg"
// Media
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import CategoryList from '../sub_components/CategoryList';
import PageTemplate from '../../../Templates/PageTemplate';
import CategoryModal from '../../../Modals/CategoryModal';

import TextEditor from '../../../Main/TextEditor';
import MediaUploaderComponent from '../../../MediaUpload/MediaUploaderComponent';
import Categories from '../sub_components/Categories';

const AddSimple = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [salesChannels, setSalesChannels] = useState([]);
  const [genders, setGenders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonPressed, setbuttonPressed] = useState('');
  const [brandId, setBrandId] = useState(null);
  const [description, setDescription] = useState('');
  const [selectedOption, setSelectedOption] = useState('0');
  const previousSelectedOption = useRef(selectedOption);
  const [barcode, setBarcode] = useState('');
  const [tax, setTax] = useState('0');
  const [taxClass, setTaxClass] = useState('zero');
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedSalesChannels, setSelectedSalesChannels] = useState([]);
  const [values, setValues] = useState({
    product_name: '',
    product_number: '',
    barcode: '',
  });
  // State for holding categories.
  const [initialCategories, setInitialCategories] = useState([
    { id: 1, headId: null, name: 'Categories' }
  ]);

  const [checkedCategories, setCheckedCategories] = useState({});
  const [prices, setPrices] = useState({
    price: { incl_tax: '', excl_tax: '' },
    sale_price: { incl_tax: '', excl_tax: '' },
    buy_in_price: { incl_tax: '', excl_tax: '' },
  });

  const fetchAll = async () => {
    setIsLoading(true);
    const [fetchBrands, fetchCategories, fetchGenders, fetchSalesChannels] = await Promise.all([
      middleware.get(`products/brands`),
      middleware.get(`products/category`),
      middleware.get(`products/genders`),
      salesChannelsApi.get('saleschannels?status=0'),
    ]);
    const cat = fetchCategories.data.map(map => {
      return {
        primary_key: map.primary_key,
        id: map.category_id,
        headId: map.head_category_id,
        name: map.title,
      };
    });
    setInitialCategories(cat);
    setBrands(fetchBrands.data);
    setCategories(fetchCategories.data)
    setGenders(fetchGenders.data);
    setSalesChannels(fetchSalesChannels.data);
    setIsLoading(false);
  };
  const MediaUploader = useRef();
  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    (async () => {
      let newBarcodeValue = '';

      // Check if the selectedOption has changed
      if (previousSelectedOption.current !== selectedOption && selectedOption === '0') {
        setValues(prevValues => ({
          ...prevValues,
          barcode: ''
        }));
      }

      if (selectedOption === '0') {
        newBarcodeValue = values.barcode;
      } else if (selectedOption === '2') {
        newBarcodeValue = await handleGenerateBarcode();
      } else if (selectedOption === '1') {
        newBarcodeValue = values.product_number;
      }

      setBarcode(newBarcodeValue);

      // Update the previous selected option to the current one
      previousSelectedOption.current = selectedOption;
    })();
  }, [selectedOption, values.barcode]);

  useEffect(() => {
    if (selectedOption === '1') {
      setBarcode(values.product_number);
    }
  }, [values.product_number])

  // Effect to recalculate prices when tax rate changes
  useEffect(() => {
    // Function defined inside useEffect to avoid dependencies on external functions
    const recalculatePrices = () => {
      setPrices((currentPrices) => {
        const updatedPrices = {};

        for (const [key, value] of Object.entries(currentPrices)) {
          const inclTax = value.incl_tax;
          const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : '';

          updatedPrices[key] = { ...value, excl_tax: exclTax };
        }

        return updatedPrices;
      });
    };

    recalculatePrices();
  }, [tax]);

  const handlePriceChange = (event) => {
    const priceType = event.target.name;
    const inclTax = event.target.value;
    const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : '';

    setPrices(prevPrices => ({
      ...prevPrices,
      [priceType]: { incl_tax: inclTax, excl_tax: exclTax },
    }));
  }

  const proccessCategorieIds = (obj) => {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
    const categoryIds = [];
    const HeadIds = [];

    Object.entries(obj)
      .filter(([key, value]) => value === true && regex.test(key))
      .forEach(([key]) => {
        const match = key.match(regex);
        if (match) {
          categoryIds.push(match[3]); // Voeg het derde deel toe aan match3
          HeadIds.push(match[4]); // Voeg het vierde deel toe aan match4
        }
      });

    return {
      categoryIds,
      HeadIds,
    };
  }

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const submitNew = async (event) => {
    event.preventDefault(); // Voorkom de standaardactie van het formulier
    setIsLoading(true);

    var newBarcode = null;
    if (barcode.trim() === '') {
      newBarcode = await handleGenerateBarcode();
      setValues({ ...values, barcode: newBarcode });
    } else {
      newBarcode = barcode;
    }

    const categoryIds = proccessCategorieIds(checkedCategories).categoryIds;

    try {
      await middleware.post(`products/create`, {
        product: {
          name: values.product_name,
          number: Number(values.product_number),
          barcode: Number(newBarcode),
          tax_class: taxClass,
          description: description,
        },
        prices: prices,
        category_ids: categoryIds,
        gender_ids: selectedGenders,
        sales_channel_ids: selectedSalesChannels,
        brand_id: brandId,
      }).then((e) => {
        let product_id = e.data.product.product_id;
        MediaUploader.current.saveImages(product_id);
      });
      ToastSuccess("Product is aangemaakt");
      if(buttonPressed == 1) {
        window.location.reload();
      } else if(buttonPressed == 2) {
        navigate("/producten");
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.details) {
        const details = err.response.data.details;

        if (details['product.number']) {
          ToastError(`Productnummer bestaat al`);
          return;
        }
        if (details['product.barcode']) {
          ToastError('Barcode bestaat al');
          return;
        }
      } else {
        console.error(err);
      }
      ToastError("Er is een fout opgetreden bij het aanmaken van het product.");
    }
  }

  const handleGenerateBarcode = async () => {
    // Generate a 13-digit barcode
    try {
      const res = await middleware.get('products/generateBarcode');
      const barcode = res.data.generatedBarcode;
      return barcode;
    } catch (error) {
      console.log(error);
    }
  }

  const handleGendersChange = event => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer

    if (event.target.checked) {
      setSelectedGenders([...selectedGenders, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedGenders(selectedGenders.filter(e => e !== valueAsInt)); // Verwijder de integer waarde
    }
  }

  const handleSalesChannelsChange = event => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer

    if (event.target.checked) {
      setSelectedSalesChannels([...selectedSalesChannels, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedSalesChannels(selectedSalesChannels.filter(e => e !== valueAsInt)); // Verwijder de integer waarde
    }
  }

  const handleTaxChange = (event) => {
    setTax(event.target.value)

    if (event.target.value == 9) {
      setTaxClass("reduced")
    }
    else if (event.target.value == 21) {
      setTaxClass("high")
    }
    else {
      setTaxClass("zero")
    }
  }

  return (
    <PageTemplate navbarTitle="Warehouse HUB" pageIsLoading={isLoading} iconNavbar={NavbarIcon}>
      <form onSubmit={submitNew} method="post" className='d-flex justify-content-center minFitContent w-100'>
        <div className="mb-3 d-flex flex-column formItems  minFitContent w-100">
          <div className="d-flex justify-content-between mb-4 w-100 flex-wrap-reverse">
            <div className='d-flex flex-row align-items-center hover' onClick={() => navigate("/producten")}>
              <img style={{ width: "25px" }} className='me-3' src={Backbutton} />
              <p className='mb-0 fw-semibold'>
                Alle producten
              </p>
            </div>
            <div className='fitContent text-nowrap'>
              <button
                type="submit"
                id='1'
                className="btn darkBlueStandardButton me-3 Nunito"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Opslaan & nieuw aanmaken
              </button>
              <button
                type="submit"
                id='2'
                className="btn lightBlueStandardButton Nunito"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Opslaan
              </button>
            </div>
          </div>
          <div className='d-flex flex-row justify-content-between'>
            <div style={{ width: "75%" }}>
              <div>
                <div className='d-flex flex-column fitcontentHeight' >
                  <div className="d-flex flex-column whiteBox p-4 mb-4 minFitContent">
                    <div className="d-flex flex-row parentBoxSellChannelProduct">
                      <div className='w-100'>
                        <div className="d-flex flex-column mb-3">
                          <label className="inputTitle mt-3 mb-2">
                            Productnaam
                          </label>
                          <input
                            className="inputField col-12 standardInput"
                            type="text"
                            name="product_name"
                            value={values.product_name ?? ''}
                            onChange={handleChange}
                            required
                          ></input>
                        </div>
                        <div className="d-flex flex-row productNumSelectLists col-12 justify-content-between">
                          <div className="d-flex flex-column" style={{ width: "32%" }}>
                            <div className='d-flex flex-nowrap'>
                              <label className="inputTitle me-2">
                                Productnummer
                              </label>
                            </div>
                            <input
                              type="number"
                              className="inputField px-2 standardInput webkitRemoves"
                              name="product_number"
                              value={values.product_number}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="d-flex flex-column" style={{ width: "32%" }}>
                            <div className="d-flex flex-row justify-content-between mb-2">
                              <label className="inputTitle me-2 mb-0">Barcode</label>
                              <select
                                className="optionsList productOptions"
                                value={selectedOption}
                                onChange={e => setSelectedOption(e.target.value)}
                              >
                                <option value="0">Aangepaste barcode</option>
                                <option value="1">Gelijk aan productnummer</option>
                                <option value="2">Automatisch gegenereerd</option>
                              </select>
                            </div>
                            <div className="d-flex flex-column">
                              {selectedOption === "0" && (
                                <input
                                  type="number"
                                  name="barcode"
                                  className="mb-4 standardInput px-2 webkitRemove"
                                  value={values.barcode}
                                  onChange={handleChange}
                                />
                              )}
                              {selectedOption === "1" && (
                                <input
                                  type="number"
                                  className="mb-4 standardInput px-2 webkitRemove"
                                  value={values.product_number}
                                  disabled
                                />
                              )}
                              {selectedOption === "2" && (
                                <input
                                  type="number"
                                  className="mb-4 standardInput px-2 webkitRemove"
                                  value={barcode}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-column" style={{ width: "32%" }}>
                            <label className="inputTitle">
                              Merk
                            </label>
                            <select
                              className="standardInput mb-2 px-2 standardDropdownArrow"
                              onChange={(e) => setBrandId(e.target.value)}
                            >
                              <option hidden></option>
                              {brands.map((brands, index) => {
                                return <option value={brands.brand_id} key={index}>{brands.name}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-2'>
                      <TextEditor onChange={(e) => setDescription(e)} />
                    </div>
                  </div>
                </div>
              </div>
              <h5>Afbeeldingen uploaden</h5>
              <MediaUploaderComponent ref={MediaUploader}>

              </MediaUploaderComponent>
            </div>

            <div className='col-3' style={{ width: "23%" }}>
              {categories.length &&
              <Categories
                initialCategories={initialCategories}
                setCheckedCategories={setCheckedCategories}
                checkedCategories={checkedCategories}
              />}
              <div className="whiteBox mb-4 col-12 p-5 pb-3 pt-4 me-3">
                <h5 className="inputTitle mb-4">Geslacht</h5>
                <div>
                  {genders.map((gender, index) => (
                    <div key={index} className="d-flex flex-row mb-3">
                      <label className="checkbox-containerSimple">
                        <input
                          type="checkbox"
                          value={gender.gender_id}
                          onChange={handleGendersChange}
                        />
                        <span className="checkmarkSimple"></span>
                      </label>
                      <h5 className="inputTitle">{gender.name}</h5>
                    </div>
                  ))}
                </div>
              </div>

              <div className="whiteBox p-4 mb-4">
                <div className="d-flex flex-column ">
                  <div className='d-flex justify-content-between'>
                    <h5 className="inputTitle mb-4 ">Prijzen</h5>
                    <div className='BTWSetting'>
                      <select
                        className="inputField standardInput standardDropdownArrow me-3 fw-medium hover"
                        style={{ height: "30px", width: "137px", fontSize: "13px" }}
                        onChange={handleTaxChange}
                        defaultValue=""
                      >
                        <option value="" disabled hidden>
                          Prijzen incl. BTW
                        </option>
                        <option
                          value="21"
                          {...(tax === "21" ? { hidden: true } : { hidden: false })}
                        >
                          21%
                        </option>
                        <option
                          value="9"
                          {...(tax === "9" ? { hidden: true } : { hidden: false })}
                        >
                          9%
                        </option>
                      </select>
                      <button className=' rounded-pill px-2 text-secondary gearButtonPrices' type='button'><FontAwesomeIcon icon={faGear} /></button>
                    </div>
                  </div>
                  <div className='px-1'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex flex-row justify-content-between standardSaleParent'>
                        <div className="d-flex flex-column me-4">
                          <label className="inputTitle text-small">Standaard prijs</label>
                          <input
                            type="number"
                            step="any"
                            name='price'
                            className="standardInput w-100 ps-2 placeholderStyle"
                            placeholder="0.00"
                            value={prices.price.incl_tax}
                            onChange={handlePriceChange}
                          />
                          <p className="bottomText">€ {prices.price.excl_tax ? prices.price.excl_tax : 0.00} Excl. BTW</p>
                        </div>
                        <div className="d-flex flex-column me-2">
                          <label className="inputTitle text-small">Sale prijs</label>
                          <input
                            type="number"
                            step="any"
                            name='sale_price'
                            className="standardInput w-100 ps-2 placeholderStyle"
                            placeholder="0.00"
                            value={prices.sale_price.incl_tax}
                            onChange={handlePriceChange}
                          />
                          <p className="bottomText">€ {prices.sale_price.excl_tax ? prices.sale_price.excl_tax : 0.00} Excl. BTW</p>
                        </div>
                      </div>
                      <div className='d-flex flex-row justify-content-between standardSaleParent'>
                        <div className="d-flex flex-column me-4">
                          <label className="inputTitle text-small">Inkoopprijs excl. BTW</label>
                          <input
                            type="number"
                            step="any"
                            name='buy_in_price'
                            className="standardInput w-100 ps-2 placeholderStyle"
                            placeholder="0.00"
                            value={prices.buy_in_price.incl_tax}
                            onChange={handlePriceChange}
                          />
                        </div>
                        <div className="d-flex flex-column heightSalePrice">
                          <label className="inputTitle text-small text-nowrap">
                            Marge excl. BTW
                          </label>
                          <input
                            type="number"
                            className="inputField w-100 ps-2 mb-1 buyPrice placeholderStyle"
                            placeholder="€ -"
                            disabled
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='whiteBox p-4 mb-4'>
                <h5 className='mb-3 fw-semibold'>Productlabel</h5>
                <ul>
                  <p className='mb-1 fw-medium'>Nieuw</p>
                  <p className='mb-1 fw-medium'>Sale</p>
                  <p className='mb-1 fw-medium'>Uitgelicht</p>
                </ul>
              </div> */}

              <div className="whiteBox p-4 boxSellChannel mb-4 me-3">
                <div className="d-flex flex-column h-100">
                  <h5 className="inputTitle mb-4">Verkoopkanalen</h5>
                  <div className='px-2 d-flex h-100 flex-column justify-content-around'>
                    {salesChannels.length > 0 ? (
                      salesChannels.map((salesChannel, index) => (
                        <div key={index} className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between">
                          <h6 className="switchText">{salesChannel.sales_channel_name}</h6>
                          <label className="defaultSwitch ms-4">
                            <input type="checkbox" value={salesChannel.sales_channel_id} onChange={handleSalesChannelsChange} />
                            <span className="defaultSlider round"></span>
                          </label>
                        </div>
                      ))
                    ) : (
                      <p>Er zijn nog geen verkoopkanalen gemaakt.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </PageTemplate>
  );
}


export default AddSimple