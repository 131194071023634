import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import Modal from "react-bootstrap/Modal";
import middleware from "../Api/Middleware";
import { useNavigate, useLocation } from "react-router";
import "../Main/Main.css";
import "./Blueprints.css";
import NavbarIcon from "../WarehouseSettings/Icons/size.png"
import Loading from "../LoadingScreen/LoadingScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPen, faXmark,} from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import SortableHeader from '../Table/TableComponents/SortableHeader';
import CheckboxHeader from "../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../Table/TableComponents/CheckboxCell";
import PageTemplate from "../Templates/PageTemplate";
import TablePreset from "../Table/TablePreset";

function Blueprints() {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
const [loading, setLoading] = useState(true); // useState for page loading  
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(true); // useState for the Loadingscreen
  const [blueprints, setBlueprints] = useState([]); // useState for all products in the database
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table
  const [order, setOrder] = useState({ orderBy: "", direction: "" }); // useState for ordering the columns in the table
  const [selectedProductIds, setSelectedProductIds] = useState([]); // usestate for the selected checkboxes
  const [timerId, setTimerId] = useState(null);
  const [blueprintsSearchengine, setBlueprintsSearchengine] = useState("");
  const [inputChangeInternalCode, setInputChangeInternalCode] = useState("");
  const [searchBarInput, setSearchBarInput] = useState("");
  // const [blueprintData, setBlueprintData] = useState([]);
  const [show, setShow] = useState(false);
  const [blueprintName, setBlueprintName] = useState("");
  const [showModalBlueprint, setShowModalBlueprint] = useState(false);
  const [showModalBlueprintNew, setShowModalBlueprintNew] = useState(false);

  // fetched all the products and the stock an the colors from the database
  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchBlueprints] = await Promise.all([
        middleware.get(`sp/blueprintsTableAll?page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== "" && order.direction !== "" ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : "" }`),
      ]);
      setBlueprints(fetchBlueprints.data.data);
      setPagination({
        ...pagination,
        max_items: fetchBlueprints.data.total,
        begin_of_page: fetchBlueprints.data.from,
        end_of_page: fetchBlueprints.data.to,
      }); // sets the pagination usestate with the max items begin of the page and the end of the page
      setLoadingScreenFunc();
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  // fetches all the data when pagination.page_size, pagination.page, order or location.pathname changes
  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page, order, location.pathname]);

  useEffect(() => {
    fetchAll();
  }, []);


  useEffect(() => {
    try {
      if (blueprintsSearchengine.trim() === "" && blueprintsSearchengine === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (blueprintsSearchengine.trim() !== "") {
        // Code to perform the search when searchengine has actual characters
        const doIt = async () => {
          const [fetchBlueprints] = await Promise.all([
            middleware.get(
              `products/sizes/blueprints?page=${pagination.page}&page_size=${pagination.page_size}&searchTerm=${blueprintsSearchengine}&noSearch=[updated_at,created_at]`
            ),
          ]);
          setBlueprints(fetchBlueprints.data.data);
          setPagination({
            ...pagination,
            max_items: fetchBlueprints.data.total,
            begin_of_page: fetchBlueprints.data.from,
            end_of_page: fetchBlueprints.data.to,
          }); // sets the pagination usestate with the max items begin of the page and the end of the page
        };
        doIt();
      } 
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
}, [blueprintsSearchengine]);

  const EditIcon = <FontAwesomeIcon icon={faPen} />;

  // handles to close the de-activate modal
  const handleClose = () => setShow(false);
  const handleCloseBlueprint = () => setShowModalBlueprint(false);
  const handleCloseBlueprintNew = () => setShowModalBlueprintNew(false);

  //handles to show the de-activate modal
  const handleShow = () => setShow(true);
  const handleShowBlueprint = () => setShowModalBlueprint(true);

  // Define ref for your modals
  const handleBlueprintModalNew = () => {
    setShowModalBlueprint(false);
    setShowModalBlueprintNew(true);
  };

  // sets loading on false an waits 0.5 sec to also set the Loadingscreen to false
  function setLoadingScreenFunc() {
    setLoading(false);
    setTimeout(() => {
      setLoadingScreen(false);
    }, 500);
  }

  const handleBlueprintName = () => {
    localStorage.setItem("BlueprintName", blueprintName);
  };

  const handleBlueprintData = (data) => {
    localStorage.setItem("Blueprintdata", JSON.stringify(data));
    navigate("./bewerken");
  };

  // Handle checkbox selection
  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedProductIds([...selectedProductIds, productId]);
    } else {
      setSelectedProductIds(
        selectedProductIds.filter((id) => id !== productId)
      );
    }
  };

  // a function that delays
  function delayedFunction(Func) {
    setTimeout(() => {
      Func();
    }, 500);
  }

  const columns = useMemo(
    () => [
        {
          Header: (
            <CheckboxHeader currentItem={blueprints} selectedIds={selectedProductIds} setSelectedIds={setSelectedProductIds} pk={'blueprint_id'}>
            </CheckboxHeader>
          ),
          accessor: "select",
          Cell: ({ row }) => {
            return <CheckboxCell row={row} handleSelection={handleProductSelection} selectedIds={selectedProductIds} pk={'blueprint_id'}/>
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="name">
              Naam
            </SortableHeader>
          ),
          accessor: 'name',
          Cell: ({ row }) => {
            return <a className="text-info text-decoration-none text-nowrap">{row.original.name}</a>
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="size_ids">
              Maten
            </SortableHeader>
          ),
          accessor: 'size_ids',
          Cell: ({ row }) => {
            return row.original.size_ids
          }
        },
        {
          Header: () => <div></div>,
          accessor: 'edit',
          Cell: ({ row }) => {
            return <div
              className="hover"
              onClick={() => handleBlueprintData(row.original)}
            >
              {EditIcon}
            </div>
          }
        },
    ],
    [order.orderBy, order.direction, selectedProductIds, setSelectedProductIds, blueprints]
  );

  // const columns = useMemo(() => {
  //   let columnsArray = [
  //     {
  //       Header: (
  //         <>
  //           <label className="checkbox-container">
  //             <input
  //               type="checkbox"
  //               onChange={(e) => {
  //                 const checked = e.target.checked;
  //                 setSelectedProductIds(
  //                   checked
  //                     ? blueprints.map((blueprint) => blueprint.primary_key)
  //                     : []
  //                 );
  //               }}
  //             />
  //             <span className="checkmark"></span>
  //           </label>
  //         </>
  //       ),
  //       accessor: "select",
  //       Cell: ({ row }) => {
  //         const isSelected = selectedProductIds.includes(
  //           row.original.primary_key
  //         );
  //         return (
  //           <label className="checkbox-container">
  //             <input
  //               type="checkbox"
  //               checked={isSelected}
  //               onChange={(e) =>
  //                 handleProductSelection(
  //                   row.original.primary_key,
  //                   e.target.checked
  //                 )
  //               }
  //             />
  //             <span className="checkmark"></span>
  //           </label>
  //         );
  //       },
  //     },
  //     {
  //       Header: () => (
  //         <div
  //           onClick={(e) =>
  //             setOrder({
  //               ...order,
  //               orderBy: "title",
  //               direction: `${
  //                 order.direction === "asc"
  //                   ? order.orderBy === "title"
  //                     ? "desc"
  //                     : "asc"
  //                   : order.direction === "desc"
  //                   ? order.orderBy === "title"
  //                     ? ""
  //                     : "asc"
  //                   : "asc"
  //               }`,
  //             })
  //           }
  //           className="header-filter text-center"
  //         >
  //           Naam{" "}
  //           {order.orderBy === "title" ? (
  //             order.direction === "asc" ? (
  //               <FontAwesomeIcon icon={faCaretUp} />
  //             ) : order.direction === "desc" ? (
  //               <FontAwesomeIcon icon={faCaretDown} />
  //             ) : (
  //               ""
  //             )
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       ),
  //       accessor: "title",
  //       Cell: ({ row }) => (
  //         <div className="text-center">{row.original.title}</div>
  //       ),
  //     },
  //     {
  //       Header: () => (
  //         <div
  //           onClick={(e) =>
  //             setOrder({
  //               ...order,
  //               orderBy: "blueprint",
  //               direction: `${
  //                 order.direction === "asc"
  //                   ? order.orderBy === "blueprint"
  //                     ? "desc"
  //                     : "asc"
  //                   : order.direction === "desc"
  //                   ? order.orderBy === "blueprint"
  //                     ? ""
  //                     : "asc"
  //                   : "asc"
  //               }`,
  //             })
  //           }
  //           className="header-filter text-center"
  //         >
  //           Maten{" "}
  //           {order.orderBy === "blueprint" ? (
  //             order.direction === "asc" ? (
  //               <FontAwesomeIcon icon={faCaretUp} />
  //             ) : order.direction === "desc" ? (
  //               <FontAwesomeIcon icon={faCaretDown} />
  //             ) : (
  //               ""
  //             )
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       ),
  //       accessor: "size_ids",
  //       Cell: ({ row }) => (
  //         <div className="text-center">{row.original.size_ids}</div>
  //       ),
  //     },
  //     {
  //       Header: () => (
  //         <div
  //           onClick={(e) =>
  //             setOrder({
  //               ...order,
  //               orderBy: "blueprint",
  //               direction: `${
  //                 order.direction === "asc"
  //                   ? order.orderBy === "blueprint"
  //                     ? "desc"
  //                     : "asc"
  //                   : order.direction === "desc"
  //                   ? order.orderBy === "blueprint"
  //                     ? ""
  //                     : "asc"
  //                   : "asc"
  //               }`,
  //             })
  //           }
  //           className="header-filter text-center"
  //         >
  //           {order.orderBy === "blueprint" ? (
  //             order.direction === "asc" ? (
  //               <FontAwesomeIcon icon={faCaretUp} />
  //             ) : order.direction == "desc" ? (
  //               <FontAwesomeIcon icon={faCaretDown} />
  //             ) : (
  //               ""
  //             )
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       ),
  //       accessor: "edit",
  //       Cell: ({ row }) => (
  //         <div
  //           className="hover"
  //           onClick={() => handleBlueprintData(row.original)}
  //         >
  //           {EditIcon}
  //         </div>
  //       ),
  //     },
  //   ];
  //   return columnsArray;
  // }, [
  //   selectedProductIds,
  //   blueprints,
  //   order.orderBy,
  //   order.direction,
  //   location.pathname,
  // ]);

  //   Table instance
  const tableInstance = useTable({ columns, data: blueprints });

  // Destructure table properties

  const searchDelay = async (value) => {
    // const value = await e.target.value
    setSearchBarInput(value)
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setBlueprintsSearchengine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  const suspend = async () => {
    // Iterate over each product id in the selectedProductIds array
    selectedProductIds.map(async (data) => {
      // For each product id, send a PUT request to the 'products' endpoint
      // to update the status of the product to 1 (this might signify that the product is suspended)
      await middleware.put("products/sizes", {
        primary_key: data,
        status: 1,
      });
    });

    // After updating all products, call fetchAll function to get updated list of products
    await fetchAll();

    // Reload the page to show the updated product statuses to the user
    window.location.reload();
  };

  // a function that selects all the selects in the table
  const select_all = () => {
    setSelectedProductIds(blueprints.map((blueprint) => blueprint.primary_key));
  };

  // a function that unselects all the selects in the table
  const unselect_all = () => {
    setSelectedProductIds(blueprints.map((blueprint) => blueprint.primary_key));
    setSelectedProductIds([]);
  };

  const submitBlueprint = async (event) => {
    event.preventDefault();

    try {
      const blueprintResponse = await middleware.post(
        `products/sizes/blueprints`,
        {
          title: blueprintName,
        }
      );

      localStorage.setItem(
        "Blueprintdata",
        JSON.stringify(blueprintResponse.data)
      );
      navigate("./bewerken");
    } catch (error) {
      console.error(error);
    }
  };

  const Action = (methode) => {
    if (methode == 'select-all') {
      setSelectedProductIds([...selectedProductIds, ...blueprints.filter(cons => !selectedProductIds.includes(cons.blueprint_id)).map(e => e.blueprint_id)])
    }
    else if (methode == 'unselect-all') {
      setSelectedProductIds([])
    }
    else  if (methode === "suspend") {
      handleShow();
    }
  }

  // if (loading) {
  //   return (
  //     <div
  //       className={`loading-container ${
  //         loadingScreen ? "fade-in" : "fade-out"
  //       }`}
  //     >
  //       <Loading />
  //     </div>
  //   );
  // }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Let op!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          U gaat {selectedProductIds.length}{" "}
          {selectedProductIds.length === 1 ? "item" : "items"} de-activeren.
          Weet U dat zeker?
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-danger text-white" onClick={handleClose}>
            Annuleren
          </button>
          <button
            className="btn bg-success text-white"
            onClick={() => {
              handleClose();
              delayedFunction(suspend);
              setSelectedProductIds([]);
            }}
          >
            Ik weet het zeker
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        id="modalBlueprint"
        show={showModalBlueprint}
        onHide={handleCloseBlueprint}
        
        size="md"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-2">Nieuwe Blueprint</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={handleCloseBlueprint}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter justify-content-around">
          <button
            className="btn text-white mt-3"
            onClick={handleClose}
            style={{ height: "100px", backgroundColor: "#dbb886" }}
          >
            Template gebruiken
          </button>
          <button
            className="btn bg-info mt-3 text-white"
            onClick={() => {
              handleBlueprintModalNew();
            }}
          >
            Zelf aanmaken
          </button>
        </Modal.Body>
        <Modal.Footer className="modalBottom"></Modal.Footer>
      </Modal>

      <Modal
        id="modalBlueprint"
        show={showModalBlueprintNew}
        onHide={handleCloseBlueprintNew}
        
        size="md"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-2">Nieuwe Blueprint</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={handleCloseBlueprintNew}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <form onSubmit={submitBlueprint}>
          <Modal.Body className="modalCenter justify-content-around pb-2">
            <input
              className="optionsList optionListWidt text-center mt-4 w-100 border-black"
              placeholder="Blueprint name"
              name="blueprint_name"
              onChange={(e) => setBlueprintName(e.target.value)}
              onBlur={handleBlueprintName}
              required
            ></input>
          </Modal.Body>
          <Modal.Footer className="modalBottom">
            <button type="submit" className="btn bg-info mt-3 text-white">
              aanmaken
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <PageTemplate navbarTitle={"Blauwdrukken"} iconNavbar={NavbarIcon}>
        <div className="w-100">
          <div className="blueprintsOverview">
            <div className="d-flex flex-row mb-3">
              <button
                className="btn bg-white fw-semibold rounded-pill"
                onClick={() => navigate("/warehouse/instellingen")}
              >
                  <FontAwesomeIcon icon={faChevronLeft}/>
              </button>
              <h3 className="ms-3 mb-0">Blauwdrukken</h3>
            </div>
            <div className="tableBlueprints">
              <TablePreset 
                data={[{'selectedIds': selectedProductIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'blueprint_id'}]}
                tableLoading={loading}
                setPagination={setPagination} 
                handleSelection={handleProductSelection} 
                searchBar={[{ shown: true, value: searchBarInput, function: searchDelay}]}
                actionOptions={[{title: "Deactiveer", function: () => Action("suspend")}, {title: "Selecteer alles", function: () => Action("select-all")}, {title: "De-selecteer alles", function: () => Action("unselect-all")}]} // actionOptions={[{title: "selecteer alles", function: () => console.log(true)}]}
                // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
                rightButtons={[{title: "Nieuwe blauwdruk", function: () => handleShowBlueprint()}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
              />
            </div>
          </div>
        </div>
      </PageTemplate>
    </>
  );
}

export default Blueprints;
