import React, { useMemo, useState, useEffect } from "react";
import Sidebar from "../Navbars/Sidebar/Sidebar";
import Cookies from "universal-cookie";
import Navbar from "../Navbars/Navbar/Navbar";
import middleware from "../Api/Middleware";
import "./Orders.css";
import NavbarIcon from "../Navbars/Sidebar/Icons/envelope.png"
import "../Table/Table.css";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faX,
  faPenToSquare,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../LoadingScreen/LoadingScreen";
import info from "./info.svg";
import saleschannels from "../Api/SalesChannels";
import PageTemplate from "../Templates/PageTemplate";
import Backbutton from "../Products/subpages/images/BackIcon.svg"
import OrderRowEdit from "./OrderRowEdit";


const OrdersNew = () => {
  const { order_id } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productsPrices, setProductsPrices] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [customerSpecific, setCustomerSpecific] = useState([]);
  const [orderConcept, setOrderConcept] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);
  const [order, setOrder] = useState([]);
  const [allSaleschannels, setAllSaleschannels] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [previousOrder, setPreviousOrder] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [showOrderRow, setShowOrderRow] = useState(false);
  const [previousCustomer, setPreviousCustomer] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [activeSaleType, setActiveSaleType] = useState(2);
  const [activeSaleTypeVerzend, setActiveSaleTypeVerzend] = useState(2);
  const [productsSearchengine, setProductsSearchengine] = useState("");
  const [consumersSearchengine, setConsumersSearchengine] = useState("");
  const [businessesSearchengine, setBusinessesSearchengine] = useState("");
  const [internalId, setInternalId] = useState("");
  const [emailsCustomer, setEmailsCustomer] = useState([]);
  const [phoneNumbersCustomer, setPhoneNumbersCustomer] = useState([]);
  const [orderProcessing, setOrderProcessing] = useState('');
  const [salesChannel, setSalesChannel] = useState('');
  const [status, setStatus] = useState(0);
  const [totalAfterOrderDiscountInclTax, setTotalAfterOrderDiscountInclTax] = useState(0);
  const [totalAfterOrderDiscountExclTax, setTotalAfterOrderDiscountExclTax] = useState(0);
  const [extraInfo, setExtraInfo] = useState('');
  const [wholeSale, setWholeSale] = useState({
    sale_amount: 0,
    sale_unit: "%",
  });
  const [timerId, setTimerId] = useState(null);
  const [btwState, setBtwState] = useState("incl");
  const [inputChangeProduct, setInputChangeProduct] = useState("");
  const [editingState, setEditingState] = useState(false);
  const [inputChangeInternalCode, setInputChangeInternalCode] = useState("");
  const [submittedButton, setSubmittedButton] = useState("");
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalExclTax, setTotalExclTax] = useState(0);
  const [totalInclTax, setTotalInclTax] = useState(0);
  const [orderDiscount, setOrderDiscount] = useState(0);
  const [discountTotalOrder, setDiscountTotalOrder] = useState(0);

  const fetchAll = async () => {
    try {
      const [
        productsResponse,
        consumersResponse,
        businessesResponse,
        saleschannelsResponse,
        ordersResponse,
        addressResponse,
        Pricesres,
      ] = await Promise.all([
        middleware.get(`products`),
        order_id ? middleware.get(`consumers?status=0`) : null,
        order_id ? middleware.get(`businesses?status=0`) : null,
        saleschannels.get(`saleschannels`),
        middleware.get(`orders`),
        middleware.get(`addresses`),
        middleware.get(`products/prices`)
      ]);

      setProducts(productsResponse.data);
      setConsumers(order_id ? consumersResponse.data : []);
      setBusinesses(order_id ? businessesResponse.data : []);
      setAllSaleschannels(saleschannelsResponse.data);
      setProductsPrices(Pricesres.data)
      setAllOrders(ordersResponse.data);
      setAddresses(addressResponse.data);
      setOrder(
        ordersResponse.data.filter(
          (filter) => filter.order_id == order_id
        )[0]
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      } else {
        // Handle other types of errors
        console.error("Error occurred:", error);
      }
    }
  };

  useEffect(() => {
    let discount = 0;
    let exclTax = 0;
    let inclTax = 0;

    orderConcept.forEach((product) => {
      const discountAmount = product.discount ? product.discount : 0;
      const discountedPriceInclTax = discountAmount > 0
        ? product.price_incl_tax - (product.discountType === '%' ? (product.price_incl_tax * discountAmount / 100) : discountAmount)
        : product.price_incl_tax;

      const discountedPriceExclTax = discountAmount > 0
        ? product.price_excl_tax - (product.discountType === '%' ? (product.price_excl_tax * discountAmount / 100) : discountAmount)
        : product.price_excl_tax;

      discount += (product.price_incl_tax - discountedPriceInclTax) * product.amount;
      exclTax += discountedPriceExclTax * product.amount;
      inclTax += discountedPriceInclTax * product.amount;
    });

    setTotalDiscount(discount);
    setTotalExclTax(exclTax);
    setTotalInclTax(inclTax);
  }, [orderConcept]);

  useEffect(() => {
    const orderDiscountAmount = orderDiscount ? orderDiscount : 0;

    const orderDiscountInclTax = activeSaleType === 2
      ? totalInclTax * (orderDiscountAmount / 100)
      : orderDiscountAmount;

    const orderDiscountExclTax = activeSaleType === 2
      ? totalExclTax * (orderDiscountAmount / 100)
      : orderDiscountAmount;
    
    setDiscountTotalOrder(orderDiscountInclTax + totalDiscount);
    setTotalAfterOrderDiscountInclTax(totalInclTax - orderDiscountInclTax);
    setTotalAfterOrderDiscountExclTax(totalExclTax - orderDiscountExclTax);
  }, [orderDiscount, activeSaleType, totalInclTax, totalExclTax]);


  const fetchCorrectCustomer = async () => {
    try {
      if (internalId.startsWith("B")) {
        const [ bussinessResponse, emailsResponse, phoneNumberResponse ] = await Promise.all([
          middleware.get(`businesses?internal_id=${internalId}`),
          middleware.get(`emails?internal_id=${internalId}`),
          middleware.get(`phonenumbers?internal_id=${internalId}`),
        ]);
        setCustomerSpecific(bussinessResponse.data);
        setEmailsCustomer(emailsResponse.data)
        setPhoneNumbersCustomer(phoneNumberResponse.data)
      } else if (internalId.startsWith("C")) {
        const [ consumerResponse, emailsResponse, phoneNumberResponse] = await Promise.all([
          middleware.get(`consumers?internal_id=${internalId}`),
          middleware.get(`emails?internal_id=${internalId}`),
          middleware.get(`phonenumbers?internal_id=${internalId}`),
        ]);
        setCustomerSpecific(consumerResponse.data);
        setEmailsCustomer(emailsResponse.data)
        setPhoneNumbersCustomer(phoneNumberResponse.data)
      }


    } catch (error) {
      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      } else {
        // Handle other types of errors
        console.error("Error occurred:", error);
      }
    }
  };

  console.log(emailsCustomer);

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    fetchCorrectCustomer();
  }, [internalId]);


  useEffect(() => {
    (async () => {
      setLoading(true);
      if (order_id) {
        const [orderResponse] = await Promise.all([
          middleware.get(`orders?order_id=${order_id}`),
        ]);
        setPreviousOrder(orderResponse.data);
        const order = orderResponse.data;

        if (order.internal_id.charAt(0) == "C") {
          const [customerResponse] = await Promise.all([
            middleware.get(
              `consumers?internal_id=${order.internal_id}&status=0`
            ),
          ]);
          setCustomer(...customerResponse.data);
          setPreviousCustomer(...customerResponse.data);
        } else if (order.internal_id.charAt(0) == "B") {
          const [customerResponse] = await Promise.all([
            middleware.get(
              `businesses?internal_id=${order.internal_id}&status=0`
            ),
          ]);
          setCustomer(...customerResponse.data);
          setPreviousCustomer(...customerResponse.data);
        }

        const [ordersSoldProductsResponse] = await Promise.all([
          middleware.get(`orders/soldproducts?order_id=${order.order_id}`),
        ]);
        setOrderConcept(
          ordersSoldProductsResponse.data.map((e) => ({
            ...e,
            product_id: e.sold_product_id,
            sale_amount: e.sale_amount,
            price_excl_tax: (e.price_incl_tax / 100) * (100 - e.btw_rate),
            sale_unit: e.sale_unit == 0 ? "%" : "€",
          }))
        );
      }
      setLoading(false);
    })();
  }, []);

  const updateSpecificCustomerData = (column, event) => {
    if (customer.internal_id.charAt(0) == "C") {
      if (event.target.value !== previousCustomer[column]) {
        middleware
          .put(`consumers`, {
            ...customer,
            consumer_id: customer.consumer_id,
            [column]: event.target.value,
          })
          .then(
            (res) =>
              successToast(
                `Klant ${event.target.name} bijgewerkt van ${previousCustomer[column]} naar ${event.target.value}`
              ) + setPreviousCustomer(res.data)
          );
      }
    } else if (customer.internal_id.charAt(0) == "B") {
      if (event.target.value !== previousCustomer[column]) {
        middleware
          .put(`businesses`, {
            ...customer,
            consumer_id: customer.consumer_id,
            [column]: event.target.value,
          })
          .then(
            (res) =>
              successToast(
                `Klant ${event.target.name} bijgewerkt van ${previousCustomer[column]} naar ${event.target.value}`
              ) + setPreviousCustomer(res.data)
          );
      }
    }
  };

  const updateSpecificProductRow = (column, sold_product_id) => {
    middleware
      .put(`orders/soldproducts`, {})
      .then(
        (res) =>
          successToast(`Bestelling gegevens bijgewerkt`) +
          setPreviousOrder(res.data)
      );
  };

  const setTotalPrice = (totalTax, orderDiscountTax) => {
    setTotalAfterOrderDiscountExclTax(totalTax - orderDiscountTax)
  }

  const updateQuantity = (product_id, newAmount) => {
    setOrderConcept(prevConcept =>
      prevConcept.map(product => {
        if (product.product_id === product_id) {
          return {
            ...product,
            amount: newAmount,
          };
        }
        return product;
      })
    );
  };
  
  
  const updateDiscount = (product_id, newDiscount, newDiscountType) => {
    setOrderConcept(prevConcept =>
      prevConcept.map(product => {
        if (product.product_id === product_id) {
          return {
            ...product,
            discount: newDiscount,
            discountType: newDiscountType,
          };
        }
        return product;
      })
    );
  };

  const successToast = (text) => {
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const dangerToast = (text) => {
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };


  const NewOrder = async (e) => {
    e.preventDefault();
    if (orderConcept.length > 0) {
        const currentDate = new Date();
        const year = currentDate.getFullYear() % 100;
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const hour = String(currentDate.getHours()).padStart(2, "0");
        const minute = String(currentDate.getMinutes()).padStart(2, "0");
        const second = String(currentDate.getSeconds()).padStart(2, "0");
        let orderNumber = "";
        if (allOrders.length > 0) {
            const lastRow = allOrders.length - 1;
            const rest = Number(allOrders[lastRow]["order_number"].substring(6));
            const incrementedRest = (rest + 1).toString(10);
            const leadingZeros = "000000".substring(0, 6 - incrementedRest.length); // Pad with leading zeros
            orderNumber = `TDS-${year}${leadingZeros}${incrementedRest}`;
        } else {
            orderNumber = `TDS-${year}000000`;
        }
        try {
            const orderResponse = await middleware.post("orders", {
                internal_id: customer.internal_id,
                order_number: orderNumber,
                order_status: submittedButton === "withInvoice" ? 2 : 1,
                note: extraInfo,
                sales_channel_id: salesChannel,
                total_price_incl_tax: totalAfterOrderDiscountInclTax,
                total_price_excl_tax: totalAfterOrderDiscountExclTax,
                sale_amount: discountTotalOrder,
                payment_status: 0,
                delivery_status: status,
            });

            const orderId = orderResponse.data.order_id;
            if (!orderId) {
                throw new Error("Failed to create order");
            }
            try {
              orderConcept.map(async (concept) => (
                await middleware.post("orders/soldproducts", {
                  order_id: orderId,
                  product_id: concept.product_id,
                  product_name: concept.product_name,
                  amount: concept.amount,
                  price_incl_tax: concept.price_incl_tax,
                  price_excl_tax: concept.price_excl_tax,
                  product_number: concept.product_number,
                  product_description: concept.product_description || "",
                  btw_rate: concept.tax_class == "high" ? 21 : 9,
                  sold_at: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
                  sale_amount: concept.sale_amount,
                  sale_unit: concept.sale_unit == "%" ? 0 : 1,
                })
              ))
            } catch (error) {
              console.log(error);
            }
            successToast("Bestelling " + orderNumber + " aangemaakt");
            if (submittedButton === "withInvoice") {
                await middleware.post("invoices", {
                  internal_id: internalId,
                  order_number: orderNumber,
                  price: totalAfterOrderDiscountInclTax,
                  note: orderInfo.note,
                  billed_on:`${year}-${month}-${day} ${hour}:${minute}:${second}`,
                  status: 1,
                })
                successToast("Factuur verstuurd voor " + orderNumber);
            } else {
                await middleware.post("invoices", {
                  internal_id: internalId,
                  order_number: orderNumber,
                  price: totalAfterOrderDiscountInclTax,
                  note: orderInfo.note,
                  billed_on:`${year}-${month}-${day} ${hour}:${minute}:${second}`,
                  status:0,
                })
                dangerToast("Nog geen factuur verstuurd voor " + orderNumber);
            }
            navigate("/bestellingen");
        } catch (error) {
            console.error("Error occurred:", error);
            dangerToast("Er is een fout opgetreden bij het aanmaken van de bestelling");
        }
    }
};

  const searchDelay = async (value, setSearchEngine, setInput) => {
    console.log(value);
    // const value = await e.target.value
    setInput(value);
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setSearchEngine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  const funcDelay = async (func, column, event) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      func(column, event);
    }, 500);

    setTimerId(newTimerId);
  };

  useEffect(() => {
    if (consumersSearchengine !== "") {
      const doIt = async () => {
        const [response] = await Promise.all([
          middleware.get(`consumers?searchTerm=${consumersSearchengine}`),
        ]);
        setConsumers(response.data);
      };
      doIt();
    }
  }, [consumersSearchengine]);

  useEffect(() => {
    if (businessesSearchengine !== "") {
      const doIt = async () => {
        const [response] = await Promise.all([
          middleware.get(`businesses?searchTerm=${businessesSearchengine}`),
        ]);
        if (response.status == 200) {
          setBusinesses(response.data);
        }
      };
      doIt();
    }
  }, [businessesSearchengine]);

  useEffect(() => {
    if (productsSearchengine !== "") {
      const doIt = async () => {
        const [response] = await Promise.all([
          middleware.get(`products?searchTerm=${productsSearchengine}`),
        ]);
        if (response.status == 200) {
          setProducts(response.data);
        }
      };
      doIt();
    }
  }, [productsSearchengine]);

  if (loading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <PageTemplate navbarTitle={"Order HUB"} iconNavbar={NavbarIcon}>
      <OrderRowEdit openOrderRow={showOrderRow} closeOrderRow={() => setShowOrderRow(false)}/>
      <div className="NewOrder w-100">
        <form onSubmit={NewOrder}>
          <div>
            <div className="d-flex flex-row mb-4 justify-content-between">
              <div className='d-flex flex-row align-items-center' onClick={() => navigate("/bestellingen")}>
                <img style={{width: "32px"}} className='me-3' src={Backbutton}/>
                <p className='mb-0 fw-semibold fs-5'>Alle bestellingen</p>
              </div>
              <div>
                <button type="submit" className="darkBlueStandardButton me-3" onClick={() => setSubmittedButton("withInvoice")}>Maak factuur aan</button>
                <button type="submit" className="lightBlueStandardButton" onClick={() => setSubmittedButton("asConcept")}>Sla op als concept</button>
              </div>
            </div>
            
            <div className="d-flex justify-content-between gap-5 parentBoxes flex-column w-100">
              <div className="bg-white rounded-4 p-4 customerBox">
                <div className="d-flex justify-content-between px-3">
                  <h5 className=" fw-semibold ">
                    Klant{" "}
                    {order_id ? (
                      <span
                        role="button"
                        className="ps-2"
                        onClick={(e) => setEditingState(!editingState)}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>
                    ) : (
                      ""
                    )}
                  </h5>
                  {order_id ? (
                    ""
                  ) : customer.internal_id !== "C-0001" ? (
                    <div>
                      {/* <span
                        role="button"
                        className="text-primary"
                        onClick={async (e) => {
                          try {
                            const response = await middleware.get(
                              `consumers?internal_id=C-0001`
                            );
                            const anonymouscustomer = response.data[0]; // Assuming there is only one customer with internal_id "C-0001"
                            setCustomer(anonymouscustomer);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        Anonieme bestelling?
                      </span> */}

                      <button className="darkBlueStandardButton me-3">Nieuwe consument</button>
                      <button className="lightBlueStandardButton ">Nieuw bedrijf</button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="customersSearch px-3 mb-3">
                  {editingState || !order_id ? (
                    <>
                      <label className=" fw-semibold mb-2">Klantnaam</label>
                      <span className="borderedSearchStyle d-flex align">
                        <input
                          style={{padding: "1.3rem"}}
                          className={!consumersSearchengine ? "w-100 SearchIcon standardInput" : "w-100 clientSearching SearchIcon standardInput"}
                          placeholder="Zoek klant op naam, email etc."
                          type="text"
                          value={
                            inputChangeInternalCode
                              ? inputChangeInternalCode
                              : ""
                          }
                          onChange={(e) =>
                            searchDelay(
                              e.target.value,
                              setConsumersSearchengine,
                              setInputChangeInternalCode
                            ) +
                            searchDelay(
                              e.target.value,
                              setBusinessesSearchengine,
                              setInputChangeInternalCode
                            )
                          }
                        ></input>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  <table className={!consumersSearchengine ? "d-none" : "customersResults"}                 >
                    <tbody>
                      {!order_id ? (
                        <>
                          {consumers
                            .filter(
                              (filter) => filter.internal_id !== "C-0001"
                            )
                            .map((data) => (
                              <tr
                                className="tableRow"
                                key={data.internal_id}
                                onClick={(e) =>
                                  setCustomer(data) +
                                  setInternalId(data.internal_id) +
                                  setConsumersSearchengine("") +
                                  setBusinessesSearchengine("") +
                                  setInputChangeInternalCode("") +
                                  setEmailsCustomer([]) +
                                  setPhoneNumbersCustomer([]) +
                                  successToast("Klant geselecteerd")
                                }
                              >
                                <td>
                                  {data.first_name +
                                    " " +
                                    (data.last_name || "")}
                                </td>
                                {/* <td>{data.email}</td>
                                <td>{data.phone_number}</td> */}
                              </tr>
                            ))}
                          {businesses
                            .filter(
                              (filter) => filter.internal_id !== "C-0001"
                            )
                            .map((data) => (
                              <tr
                                className="tableRow"
                                key={data.internal_id}
                                onClick={(e) =>
                                  setCustomer(data) +
                                  setInternalId(data.internal_id) +
                                  setConsumersSearchengine("") +
                                  setBusinessesSearchengine("") +
                                  setInputChangeInternalCode("") +
                                  setEmailsCustomer([]) +
                                  setPhoneNumbersCustomer([]) +
                                  successToast("Klant geselecteerd")
                                }
                              >
                                <td>{data.company_name}</td>
                                <td>{data.email}</td>
                                <td>{data.phone_number}</td>
                              </tr>
                            ))}
                        </>
                      ) : (
                        <>
                          {consumers
                            .filter(
                              (filter) => filter.internal_id !== "C-0001"
                            )
                            .map((data) => (
                              <tr
                                className="customerTableRow"
                                key={data.internal_id}
                                onClick={(e) =>
                                  middleware.put(`orders`, {
                                    order_id: order.order_id,
                                    internal_id: data.internal_id,
                                  }) +
                                  setCustomer(data) +
                                  setConsumersSearchengine("") +
                                  setBusinessesSearchengine("") +
                                  setInputChangeInternalCode("") +
                                  successToast("Klant aangepast")
                                }
                              >
                                <td>
                                  {data.first_name +
                                    " " +
                                    (data.last_name || "")}
                                </td>
                                <td>{data.email}</td>
                                <td>{data.phone_number}</td>
                              </tr>
                            ))}
                          {businesses
                            .filter(
                              (filter) => filter.internal_id !== "C-0001"
                            )
                            .map((data) => (
                              <tr
                                className="customerTableRow"
                                key={data.internal_id}
                                onClick={(e) =>
                                  middleware.put(`orders`, {
                                    order_id: order.order_id,
                                    internal_id: data.internal_id,
                                  }) +
                                  setCustomer(data) +
                                  setConsumersSearchengine("") +
                                  setBusinessesSearchengine("") +
                                  setInputChangeInternalCode("") +
                                  successToast("Klant aangepast")
                                }
                              >
                                <td>{data.company_name}</td>
                                <td>{data.email}</td>
                                <td>{data.phone_number}</td>
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                {customer.length == 0 ||
                customer.internal_id == "C-0001" ? (
                  customer && customer.internal_id == "C-0001" ? (
                    <div className="mt-2">Deze bestelling anoniem</div>
                  ) : (
                    ""
                  )
                ) : !editingState ? (
                  customer && (
                    <table className="mt-2 w-100 table">
                      <tbody>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "B"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Bedrijfsnaam:</td>
                          <td>
                            {customer.company_name
                              ? customer.company_name
                              : ""}
                          </td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "C"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Naam:</td>
                          <td>
                            {customer.first_name ? customer.first_name : ""}{" "}
                            {customer.last_name ? customer.last_name : ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Telefoonnummers:</td>
                          <td>
                            {phoneNumbersCustomer && phoneNumbersCustomer.length > 0 ? (
                              phoneNumbersCustomer.map((phoneNumber, i) => (
                                <div key={i}>{phoneNumber.phone_number}</div>
                              ))
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Contact e-mail:</td>
                          <td>
                            {emailsCustomer && emailsCustomer.length > 0 ? (
                              emailsCustomer.map((email, i) => (
                                email.type === 0 ? <div key={i}>{email.email}</div> : null
                              )).filter(Boolean).length > 0 ? (
                                emailsCustomer.map((email, i) => (
                                  email.type === 0 ? <div key={i}>{email.email}</div> : null
                                ))
                              ) : (
                                "-"
                              )
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "B"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Factuur mail:</td>
                          <td>
                            {
                              emailsCustomer.map((email, index) => 
                                email.type == 1 ? email.email : null
                              )
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Fax nummer:</td>
                          <td>
                            {customer.fax_number ? customer.fax_number : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
                ) : (
                  customer && (
                    <table className="mt-2 w-100 table">
                      <tbody>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "B"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Bedrijfsnaam:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData(
                                  "company_name",
                                  e
                                )
                              }
                              name="bedrijfs naam"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.company_name
                                  ? customer.company_name
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  company_name: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "C"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Voornaam:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("first_name", e)
                              }
                              name="voornaam"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.first_name
                                  ? customer.first_name
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  first_name: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "C"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Tussenvoegsel:</td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "C"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Achternaam:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("last_name", e)
                              }
                              name="achternaam"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.last_name ? customer.last_name : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  last_name: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Telefoonnummer:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData(
                                  "phone_number",
                                  e
                                )
                              }
                              name="telefoonnummer"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.phone_number
                                  ? customer.phone_number
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  phone_number: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Contact e-mail:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("email", e)
                              }
                              name="email"
                              className="borderedSearchStyle w-100"
                              value={customer.email ? customer.email : ""}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  email: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr
                          hidden={
                            customer.internal_id.charAt(0) !== "B"
                              ? true
                              : false
                          }
                        >
                          <td className="col-2">Factuur mail:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("invoicemail", e)
                              }
                              name="factuur email"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.invoicemail
                                  ? customer.invoicemail
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  invoicemail: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td className="col-2">Fax nummer:</td>
                          <td>
                            <input
                              onBlur={(e) =>
                                updateSpecificCustomerData("fax_number", e)
                              }
                              name="fax nummer"
                              className="borderedSearchStyle w-100"
                              value={
                                customer.fax_number
                                  ? customer.fax_number
                                  : ""
                              }
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  fax_number: e.target.value,
                                })
                              }
                            ></input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
                )}
              </div>
              <div className="bg-white rounded-4 p-4 w-100">
                <div className="d-flex justify-content-between flex-row flex-wrap mb-2 px-3">
                  <h5 className="fw-semibold">Producten</h5>
                </div>
                <div className="productsSearch px-3">
                  <span
                    className={`borderedSearchStyle d-flex align ${
                      order_id ? "d-none" : ""
                    }`}
                  >
                    <input
                      onChange={(e) =>
                        searchDelay(
                          e.target.value,
                          setProductsSearchengine,
                          setInputChangeProduct
                        )
                      }
                      value={inputChangeProduct}
                      style={{padding: "1.3rem"}}
                      className={!productsSearchengine ? "w-100 SearchIcon standardInput" : "w-100 clientSearching SearchIcon standardInput"}
                      placeholder="Zoek product"
                      type="text"
                    ></input>
                  </span>
                  {!productsSearchengine ? (
                    ""
                  ) : (
                    <div className="d-flex w-100 justify-content-center align-content-around searchResults productsResults">
                      <table
                        className="tableSearch"
                      >
                        <tbody className="bg-white">
                          {products.map((product, index) => (
                            <tr
                              key={index}
                              className="tableRow"
                              onClick={() => {
                                const updateOrderConcept = () => {
                                  const existingProductIndex = orderConcept.findIndex(
                                    (item) => item.product_id === product.product_id
                                  );

                                  // Haal de prijsinformatie op uit productsPrices
                                  const productPrice = productsPrices.find(
                                    (price) => price.product_id === product.product_id
                                  );

                                  if (existingProductIndex !== -1) {
                                    // Als het product al in de orderConcept staat, verhoog de hoeveelheid
                                    const updatedOrderConcept = [...orderConcept];
                                    updatedOrderConcept[existingProductIndex].amount =
                                      Number(updatedOrderConcept[existingProductIndex].amount) + 1;
                                    setOrderConcept(updatedOrderConcept);
                                  } else {
                                    // Voeg het product toe met de prijsinformatie
                                    setOrderConcept([
                                      ...orderConcept,
                                      {
                                        ...product,
                                        amount: 1,
                                        sale_unit: "%",
                                        discountType: "€",
                                        sale_amount: 0,
                                        price_excl_tax:
                                          productPrice ? productPrice.excl_tax : 0,
                                        price_incl_tax:
                                          productPrice ? productPrice.incl_tax : 0,
                                      },
                                    ]);
                                  }
                                };
                                updateOrderConcept();
                                setProductsSearchengine("");
                                setInputChangeProduct("");
                              }}
                            >
                              <td>{product.product_name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center px-3 mt-4 w-100">
                  {orderConcept.length == 0 ? (
                    ""
                  ) : (
                    <div className="d-flex flex-column w-100 mb-3">
                      <table className="w-100 m-0 mb-3 table tableProductSearch">
                        <thead>
                          <tr className="bg-white text-center text-nowrap">
                            <th className=" px-3">Aantal</th>
                            <th className="text-start  px-3">Product</th>
                            <th>
                              Prijs{" "}
                              {btwState.charAt(0).toUpperCase() +
                                btwState.slice(1)}
                              . BTW
                            </th>
                            <th className="text-center">Korting</th>
                            <th>Kortingprijs</th>
                            <th>Totaal</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderConcept.map((product, index) => {
                            const discountAmount = product.discount ? product.discount : 0;
                            const discountedPrice = discountAmount > 0
                            ? product.price_incl_tax - (product.discountType === '%' ? (product.price_incl_tax * discountAmount / 100) : discountAmount)
                            : product.price_incl_tax;
                            const totalPrice = discountedPrice * product.amount;

                            return (
                              <tr key={index} className="text-center" style={{ minHeight: "59px" }}>
                                <td className=" px-3" style={{width: "8.5%"}}>
                                  <div className="amountParent">
                                    <button
                                      type="button"
                                      className="amountCountButtons fw-semibold fs-5"
                                      onClick={() =>
                                        updateQuantity(product.product_id, Math.max(1, product.amount - 1))
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      type="number"
                                      min={1}
                                      value={product.amount}
                                      onChange={e => updateQuantity(product.product_id, Number(e.target.value))}
                                      className="p-0 amountCount w-75 text-center z-3"
                                    />
                                    <button
                                    type="button"
                                      className="amountCountButtons fw-semibold fs-5"
                                      onClick={() => updateQuantity(product.product_id, product.amount + 1)}
                                    >
                                      +
                                    </button>
                                  </div>
                                </td>
                                <td
                                  className="text-start text-info px-3 d-flex align-items-center"
                                  style={{ maxWidth: "30vw" }}
                                >
                                  <div style={{ minHeight: "43px" }} className="fitcontentHeight d-flex justify-content-center">
                                    <div className="d-flex align-self-center mb-0 cursorPointer" onClick={() => setShowOrderRow(true)}>
                                      {product.product_name}
                                    </div> 
                                  </div>
                                </td>
                                <td style={{ width: "11%" }}>
                                  <div style={{ minHeight: "43px" }} className="fitcontentHeight d-flex justify-content-center">
                                    <div className="d-flex align-self-center mb-0">
                                      €{product.price_incl_tax.toFixed(2)}
                                    </div>
                                  </div>
                                </td>
                                <td style={{ width: "11%" }}>
                                  <div style={{ minHeight: "43px" }} className="fitcontentHeight d-flex justify-content-center">
                                    <div className="d-flex align-self-center mb-0">
                                      <input
                                        type="number"
                                        defaultValue={product.discount}
                                        onChange={e => updateDiscount(product.product_id, Number(e.target.value), product.discountType)}
                                        className="p-2 inputSaleOrder text-center fw-semibold me-2"
                                        placeholder="0"
                                        step=".01"
                                        max={product.discountType === '€' ? product.price_incl_tax.toFixed(2) : 100}
                                      />
                                      <div className="d-flex flex-row">
                                        <button
                                          type="button"
                                          className={`euroSaleButton p-2 fw-semibold ${product.discountType === '€' ? "activeSaleType" : ""}`}
                                          onClick={() => updateDiscount(product.product_id, product.discount, '€')}
                                        >
                                          €
                                        </button>
                                        <button
                                          type="button"
                                          className={`percentageSaleButton p-2 fw-semibold ${product.discountType === '%' ? "activeSaleType" : ""}`}
                                          onClick={() => updateDiscount(product.product_id, product.discount, '%')}
                                        >
                                          %
                                        </button>
                                      </div>
                                    </div> 
                                  </div>
                                </td>
                                <td style={{ width: "11%" }}>
                                  <div style={{ minHeight: "43px" }} className="fitcontentHeight d-flex justify-content-center">
                                    <div className="d-flex align-self-center mb-0">
                                      {/* Laat 0.00 zien als er geen korting is */}
                                      €{discountedPrice.toFixed(2)}
                                    </div>
                                  </div>
                                </td>
                                <td style={{ width: "8%" }}>
                                  <div style={{ minHeight: "43px" }} className="fitcontentHeight d-flex justify-content-center">
                                    <div className="d-flex align-self-center mb-0">
                                      {/* Laat 0.00 zien als er geen totaal prijs is */}
                                      €{totalPrice.toFixed(2)}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{ width: "20px" }}
                                  className="px-3"
                                  onClick={() => {
                                    setOrderConcept(prevConcept => prevConcept.filter(p => p.product_id !== product.product_id));
                                  }}
                                >
                                  <div style={{ minHeight: "43px" }} className="cursorPointer fitContent fitcontentHeight d-flex justify-content-center">
                                    <div className="d-flex align-self-center mb-0"><FontAwesomeIcon icon={faX} /></div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-end">
                        <div className="d-flex flex-row justify-content-around" style={{ width: "30%" }}>
                          <h5 className="fw-semibold">Subtotaal</h5>
                          <div className="fw-semibold">
                            €{orderConcept.reduce((sum, product) => {
                              const discountAmount = product.discount ? product.discount : 0;
                              const discountedPrice = discountAmount > 0
                                ? product.price_incl_tax - (product.discountType === '%' ? (product.price_incl_tax * discountAmount / 100) : discountAmount)
                                : product.price_incl_tax;
                              const totalPrice = discountedPrice * product.amount;
                              return sum + totalPrice;
                            }, 0).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-between noteSubtotal px-3 mb-5">
                  <div className="col-6 pe-4 d-flex flex-column parentNote">
                    <div className="fs-5 mb-3">Opmerking op factuur</div>
                    <textarea
                      placeholder="Opmerking op factuur"
                      name="note"
                      className="textareaStyleOrders p-3"
                      value={order ? order.note : orderInfo.note}
                      onChange={(e) => setOrderInfo({ ...orderInfo, note: e.target.value,})}
                    ></textarea>
                  </div>
                  <div className="col-6 d-flex flex-column">
                    <div className="fs-5 mb-3">Bestelling overzicht</div>
                    <div
                      className="h-100 p-3 pt-2 rounded-4 parentSubTotaal"
                      style={{ border: "2px solid #f3f3f3" }}
                    >
                      <div>
                      <div className="d-flex justify-content-between discountOrder mb-3">
                        <div className="fw-semibold d-flex align-items-center">Korting over bestelling</div>
                        <div className="d-flex buttonswitch">
                          <div className="d-flex align-content-center mb-0">
                            <input
                              className="p-2 inputSaleOrder text-center fw-semibold me-2"
                              placeholder="0"
                              step=".01"
                              max={activeSaleType === 1 ? totalInclTax.toFixed(2) : 100}
                              value={orderDiscount}
                              onChange={(e) => setOrderDiscount(Number(e.target.value))}
                            />
                            <div className="d-flex flex-row">
                              <button
                                type="button"
                                className={`euroSaleButton p-2 fw-semibold ${activeSaleTypeVerzend === 1 ? "activeSaleType" : ""}`}
                                onClick={() => setActiveSaleTypeVerzend(1)}
                              >
                                €
                              </button>
                              <button
                                type="button"
                                className={`percentageSaleButton p-2 fw-semibold ${activeSaleTypeVerzend === 2 ? "activeSaleType" : ""}`}
                                onClick={() => setActiveSaleTypeVerzend(2)}
                              >
                                %
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between subTotal mb-4">
                        <select className="standardDropdownArrow standardInput px-3 pe-4 w-50">
                          <option hidden>Verzendmethode</option>
                          <option>Ophalen</option>
                          {shippingMethods.map((map) => (
                            <option key={map.shipping_id} value={map.shipping_id}>
                              {map.title}
                            </option>
                          ))}
                        </select>
                        <div className="d-flex justify-content-end shippingCosts">
                          <div className="d-flex align-content-center mb-0">
                            <div className="me-3 fwPerfect d-flex align-items-center">€6,95</div>
                            <input className="p-2 inputSaleOrder text-center fw-semibold me-2" placeholder="0" />
                            <div className="d-flex flex-row">
                              <button
                                className={`euroSaleButton p-2 fw-semibold ${activeSaleType === 1 ? "activeSaleType" : ""}`}
                                onClick={() => setActiveSaleType(1)}
                              >
                                €
                              </button>
                              <button
                                className={`percentageSaleButton p-2 fw-semibold ${activeSaleType === 2 ? "activeSaleType" : ""}`}
                                onClick={() => setActiveSaleType(2)}
                              >
                                %
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex flex-row justify-content-between mb-2">
                          <div className="fw-semibold">Totale korting</div>
                          <div className="fw-semibold">€{(totalDiscount + (activeSaleType === 2 ? totalInclTax * (orderDiscount / 100) : orderDiscount)).toFixed(2)}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between mb-2">
                          <div className="fw-semibold">Totale excl. BTW</div>
                          <div className="fw-semibold">€{totalAfterOrderDiscountExclTax.toFixed(2)}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between mb-2">
                          <div className="fw-semibold">Totale incl. BTW</div>
                          <div className="fw-semibold">€{totalAfterOrderDiscountInclTax.toFixed(2)}</div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex flex-column">
                  <div className="fs-5 mt-3 mb-2">Details</div>
                  <div className="d-flex dropdownDetails">
                    <div className="col-3 me-3 ordersProcessing">
                      <div className="text-nowrap">
                        Bestelling verwerken
                      </div>
                      <select
                        defaultValue={0}
                        className="borderedSearchStyle selectDetails"
                        name="order_processing"
                      >
                        <option value={0}>Bestelverzoek</option>
                      </select>
                    </div>
                    <div className="col-3 ms-3 sellLocation">
                      <div>Verkooplocatie</div>
                      <select
                        defaultValue={0}
                        className="borderedSearchStyle selectDetails"
                        name="sales_channel_id"
                      >
                        {allSaleschannels.map((map) => (
                          <option
                            key={map.sales_channel_id}
                            value={map.sales_channel_id}
                          >
                            {map.sales_channel_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-3">
                                            <div>Status</div>
                                            <select defaultValue={0} className="borderedSearchStyle selectDetails" name="status">
                                                <option value={0}>Nieuw</option>
                                            </select>
                                        </div>
                  </div>
                </div> */}
              </div>
              <div className="col-12 d-flex flex-row justify-content-between">
                <div className="bg-white rounded-4 p-4" style={{width: "49%"}}>
                  <h5 className="fw-semibold mb-3">Details</h5>
                  <div className="mb-3">
                    <div className="fw-semibold mb-2 d-flex flex-row">Bestelling verwerken <img src={info} className="d-flex align-self-center ms-2" style={{height: "20px", width: "20px"}}/></div>
                    <select
                      className="standardInput standardDropdownArrow px-3 w-100"
                      value={orderProcessing}
                      onChange={(e) => setOrderProcessing(e.target.value)}
                    >
                      <option value="">Bestelling verwerken</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>
                  <div className="mb-3">
                    <div className="fw-semibold mb-2 d-flex flex-row">Verkooplocatie <img src={info} className="d-flex align-self-center ms-2" style={{height: "20px", width: "20px"}}/></div>
                    <select
                      className="standardInput standardDropdownArrow px-3 w-100"
                      value={salesChannel}
                      onChange={(e) => setSalesChannel(e.target.value)}
                    >
                      <option hidden>Kies verkooplocatie</option>
                      {allSaleschannels.map((map) => (
                        <option key={map.sales_channel_id} value={map.sales_channel_id}>
                          {map.sales_channel_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <div className="fw-semibold mb-2 d-flex flex-row">Status <img src={info} className="d-flex align-self-center ms-2" style={{height: "20px", width: "20px"}}/></div>
                    <select
                      className="standardInput standardDropdownArrow px-3 w-100"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={0}>Niet verzonden</option>
                      <option value={1}>Verzonden</option>
                    </select>
                  </div>
                </div>
                <div className="bg-white rounded-4 p-4" style={{width: "49%"}}>
                  <h5 className="fw-semibold">Extra informatie</h5>
                  <div style={{height: "250px"}}>
                    <textarea
                      placeholder="Referentie op factuur"
                      name="note"
                      className="textareaStyleOrders w-100 p-3"
                      value={extraInfo}
                      onChange={(e) => setExtraInfo(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </PageTemplate>
  );
};


export default OrdersNew;