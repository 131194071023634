import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import middleware from "../../../Api/Middleware";
import salesChannels from "../../../Api/SalesChannels";
import TablePreset from "../../../Table/TablePreset";
import "../../../Table/Table.css";
import "../../../Orders/Orders.css";
import { useNavigate, useParams } from "react-router";
import CheckboxHeader from "../../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../../Table/TableComponents/SortableHeader";
import Trash from "../Icons/trash-can.svg";
import ToastError from "../../../Toasts/ToastError";
import Downloaden from "../Icons/download.svg";
import ToastSuccess from "../../../Toasts/ToastSuccess";

const Invoice = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({orderBy: '', direction: ''});
  const [searchBar, setSearchBar] = useState("");
  const [rowToDelete, setRowToDelete] = useState(null);
  const { internal_id } = useParams();

  const fetchAll = async () => {
    setLoading(true);
    try {
        const [offersResponse] = await Promise.all([
            middleware.get(`invoices?page=${pagination.page}&internal_id=${internal_id}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&direction=${order.direction}` : ''}`),
        ]);
        setData(offersResponse.data.data);
        setPagination({...pagination, "max_items": offersResponse.data.total, "begin_of_page": offersResponse.data.from, "end_of_page": offersResponse.data.to});
    } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
    }
    setLoading(false);
  };
  

  useEffect(() => {
    try {
      fetchAll();
    } catch (error) {
      console.warn(error)
    }
  }, [pagination.page_size, pagination.page, order, order.direction]);

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const GetStatus = ({ Status }) => {
    switch (Status) {
      case 0:
        return <label className="text-danger">Concept</label>;
      case 1:
        return <label className="text-warning">Openstaand</label>;
      case 2:
        return <label className="text-info">Deels betaald</label>;
      case 3:
        return <label className="text-success">Volledig betaald</label>;
    }
  }

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('nl-NL'); // Formats the date
    const formattedTime = date.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' });
  
    return { formattedDate, formattedTime };
  };

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader currentItem={data} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'invoice_id'}>
          </CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'invoice_id'}/>
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="invoice_number">
            Factuurnummer
          </SortableHeader>
        ),
        accessor: 'InvoiceNumber',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column" style={{color: "#009fe3"}}>
              <p className="mb-0">{row.original.invoice_number}</p>
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} classNameHeader={"TypeAdressInfoHeader"} field="billed_on">
            Factuur datum
          </SortableHeader>
        ),
        accessor: 'billed_on',
        Cell: ({ row }) => {
          const { formattedDate, formattedTime } = formatDateTime(row.original.billed_on);
          return (
            <div className="text-center">
              <div>{formattedDate}</div>
              <div>{formattedTime}</div>
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} classNameHeader={"TypeAdressInfoHeader"} field="price">
            Waarde (excl. BTW)
          </SortableHeader>
        ),
        accessor: 'price',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <div>€ {parseFloat(row.original.price).toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} classNameHeader={"TypeAdressInfoHeader"} field="status">
            Status
          </SortableHeader>
        ),
        accessor: 'status',
        Cell: ({ row }) => {
          return (
            <div className="text-start"><GetStatus Status={row.original.status} /></div>
          )
        }
      },
      {
        Header: () => (
          <div className="text-center">Downloaden</div>
        ),
        accessor: 'Download',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column cursorPointer">
              <img className="downloadImg" src={Downloaden} />
            </div>
          )
        }
      },
    ], [order.orderBy, order.direction, selectedIds, setSelectedIds, data]
  );

  // Table instance
  const tableInstance = useTable({ columns, data: data });

  return (
    <div className="InvoiceOverviewTab h-100 mt-4">
      <div className="mb-3 mt-5 tablesCustomerEdit componentMarginTop2_5 h-100">
        <TablePreset 
          data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id'}]} 
          setPagination={setPagination} 
          handleSelection={handleSelection} 
          searchBar={[{ shown: true, value: searchBar, function: setSearchBar}]}
          leftButtons={[{title: "Filteren", function: () => console.log('a')}]} // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
          rightButtons={[{title: "Nieuw factuur", function: () => navigate(-1)}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
        />
      </div>
    </div>
  );
}

export default Invoice;