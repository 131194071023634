import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from '@fortawesome/free-solid-svg-icons'

const SecondBox = (props) => {
  return (
    <>
      {/* Second Box */}
      <div className="whiteBox p-5 m-5">
        <div className="d-flex justify-content-between">
          <h4>Prijzen</h4>
          <select
                        className="inputField standardInput standardDropdownArrow me-3 fw-medium hover"
                        style={{height: "30px", width: "137px", fontSize: "13px" }}
                        onChange={props.HandleTaxChange}
                        value={props.selectedTax}
                      >
                        <option value="" disabled hidden>
                          Prijzen incl. BTW
                        </option>
                        <option
                          value="21"
                          {...(props.tax === "21" ? { hidden: true } : { hidden: false })}
                        >
                          21%
                        </option>
                        <option
                          value="9"
                          {...(props.tax === "9" ? { hidden: true } : { hidden: false })}
                        >
                          9%
                        </option>
                      </select>
          <button className=' rounded-pill px-2 text-secondary gearButtonPrices' type='button'><FontAwesomeIcon icon={faGear}/></button>
        </div>
        {/* bovenste rij prijzen box */}
        <div className='px-1'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex flex-row justify-content-between standardSaleParent'>
                        <div className="d-flex flex-column me-4">
                          <label className="inputTitle text-small">Standaard prijs</label>
                          <input
                            type="number"
                            step="any"
                            name='price'
                            className="standardInput w-100 ps-2 placeholderStyle"
                            placeholder="0.00"
                            value={props.prices[0]?.incl_tax ?? null}
                            onChange={props.HandlePriceChange}
                          />
                          <p className="bottomText">€ {props.prices[0]?.excl_tax ?? 0.00} Excl. BTW</p>
                        </div>
                        <div className="d-flex flex-column me-2">
                          <label className="inputTitle text-small">Sale prijs</label>
                          <input
                            type="number"
                            step="any"
                            name='sale_price'
                            className="standardInput w-100 ps-2 placeholderStyle"
                            placeholder="0.00"
                            value={props.prices[1]?.incl_tax ?? null}
                            onChange={props.HandlePriceChange}
                          />
                          <p className="bottomText">€ {props.prices[1]?.excl_tax ?? 0.00 } Excl. BTW</p>
                        </div>
                      </div>
                      <div className='d-flex flex-row justify-content-between standardSaleParent'>
                        <div className="d-flex flex-column me-4">
                          <label className="inputTitle text-small">Inkoopprijs excl. BTW</label>
                          <input
                            type="number"
                            step="any"
                            name='buy_in_price'
                            className="standardInput w-100 ps-2 placeholderStyle"
                            placeholder="0.00"
                            value={props.prices[2]?.incl_tax ?? null}
                            onChange={props.HandlePriceChange}
                          />
                        </div>
                        <div className="d-flex flex-column heightSalePrice">
                          <label className="inputTitle text-small text-nowrap">
                            Marge excl. BTW
                          </label>
                          <input
                            type="number"
                            className="inputField w-100 ps-2 buyPrice placeholderStyle"
                            placeholder="€ -"
                            disabled
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
        {/* onderste rij prijzen box*/}
        {/* onderste rij prijzen box eind*/}
      </div>
      {/* Second Box end */}
    </>
  );
};

export default SecondBox;
