import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { IoMdSend } from 'react-icons/io';
import middleware from "../Api/Middleware";
import ToastSuccess from "../Toasts/ToastSuccess";
import ToastError from "../Toasts/ToastError";
import "./ModalStyle.css";

function SendEmailModal({ order, emails }) {
  const [show, setShow] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [isCustomEmail, setIsCustomEmail] = useState(false);
  const [emailData, setEmailData] = useState({
    order_id: '',
    receiver_email: '',
    sender_email: 'noreply@techdogsoftware.nl',
    subject: '',
    content: ''
  });
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    if (order && order.order_id) {
      setEmailData(prevData => ({
        ...prevData,
        order_id: order.order_id,
        subject: `Nieuw bericht voor bestelling # ${order.order_id}`
      }));
    }
  }, [order]);

  const handleClose = () => {
    setShow(false);
    setIsCustomEmail(false);
    setEmailError('');
  };
  const handleShow = () => setShow(true);

  const validateEmail = (email) => {
    // This regex pattern is compliant with RFC 5322
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'receiver_email') {
      if (value === 'custom') {
        setIsCustomEmail(true);
        setEmailData(prevState => ({ ...prevState, [name]: '' }));
      } else {
        setIsCustomEmail(false);
        setEmailData(prevState => ({ ...prevState, [name]: value }));
        setEmailError('');
      }
    } else if (name === 'custom_email') {
      setEmailData(prevState => ({ ...prevState, receiver_email: value }));
      if (value && !validateEmail(value)) {
        setEmailError('Voer een geldig e-mailadres in');
      } else {
        setEmailError('');
      }
    } else {
      setEmailData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError || !emailData.receiver_email) {
      setEmailError('Selecteer een ontvanger of voer een geldig e-mailadres in');
      return;
    }
    setIsEmailSending(true);

    try {
      const response = await middleware.post('https://api-middleware.dev.techdogcloud.com/orders/emails', emailData);

      if (response.status !== 200) {
        throw new Error('Failed to send email');
      }
      ToastSuccess("E-mail is succesvol verzonden.");
      
      setEmailData(prevData => ({
        ...prevData,
        receiver_email: '',
        content: ''
      }));
      handleClose();
      console.log("after handeclose: " + response.status)
    } catch (err) {
      ToastError(`Er is een fout opgetreden bij het verzenden van de e-mail: ${err.message}`);
    } finally {
      setIsEmailSending(false);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <IoMdSend /> Stuur e-mail
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className='emailModalHeader'>
          <Modal.Title>Stuur e-mail</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-white'>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="receiver_email">
              <Form.Label>Ontvanger</Form.Label>
              <Form.Select
                name="receiver_email"
                value={isCustomEmail ? 'custom' : emailData.receiver_email}
                onChange={handleEmailInputChange}
                required
              >
                <option value="">Selecteer ontvanger</option>
                {emails.map((email, index) => (
                  <option key={index} value={email.email}>{email.email}</option>
                ))}
                <option value="custom">Ander e-mailadres</option>
              </Form.Select>
            </Form.Group>
            {isCustomEmail && (
              <Form.Group className="mb-3" controlId="custom_email">
                <Form.Control
                  type="email"
                  name="custom_email"
                  value={emailData.receiver_email}
                  onChange={handleEmailInputChange}
                  placeholder="Voer een e-mailadres in"
                  isInvalid={!!emailError}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="sender_email">
              <Form.Label>Afzender</Form.Label>
              <Form.Control
                type="email"
                name="sender_email"
                value={emailData.sender_email}
                onChange={handleEmailInputChange}
                required
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="subject">
              <Form.Label>Onderwerp</Form.Label>
              <Form.Control
                type="text"
                name="subject"
                value={emailData.subject}
                onChange={handleEmailInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="content">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="content"
                value={emailData.content}
                onChange={handleEmailInputChange}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='emailModalFooter'>
          <div className="d-flex justify-content-between w-100">
            <Button variant="primary" type="button" className="btn-link text-primary">
              Voorbeeld van e-mail
            </Button>
            <div>
              <Button variant="secondary" onClick={handleClose} className="me-2">
                Annuleren
              </Button>
              <Button variant="primary" onClick={handleSubmit} disabled={isEmailSending}>
                {isEmailSending ? 'Verzenden...' : 'Versturen'}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendEmailModal;