import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import createGroup from "./Icons/create-group-button.png";
import multipleUsers from "./Icons/multiple-users-silhouette.png";
import Cards from "./Icons/credit-cards-payment.png";
import Search from "./Icons/analysis.png";
import Webpage from "./Icons/online-store.png";
import Award from "./Icons/customer-loyalty.png";
import Dollar from "./Icons/dollar.png";
import Profit from "./Icons/profit.png";
import Order from "./Icons/order.png"
import Amazon from "./Icons/amazon.png";
import unit4 from "./Icons/unit4.png";
import clipboard from "./Icons/Icon awesome-clipboard-check.svg";
import exact from "./Icons/exact.png";
import Warehouse from "./Icons/warehouse.png";
import NavbarIcon from "../Navbars/Sidebar/Icons/briefcase.png"
import Loading from "../LoadingScreen/LoadingScreen";
import middleware from "../Api/Middleware";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckFast, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { faDhl, faUps, faFedex } from "@fortawesome/free-brands-svg-icons";
import "./MainPage.css";
import PageTemplate from "../Templates/PageTemplate";
import DashboardBoxes from "./DashboardBoxes";

const MainPage = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [hubs, setHubs] = useState([]);
  const [appstore, setAppstore] = useState([]);
  const [apps, setApps] = useState([]);

  const fetchAll = async () => {
    setIsLoading(true);
    const [fetchHubs, fetchAppstore, fetchApps] = await Promise.all([
      middleware.get('sp/dashboardHubs'),
      middleware.get(`hubs/appstore`),
      middleware.get(`hubs/apps`),
    ])
    setHubs(fetchHubs.data)
    setAppstore(fetchAppstore.data)
    setApps(fetchApps.data)
  }

  useEffect(() => {
    (async () => {
      try {
        await fetchAll()
        setIsLoading(false);
      } catch (error) {
        // console.error(error)
      }
    })();
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <PageTemplate navbarTitle={"Dashboard"} iconNavbar={NavbarIcon}>
      <div className="dashboard-container w-100">
        {hubs?.map((hub, index) =>
          <DashboardBoxes key={index}
            titlebox={hub.name}
            numberOfApps={hub.app_amount}
            HubIcon={hub.icon}
            subtext={hub.description}
            statusCode={hub.status}
            InstallingText={"Rond uw installatie af in de AppStore."}
            InactiveText={"Activeer uw apps in de AppStore."}
            apps={[apps.filter(filt => filt.appstore_id == (appstore.filter(filt => filt.hub_id == hub.hub_id).map(map => map.appstore_id)))]}
          />
        )}
      </div>
    </PageTemplate>
  );
};

export default MainPage;