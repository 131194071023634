import React, { useEffect, useRef, useState } from 'react';
import PageTemplate from '../Templates/PageTemplate';
import { useNavigate, useParams } from 'react-router';
import productPic from "../WarehouseSettings/Icons/size.png"
import middleware from '../Api/Middleware';
import ToastWarning from '../Toasts/ToastWarning';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import countries from 'i18n-iso-countries';
import ToastError from '../Toasts/ToastError';
countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

const ProccessOrders = () => {
  const { order_number } = useParams();

  const navigate = useNavigate();
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const [order, setOrder] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [productsToProcces, setProductsToProcces] = useState([]);
  const [productsToProccesFullyShown, setProductsToProccesFullyShown] = useState([]);
  const [proccessingProducts, setProccessingProducts] = useState([]);
  const [makeNewPackage, setMakeNewPackage] = useState(true);
  const [shippingMethod, setShippingMethod] = useState('');
  const [packageAmount, setPackageAmount] = useState(1);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const ordersResponse = await middleware.get(`orders?order_number=${order_number}`);
        if (!ordersResponse.data) {
          navigate(`/bestellingen`);
          return;
        }
        const order = ordersResponse.data[0];
        setOrder(order);

        const addressResponse = await middleware.get(`addresses?internal_id=${order.internal_id}`);
        if (addressResponse.data.length > 0) {
          const countryCodesResponse = await middleware.get(`countrycodes`);
          addressResponse.data.map((address) => {
            address.country = countryCodesResponse.data.find((countryCode) => countryCode.country_code_id === address.country_code_id).country_code_2;
            address.address_kind === 0 || address.address_kind === 2 ? setDeliveryAddress(address) : setInvoiceAddress(address);
          });
        }

        const soldProductsResponse = await middleware.get(`orders/soldproducts?order_id=${order.order_id}`);
        const soldProductsData = soldProductsResponse.data
        setSoldProducts(soldProductsData);

        // If there are products that are not proccessed it adds them to the products to proccess. It also calculates how many products where bought in the order
        const addToProcces = [];
        const addToProccessingProducts = {};
        soldProductsData.map(soldProduct => {
          if (soldProduct.amount > soldProduct.items_processed) {
            addToProccessingProducts[soldProduct.sold_product_id] = (soldProduct.amount - soldProduct.items_processed);
            addToProcces.push(soldProduct);
          }
        });
        setProductsToProcces(addToProcces);
        setProccessingProducts(addToProccessingProducts);

        // If everything has been proccessed you can't access this page
        if (Object.values(addToProccessingProducts).length === 0) {
          ToastWarning(`Alles is al verwerkt`);
          navigate(`/bestellingen/bewerken/${order_number}`);
        }

        setPageIsLoading(false); 
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const changeProductProccessingAmount = (event, productToProcces) => {
    let value = event.target.value;
    if (value === '') {
      value = 0;
    }

    value = parseInt(value, 10);
    const max = productToProcces.amount - productToProcces.items_processed;

    if (value > max) {
      setProccessingProducts({...proccessingProducts, [productToProcces.sold_product_id]: max});
    } else {
      setProccessingProducts({...proccessingProducts, [productToProcces.sold_product_id]: value});
    }
  }

  const proccessProducts = async (event) => {
    event.preventDefault();
    console.log('Form submitted'); // Debugging statement
  
    try {
      console.log(!makeNewPackage);
      if (!makeNewPackage) {
        const soldProductsToUpdate = [];
        productsToProcces.forEach(productToProcces => {
          const selectedProcessingAmount = proccessingProducts[productToProcces.sold_product_id];
          if (selectedProcessingAmount > 0) {
            soldProductsToUpdate.push({
              sold_product_id: productToProcces.sold_product_id,
              items_processed: productToProcces.items_processed + selectedProcessingAmount
            });
          }
        });
        
        if (soldProductsToUpdate.length === 0) {
          ToastError(`Er zijn geen producten geselecteerd om te verwerken`);
          return;
        }
  
        const ressoldproducts = await middleware.put(`orders/soldproducts`, {
          bulk_data: soldProductsToUpdate
        });
        
        console.log(ressoldproducts);
  
        const shipmentsResponse = await middleware.post(`shipments`, {
          order_id: order.order_id
        });

        console.log(productsToProcces);
  
        const shipmentSoldProducts = [];
        productsToProcces.forEach(productToProcces => {
          const selectedProcessingAmount = proccessingProducts[productToProcces.sold_product_id];
          console.log(selectedProcessingAmount);
          if (selectedProcessingAmount > 0) {
            shipmentSoldProducts.push({
              shipment_id: shipmentsResponse.data.shipment_id,
              order_sold_product_id: productToProcces.sold_product_id,
              product_name: productToProcces.product_name,
              amount: selectedProcessingAmount,
              price_incl_tax: productToProcces.price_incl_tax,
              price_excl_tax: productToProcces.price_excl_tax,
              product_number: productToProcces.product_number,
              product_description: productToProcces.product_description,
              btw_rate: productToProcces.btw_rate,
              internal_id: productToProcces.internal_id,
              sold_at: productToProcces.sold_at,
              temperature: productToProcces.temperature,
              sale_amount: productToProcces.sale_amount,
              sale_unit: productToProcces.sale_unit,
              internal_id: order.internal_id,
            });
          }
        });
  
        const res = await middleware.post(`shipments/soldProducts`, {
          bulk_data: shipmentSoldProducts
        });
        console.log(res);
  
        navigate(`/bestellingen/bewerken/${order_number}`);
      }
    } catch (error) {
      console.error(error);
    }
  };  

  return (
    <PageTemplate pageIsLoading={pageIsLoading} navbarTitle={"Verwerk bestelling"}>
      <div className='w-100'>
        <div className='d-flex align-items-center mb-3'>
          <button
            className="buttonStyle me-2"
            onClick={(e) => navigate(`/bestellingen/bewerken/${order_number}`)}
          >
            {`< ${order.order_number}`}
          </button> 
        </div>
        <div className="d-flex justify-content-between gap-5 parentBoxes flex-column w-100">
          <div className='proccessOrdersContainer'>
            <div className='proccessOrdersLeftSide'>
              {soldProducts.length > 0 ? (
                <div className="bg-white rounded-4 p-4 mb-3">
                  <div>
                    <h3>Producten om te verwerken</h3>
                  </div>

                  <div>
                    <table className='w-100 border-0 mb-0'>
                      <thead>
                        <tr>
                          <th>Foto</th>
                          <th>Product</th>
                          <th>Aantal</th>
                        </tr>
                      </thead>

                      <tbody>
                        {productsToProcces.map((productToProcces, index) => (
                          <tr key={index}>
                            <td className="text-center"><img src={productPic} className="p-2" height={69} width={69} alt="placeholder..."></img></td>
                            <td className="w-75">
                              {productToProcces.product_name.length > 100 ? (
                                productsToProccesFullyShown[index] === undefined ? (
                                  <>
                                    <span>
                                      {productToProcces.product_name.slice(0, 90)}
                                    </span>
                                    <span
                                      className="text-info hover text-nowrap ms-2"
                                      onClick={() => setProductsToProccesFullyShown([...productsToProccesFullyShown, index])}>
                                      (...)
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span>
                                      {productToProcces.product_name}
                                    </span>
                                    <span
                                      className="text-info hover text-nowrap ms-2"
                                      onClick={() => setProductsToProccesFullyShown(productsToProccesFullyShown.filter(i => i !== index))}>
                                      Toon minder
                                    </span>
                                  </>
                                )
                              ) : (
                                productToProcces.product_name
                              )}
                            </td>
                            <td>
                              <input
                                type='text'
                                value={proccessingProducts[productToProcces.sold_product_id] ?? ''}
                                pattern="[0-9]*"
                                onChange={(event) => changeProductProccessingAmount(event, productToProcces)}
                              />
                              <span>{`Van ${productToProcces.amount - productToProcces.items_processed} Stock`}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="bg-white rounded-4 p-4 mb-3">
                  <h1>Deze bestelling heeft geen verkochte producten</h1>
                </div>
              )}
            </div>

            <div className='proccessOrdersRightSide'>
              <div className='bg-white rounded-4 p-4 mb-3'>
                <div className='mb-3'>
                  <h3>
                    <label className="switch me-2">
                      <input type="checkbox" defaultChecked onClick={() => setMakeNewPackage(!makeNewPackage)} />
                      <span className="slider round"></span>
                    </label>
                    Pakketlabel aanmaken
                  </h3>
                </div>

                <div className='mb-3'>
                  <span className='fw-bold'>Verzendadres:</span> <br/>
                  {deliveryAddress ? (
                    <>
                      <span>{deliveryAddress.streetname} {deliveryAddress.housenumber}{deliveryAddress.addition}</span> <br/>
                      <span>{deliveryAddress.living_area} {deliveryAddress.postalcode} {countries.getName(deliveryAddress.country, "nl")}</span>
                    </>
                  ) : (
                    <span>Deze klant heeft nog geen verzendadres</span>
                  )}
                </div>

                <form onSubmit={proccessProducts}>
                  {makeNewPackage ? (
                    <>
                      <div className='mb-3'>
                        <span className='me-3'>Pakket provider *</span>
                        <select value={shippingMethod ?? ''} onChange={event => setShippingMethod(event.target.value)} required>
                          <option value={''}>-- Selecteer een pakketaanbieder --</option>
                          <option value={'0'}>Afhalen</option>
                          <option value={'1'}>Eigen bezorging</option>
                        </select>
                      </div>

                      <div className='mb-5 w-100'>
                        <span className='me-3'>Aantal pakketten *</span>
                        <input
                          type='number'
                          className='w-50'
                          min={1}
                          required
                          value={packageAmount}
                          onChange={event => setPackageAmount(event.target.value)}
                        />
                      </div>
                    </>
                  ) : (
                    <div className='mb-3 alert alert-info'>
                      <p>
                        <FontAwesomeIcon className='text-info' icon={faCircleInfo}/> {' '}
                        Er wordt nu geen pakket aangemaakt, maar je kunt later altijd pakket toevoegen.
                      </p>
                    </div>
                  )}

                  <div>
                    <button type='submit' className='buttonStyle bg-light w-100 mb-0'>Verwerk producten</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default ProccessOrders;