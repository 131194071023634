import React, { useState, useEffect } from 'react';
import PageTemplate from '../../Templates/PageTemplate';
import { Tab, Tabs } from "react-bootstrap";
import middleware from "../../Api/Middleware";
import '../../Main/Main.css';
import './EditCustomers.css';
import { useNavigate, useParams } from "react-router";
import NavbarIcon from "../user-solid.svg";
import Overview from './TabPages/Overview';
import AddressData from './TabPages/AddressData';
import OrdersTab from './TabPages/OrdersTab';
import Quotation from './TabPages/Quotation';
import Invoice from './TabPages/Invoice';
import Pin from './Icons/Pin.svg';
import Mail from './Icons/Mail.svg';
import Phone from './Icons/Telephone.svg';
import Cake from './Icons/Birthday-cake.svg';
import Loyalty from './TabPages/Loyalty';
import GeneralInfoTab from './TabPages/GeneralInfoTab';
import Notes from './TabPages/Notes';
import NoteModal from './CustomerModals/NoteModal';
import ConvertToBusiness from './CustomerModals/ConvertToBusiness';
import ToastError from '../../Toasts/ToastError';
import ToastSuccess from '../../Toasts/ToastSuccess';

const EditCustomers = () => {
  const { internal_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState(() => {
    return localStorage.getItem('activeTabKey') || "tab1";
  });
  const [activeTab, setActiveTab] = useState("Overzicht");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [topNotes, setTopNotes] = useState([]);
  const [consumerData, setConsumerData] = useState({});
  const [consumerAddresses, setConsumerAddresses] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [customerType, setCustomerType] = useState("");
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showConvertModal, setShowConvertModal] = useState(false);
  const [selectedNoteData, setSelectedNoteData] = useState(null); // State for selected note
  const [dataUpdated, setDataUpdated] = useState(false);
  const [typeOfCustomer, setTypeOfCustomer] = useState(internal_id.charAt(0));
  const [pinnedNotes, setPinnedNotes] = useState([])

  const fetchAll = async () => {
    setLoading(true);
    try {
      const [ConsumerRes, TopNotesRes, ordersResponse, businessResponse, pinnedNotesResponse, consumerAddresses] = await Promise.all([
        middleware.get(`sp/customerHubOverview?searchTerm=${internal_id}&specificSearchColumn=internal_id`),
        middleware.get(`sp/recentConsumerNotes?internal_id=${internal_id}`),
        middleware.get(`orders?internal_id=${internal_id}`),
        middleware.get(`businesses`),
        middleware.get(`consumers/notes?pinned=1&internal_id=${internal_id}`),
        middleware.get(`sp/consumeraddresses?specificSearchColumn=internal_id&searchTerm=${internal_id}`)
      ]);
      setConsumerData(ConsumerRes.data[0]);
      setTopNotes(TopNotesRes.data);
      setConsumerAddresses(consumerAddresses.data);
      setData(ordersResponse.data.data);
      setBusinesses(businessResponse.data);
      setPinnedNotes(pinnedNotesResponse.data)
    } catch (error) {
      if (error.response.status == 401) {
        navigate("/login")
      }
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  console.log(pinnedNotes.length);

  useEffect(() => {
    try {
      fetchAll();
      const initialTab = tabs.find((tab) => tab.eventKey === key);
      if (initialTab) {
        setActiveTab(initialTab.navbarTitle);
      }
    } catch (error) {
      console.warn(error);
    }
  }, [consumerData.internal_id]);

  useEffect(() => {
    if (dataUpdated) {
      fetchAll();
      setDataUpdated(false); // Reset the flag after fetching the data
    }
  }, [dataUpdated]);

  const closeModalNote = () => setShowNoteModal(false);
  const closeModalConvert = () => setShowConvertModal(false);

  const ConvertToBusinessModal = () => {
    setShowConvertModal(true)
  };

  const handleConvertConfirmed = async (info) => {
    console.log(info);
      try {
        // Replace 'offer_id' with the correct identifier field for your data
       const res = await middleware.post(`convertToBusiness?internal_id=${internal_id}`, {
            company_name: info.companyName,
            cocnumber: info.companyNumber,
            vatnumber: info.vatNumber
        });

        console.log(res);
        setShowConvertModal(false);
        ToastSuccess("Converterenn naar een bedrijf gelukt")
        setTimeout(() => {
          navigate(`../klanten/bewerken/${res.data.business.internal_id}`)
          window.location.reload();
        }, 3000);
      } catch (error) {
        console.log(error);
        ToastError("Er is een fout opgetreden bij het converteren naar een bedrijf");
      }
  };

  const navigateTabs = (e) => {
    const selectedTab = tabs.find(tab => tab.eventKey === e);
    if (selectedTab) {
      setKey(e);
      setActiveTab(selectedTab.navbarTitle);
      localStorage.setItem('activeTabKey', selectedTab.eventKey);
    } else {
      console.log('Tab not found');
    }
  };

  const splitString = (str) => {
    return str ? str.split(',').map(item => item.trim()) : [];
  };

  const tabs = [
    {
      eventKey: "tab1",
      title: "Overzicht",
      navbarTitle: "Overzicht",
      content: <Overview NavigateTab={(e) => navigateTabs(e)} consumerData={consumerData} consumerAddress={consumerAddresses} />,
    },
    {
      eventKey: "tab2",
      title: "Algemene gegevens",
      navbarTitle: "Algemene gegevens",
      content: <GeneralInfoTab consumerData={consumerData} typeCustomer={typeOfCustomer} setDataUpdated={setDataUpdated} />,
    },
    {
      eventKey: "tab3",
      title: "Adresgegevens",
      navbarTitle: "Adresgegevens",
      content: <AddressData consumerData={consumerData} consumerAddresses={consumerAddresses} setDataUpdated={setDataUpdated} />,
    },
    {
      eventKey: "tab4",
      title: "Bestellingen",
      navbarTitle: "Bestellingen",
      content: <OrdersTab data={data} />,
    },
    {
      eventKey: "tab5",
      title: "Offertes",
      navbarTitle: "Offertes",
      content: <Quotation data={data} />,
    },
    {
      eventKey: "tab6",
      title: "Facturen",
      navbarTitle: "Facturen",
      content: <Invoice data={data} />,
    },
    {
      eventKey: "tab7",
      title: "Loyaliteit",
      navbarTitle: "Loyaliteit",
      content: <Loyalty typeCustomer={typeOfCustomer} consumerData={consumerData} />,
    },
    {
      eventKey: "tab8",
      title: "Notities",
      navbarTitle: "Notities",
      content: <Notes typeCustomer={typeOfCustomer} />,
    },
  ];

  return (
    <PageTemplate navbarTitle={"Customer HUB"} iconNavbar={NavbarIcon}>
      <NoteModal openNote={showNoteModal} data={selectedNoteData} closeNote={closeModalNote} />
      <ConvertToBusiness openConvertToBusiness={showConvertModal} closeConvertToBusiness={closeModalConvert} onConvertConfirmed={handleConvertConfirmed} />
      <div className='parentBoxSize'>
        <div className='col-12 d-flex flex-row justify-content-between responsiveEditCustomers'>
          {activeTab === "Overzicht" ? (
            <div className='col-9'>
              <div className="tabContainer whiteBox px-4 tabsOverviewPadding">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => {
                    setKey(k);
                    const tab = tabs.find((t) => t.eventKey === k);
                    setActiveTab(tab.navbarTitle);
                    localStorage.setItem('activeTabKey', tab.eventKey);
                  }}
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.eventKey}
                      eventKey={tab.eventKey}
                      title={
                        <span
                          className={`tab-title ${key === tab.eventKey ? "active" : ""} fw-semibold`}
                        >
                          {tab.title}
                        </span>
                      }
                      disabled={tab.disabled}
                    >
                      {activeTab !== "Overzicht" ? (
                        <div key={tab.eventKey} className="tabContent">{tab.content}</div>
                      ) : (
                        ""
                      )}
                    </Tab>
                  ))}
                </Tabs>
              </div>
              {activeTab === "Overzicht" &&
                tabs.map((tab) => (
                  tab.title === "Overzicht" && (
                    <div key={tab.eventKey} className='mt-4'>
                      <div className="tabContent fitcontentHeight">{tab.content}</div>
                    </div>
                  )
                ))
              }
            </div>
          ) : (
            <div className="tabContainer whiteBox col-9 px-4 fitcontentHeight">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                  const tab = tabs.find((t) => t.eventKey === k);
                  setActiveTab(tab.navbarTitle);
                  localStorage.setItem('activeTabKey', tab.eventKey);
                }}
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.eventKey}
                    eventKey={tab.eventKey}
                    title={
                      <span className={`tab-title ${key === tab.eventKey ? "active" : ""} fw-semibold`}>
                        {tab.title}
                      </span>
                    }
                    disabled={tab.disabled}
                  >
                    <div key={tab.eventKey} className="tabContent h-100">{tab.content}</div>
                  </Tab>
                ))}
              </Tabs>
            </div>
          )}
          {activeTab === "Overzicht" ? (
            <div className='convertToAndNotes'>
              <div className='convertToBoxOverview'>
                <button className={`btn convertToBusinessButton ${typeOfCustomer === "C" ? "" : "d-none"}`} onClick={() => ConvertToBusinessModal()}>Omzetten naar bedrijf</button>
              </div>
              <div className={`mt-4 whiteBox latestNotesQuotation mb-5 ${pinnedNotes.length < 1 ? "d-none" : ""}`}>
                <h5 className='fw-bold mb-3'>Vastgemaakte notities</h5>
                {pinnedNotes?.map((note, index) => (
                  <div key={index}>
                    <h6 className='darkBlueLinkText'>
                      {new Date(note.created_at).toLocaleDateString('nl-NL', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </h6>
                    <p
                      className='noteTitle fw-semibold cursorPointer'
                      onClick={() => {
                        setSelectedNoteData(note); // Set the selected note data
                        setShowNoteModal(true); // Show the modal
                      }}
                    >
                      {note.note_title || ''}
                    </p>
                    <p className='fw-semibold overflow-hidden'>{note.note}</p>
                    {index < pinnedNotes.length - 1 && <hr className='noteDivider' />}
                  </div>
                ))}
                <p className='fw-semibold'>{pinnedNotes?.length <= 0 ? "Geen notities gevonden" : ""}</p>
              </div>
              <div className='mt-4 whiteBox latestNotesQuotation mb-5'>
                <h5 className='fw-bold mb-3'>Laatste notities</h5>
                {topNotes?.map((note, index) => (
                  <div key={index}>
                    <h6 className='darkBlueLinkText'>{note.created_date}</h6>
                    <p
                      className='noteTitle fw-semibold cursorPointer'
                      onClick={() => {
                        setSelectedNoteData(note); // Set the selected note data
                        setShowNoteModal(true); // Show the modal
                      }}
                    >
                      {note.note_title || ''}
                    </p>
                    <p className='fw-semibold overflow-hidden'>{note.note}</p>
                    {index < topNotes.length - 1 && <hr className='noteDivider' />}
                  </div>
                ))}
                <p className='fw-semibold'>{topNotes?.length <= 0 ? "Geen notities gevonden" : ""}</p>
              </div>
            </div>
          ) : (
            <>
              {activeTab === "Notities" ? (
                <div className='ms-4'>
                  { loading ? 
                    <div className='col-2 py-4 px-5 customerInfoBox whiteBox fitcontentHeight minFitContent mb-3' style={{ paddingLeft: "25px" }}>
                      <h4 className="m-4 loader"></h4>
                    </div>
                  :
                    <div className='py-4 px-5 customerInfoBox whiteBox fitcontentHeight minFitContent w-100' style={{ paddingLeft: "25px" }}>
                      <div className='mb-4'>
                        <h3 className='mb-3 text-nowrap'>Overzicht {consumerData.relation}</h3>
                        <h5>{consumerData.company_name ?? (consumerData.first_name + ' ' + consumerData.last_name)}</h5>
                      </div>
                      <div className='d-flex flex-column justify-content-between fitcontentHeight mb-2'>
                        {consumerAddresses?.map((map, index) => (
                          <div key={index}>
                            {map.address_type}
                            <div className='d-flex flex-row my-3'>
                              <img className='text-white rounded-2 infoIcons' src={Pin} />
                              <div className='textInfoCustomer d-flex flex-column align-self-center fw-medium'>
                                <div className='text-nowrap'>
                                  {map.street}
                                </div>
                                <div className='text-nowrap'>
                                  {map.location}
                                </div>
                                <div className='text-nowrap'>
                                  {map.country}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className='d-flex flex-row my-3'>
                          <img className='text-white rounded-2 infoIcons' src={Phone} />
                          <div className='d-flex flex-column justify-content-center'>
                            {splitString(consumerData.phone_numbers).map((phone_number, index) => (
                              <div className='fw-medium mb-1' key={index}>{phone_number}</div>
                            ))}
                          </div>
                        </div>
                        <div className='d-flex flex-row my-3'>
                          <img className='text-white rounded-2 infoIcons' src={Mail} />
                          <div className='d-flex flex-column justify-content-center'>
                            {splitString(consumerData.emails).map((email, index) => (
                              <div className='fw-medium mb-1' key={index}>{email}</div>
                            ))}
                          </div>
                        </div>
                        <div className='d-flex flex-row my-3'>
                          <img className='text-white rounded-2 infoIcons' src={Cake} />
                          <p className='textInfoCustomer d-flex align-self-center fw-semibold text-nowrap'>{consumerData.birthday} ({consumerData.age})</p>
                        </div>
                      </div>
                    </div>
                  }
                  { loading ? 
                    <div className='col-2 py-4 px-5 customerInfoBox whiteBox fitcontentHeight minFitContent' style={{ paddingLeft: "25px" }}>
                      <h4 className="m-4 loader"></h4>
                    </div>
                  :
                    <div className='mt-4 whiteBox latestNotesQuotation mb-5'>
                      <h5 className='fw-bold mb-3'>Laatste notities</h5>
                      {topNotes?.map((note, index) => (
                        <div key={index}>
                          <h6 className='darkBlueLinkText'>{note.created_date}</h6>
                          <p
                            className='noteTitle fw-semibold cursorPointer'
                            onClick={() => {
                              setSelectedNoteData(note); // Set the selected note data
                              setShowNoteModal(true); // Show the modal
                            }}
                          >
                            {note.note_title || ''}
                          </p>
                          <p className='fw-semibold overflow-hidden'>{note.note}</p>
                          {index < topNotes.length - 1 && <hr className='noteDivider' />}
                        </div>
                      ))}
                      <p className='fw-semibold'>{topNotes?.length <= 0 ? "Geen notities gevonden" : ""}</p>
                    </div>
                  }
                </div>
              ) : (
                <>
                  { loading ? 
                    <div className='col-2 py-4 px-5 customerInfoBox whiteBox fitcontentHeight' style={{ paddingLeft: "25px" }}>
                      <h4 className="m-4 loader"></h4>
                    </div>
                  :
                  <div className='col-2 py-4 px-5 customerInfoBox whiteBox fitcontentHeight' style={{ paddingLeft: "25px" }}>
                    <div className='mb-4'>
                      <h3 className='mb-3 text-nowrap'>Overzicht {consumerData.relation}</h3>
                      <h5>{consumerData.company_name ?? (consumerData.first_name + ' ' + consumerData.last_name)}</h5>
                    </div>
                    <div className='d-flex flex-column justify-content-between fitcontentHeight mb-2'>
                      {consumerAddresses?.map((map, index) => (
                        <div key={index}>
                          {map.address_type}
                          <div className='d-flex flex-row my-3'>
                            <img className='text-white rounded-2 infoIcons' src={Pin} />
                            <div className='textInfoCustomer d-flex flex-column align-self-center fw-medium'>
                              <div className='text-nowrap'>
                                {map.street}
                              </div>
                              <div className='text-nowrap'>
                                {map.location}
                              </div>
                              <div className='text-nowrap'>
                                {map.country}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className='d-flex flex-row my-3'>
                        <img className='text-white rounded-2 infoIcons' src={Phone} />
                        <div className='d-flex flex-column justify-content-center'>
                          {splitString(consumerData.phone_numbers).map((phone_number, index) => (
                            <div className='fw-medium mb-1' key={index}>{phone_number}</div>
                          ))}
                        </div>
                      </div>
                      <div className='d-flex flex-row my-3'>
                        <img className='text-white rounded-2 infoIcons' src={Mail} />
                        <div className='d-flex flex-column justify-content-center'>
                          {splitString(consumerData.emails).map((email, index) => (
                            <div className='fw-medium mb-1' key={index}>{email}</div>
                          ))}
                        </div>
                      </div>
                      <div className='d-flex flex-row my-3'>
                        <img className='text-white rounded-2 infoIcons' src={Cake} />
                        <p className='textInfoCustomer d-flex align-self-center fw-semibold text-nowrap'>{consumerData.birthday} ({consumerData.age})</p>
                      </div>
                    </div>
                  </div>
                  }
                </>
              )}
            </>
          )}
        </div>
      </div>
    </PageTemplate>
  );
}

export default EditCustomers;
